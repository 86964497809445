import React, {useEffect} from "react";
import {Field, Form, Formik} from "formik";
import Dialog from "@mui/material/Dialog";
import {translate} from "../../../../translate/translate";
import {DialogActions, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Typography} from "@mui/material";
import {Assignment, Close} from "@mui/icons-material";
import {Demand} from "../../../../models/basic/demand";
import {Product} from "../../../../models/basic/product";
import {URLS} from "../../../../services/app-urls";
import {BaseService} from "../../../../services/base-service";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {ErrorComponent} from "../../../../components/error/error.component";
import {MuiColorInput} from "mui-color-input";
import {DateNowEnUS} from "../../../../components/date/date-timeFormat";


const DialogCreateDemandComponent = (props: any) => {
    const {
        open,
        onClose,
        product,
        maxQuantity
    } = props;
    const service = new BaseService<Demand>(URLS.DEMAND);
    const productService = new BaseService<Product>(URLS.PRODUCT);
    const [quantity, setQuantity] = React.useState<number>(maxQuantity);
    const [colorValue, setColorValue] = React.useState<string>("#000000");
    const [fontColorValue, setFontColorValue] = React.useState<string>("#ffffff");
    const [productObject, setProductObject] = React.useState(new Product());
    const schema = Yup.object().shape({production_order: Yup.string().required()});

    useEffect(() => {
        getProduct();
    }, [product]);

    const getProduct = () => {
        productService.clearParameters();
        productService.getById(product).then((response) => {
            setProductObject(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const handleChangeColor = (newColorValue: string) => {
        setColorValue(newColorValue);
    };

    const fontColor = (color: string) => {
        return <div
            style={{
                backgroundColor: colorValue,
                color: color,
                fontWeight: "bold",
                padding: 7,
                paddingLeft: 12,
                paddingRight: 12,
                borderRadius: 2
            }}
        >
            100
        </div>;
    };

    const handleClose = (openPlanning: boolean, result: any) => {
        onClose(openPlanning, result);
    };

    const createDemand = () => {
        const new_demand = new Demand();
        new_demand.quantity = quantity;
        new_demand.color = colorValue;
        new_demand.font_color = fontColorValue;
        new_demand.product = productObject.url;
        new_demand.status = "TP";
        new_demand.reference_date = DateNowEnUS();
        service.clearParameters();
        service.addParameter("expand", "product,version");
        service.postFromListRoute(new_demand, "save_demand_to_final_version")
            .then((response) => {
                toast.success(translate.t("successfully_updated"));
                handleClose(true, response.data);
            }).catch((error: any) => {
                ErrorComponent(error);
            });
    };

    return (
        <div>
            <Dialog open={open} onClose={() => handleClose(false, {})} maxWidth={"lg"}>
                <DialogTitle sx={{backgroundColor: "primary.main", padding: "16px"}}>
                    <Grid container direction="row">
                        <Grid item xs>
                            <Typography color="#FFFFFF" fontWeight="bold" fontSize="16px" display="flex" alignItems="center">
                                <Assignment fontSize="large" sx={{paddingRight: "5px"}}/>
                                {translate.t("create_demand")}
                            </Typography>
                        </Grid>
                        <Grid sx={{cursor: "pointer", color: "#ffffff"}} alignItems="center" display="flex">
                            <Close onClick={() => handleClose(false, {})}/>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers tabIndex={0}>
                    <Formik
                        initialValues={{}}
                        validationSchema={schema}
                        validateOnChange={true}
                        enableReinitialize
                        onSubmit={(values: any, {setSubmitting}) => {
                            setTimeout(() => {
                                setSubmitting(false);
                            }, 500);
                        }}>
                        {() => (
                            <Form autoComplete="off">
                                <Grid container paddingTop={2} direction="column" spacing={2}>
                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="quantity"
                                                label={translate.t("quantity")}
                                                variant={"outlined"}
                                                fullWidth
                                                autoFocus={false}
                                                required={true}
                                                value={quantity}
                                                max={maxQuantity}
                                                onChange={(e: any) => setQuantity(e.target.value)}
                                                type="number"
                                                InputProps={{
                                                    min: 1,
                                                    max: maxQuantity,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {!quantity && (
                                                                <div className={"required-field"}>
                                                                    {translate.t("required_field")}
                                                                </div>
                                                            )}
                                                            {quantity && quantity > maxQuantity && (
                                                                <div className={"required-field"}>
                                                                    {translate.t("max_value") + maxQuantity}
                                                                </div>
                                                            )}
                                                            {quantity && quantity < 1 && (
                                                                <div className={"required-field"}>
                                                                    {translate.t("min_value") + 1}
                                                                </div>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}/>
                                        </Grid>
                                        <Grid item xs>
                                            <MuiColorInput
                                                name="color"
                                                id="color"
                                                label={translate.t("color")}
                                                variant={"outlined"}
                                                fullWidth
                                                autoFocus={false}
                                                required={true}
                                                error={!colorValue}
                                                inputProps={{
                                                    maxLength: 7,
                                                }}
                                                format="hex"
                                                isAlphaHidden
                                                value={colorValue}
                                                onChange={handleChangeColor}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {!colorValue && (
                                                                <div className={"required-field"}>
                                                                    {translate.t("required_field")}
                                                                </div>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item xs={12} sm={12} md={4} lg={4}
                                            sx={{paddingLeft: "15px", paddingTop: "10px"}}
                                            container alignItems="center">
                                            <FormControl component="fieldset">
                                                <Grid container alignItems="center">
                                                    <FormLabel
                                                        required={true}
                                                        sx={{paddingRight: "10px"}}
                                                        component="legend">
                                                        {translate.t("font_color")}:</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        name="font_color"
                                                        onChange={(event) =>
                                                            setFontColorValue(event.target.value)}>
                                                        <FormControlLabel
                                                            value="#000000"
                                                            control={<Radio/>}
                                                            checked={fontColorValue === "#000000"}
                                                            label={fontColor("#000000")}
                                                        />
                                                        <FormControlLabel
                                                            value="#ffffff"
                                                            control={<Radio/>}
                                                            checked={fontColorValue === "#ffffff"}
                                                            label={fontColor("#ffffff")}
                                                        />
                                                    </RadioGroup>
                                                </Grid>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="button-options"
                        color="secondary"
                        onClick={() => handleClose(false, {})}
                        variant="outlined">
                        {translate.t("cancel")}
                    </Button>
                    <Button
                        className="button-options"
                        color="success"
                        disabled={!quantity || quantity <= 0 || quantity > maxQuantity}
                        onClick={() => createDemand()}
                        variant="contained">
                        {translate.t("save")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DialogCreateDemandComponent;
