import {Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Tooltip, Typography} from "@mui/material";
import {translate} from "../../../translate/translate";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {Calendar, momentLocalizer} from "react-big-calendar";
import moment from "moment";
import "./calendar.component.css";
import {BaseService} from "../../../services/base-service";
import {URLS} from "../../../services/app-urls";
import {CalendarEvent} from "../../../models/basic/calendar-event";
import {CalendarEventType} from "../../../models/basic/calendar-event-type";
import {toast} from "react-toastify";
import {Field, Form, Formik} from "formik";
import Select from "@mui/material/Select";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "moment/locale/pt-br";
import DialogCalendarComponent from "./dialog/dialog-calendar.component";
import {Timelapse, TimerOffOutlined} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {ErrorComponent} from "../../../components/error/error.component";

const CalendarComponent = () => {
    moment.locale(translate.language == "pt" ? "pt-br" : "en-us");
    const localize = momentLocalizer(moment);
    const serviceCalendarEvent = new BaseService<CalendarEvent>(URLS.CALENDAR_EVENT);
    const serviceEventType = new BaseService<CalendarEventType>(URLS.CALENDAR_EVENT_TYPE);
    const [listEventType, setListEventType] = useState<CalendarEventType[]>([]);
    const [listCalendarEvent, setListCalendarEvent] = useState<CalendarEvent[]>([]);
    const [events, setEvents] = useState<any[]>([]);
    const [month, setMonth] = useState<number>(new Date().getMonth());
    const [year, setYear] = useState<number>(new Date().getFullYear());
    const [date, setDate] = useState<Date>(new Date());
    const onNavigate = useCallback((newDate: Date) => setDate(newDate), [setDate]);
    const [listYear, setListYear] = useState<number[]>([]);
    const [listMonth, setListMonth] = useState<any[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [typeOperation, setTypeOperation] = useState<string>("");
    const [calendarEvent, setCalendarEvent] = useState<CalendarEvent>(new CalendarEvent());
    const [eventStart, setEventStart] = useState<Date>(new Date());

    const generateListYear = () => {
        const currentYear = new Date().getFullYear();
        const list = [];
        for (let i = currentYear - 50; i < currentYear + 50; i++) {
            list.push(i);
        }
        setListYear(list);
    };

    const generateListMonth = () => {
        const listMonth = [];

        for (let i = 0; i < 12; i++) {
            const month = moment().month(i).format("MMMM");
            const monthUpper = month.charAt(0).toUpperCase() + month.slice(1);
            listMonth.push({id: i, name: monthUpper});
        }

        setListMonth(listMonth);
    };

    useEffect(() => {
        generateListMonth();
        generateListYear();
        searchEventType();
    }, []);

    useEffect(() => {
        if (year != 0 && month != 0) {
            searchCalendarEvent();
            setDate(new Date(year, month, new Date().getDay()));
        }
    }, [month, year]);

    const searchEventType = () => {
        serviceEventType.clearParameters();
        serviceEventType.getAll().then(response => {
            setListEventType(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const searchCalendarEvent = () => {
        serviceCalendarEvent.clearParameters();
        serviceCalendarEvent.addParameter("month", Number(month) + 1);
        serviceCalendarEvent.addParameter("year", year);
        serviceCalendarEvent.getAll().then(response => {
            setListCalendarEvent(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const dateStartEnd = (event: CalendarEvent, type: string) => {
        if (type == "start") {
            let start_time = event.start_time_event;
            if (!start_time) start_time = "00:00:00";
            return moment(`${event.event_date}T${start_time}`).toDate();
        } else if (type == "end") {
            let end_time = event.end_time_event;
            if (!end_time) end_time = "23:59:59";
            return moment(`${event.event_date}T${end_time}`).toDate();
        } else {
            return moment().toDate();
        }
    };

    useEffect(() => {
        const addEvents: any = [];
        if (listCalendarEvent.length > 0) {
            listCalendarEvent.forEach((event) => {
                addEvents.push({
                    title: event.description,
                    start: dateStartEnd(event, "start"),
                    end: dateStartEnd(event, "end"),
                    data: {
                        id: event.id,
                        type: event.id_calendar_event_type,
                        filled_times: !!((event.start_time) && (event.end_time))
                    }
                });
            });
            setEvents(addEvents);
        } else {
            setEvents([]);
        }
    }, [listCalendarEvent]);

    const eventStyleGetter = () => {
        const style = {
            backgroundColor: "#fff",
            outline: 0
        };

        return {
            style,
        };
    };

    const changeMonth = (value: string): any => {
        const dateChange = date;
        if (value == "left") {
            dateChange.setMonth(dateChange.getMonth() - 1);
        } else if (value == "right") {
            dateChange.setMonth(dateChange.getMonth() + 1);
        }
        setDate(dateChange);
        setYear(dateChange.getFullYear());
        setMonth(dateChange.getMonth());
    };

    const handleSelectEvent = useCallback(
        (event: any) => {
            serviceCalendarEvent.clearParameters();
            serviceCalendarEvent.addParameter("expand", "calendar_event_type");
            serviceCalendarEvent.getById(event.data.id).then(response => {
                setCalendarEvent(response.data);
                setOpenDialog(true);
                setTypeOperation("edit");
            }).catch((error: any) => {
                ErrorComponent(error);
            });
        },
        []
    );

    const handleSelectSlot = (date: any) => {
        if (new Date() < date) {
            setEventStart(date);
            setOpenDialog(true);
            setTypeOperation("add");
            setCalendarEvent(new CalendarEvent());
        } else {
            toast.error(translate.t("validate_event_create"));
        }
    };

    const {defaultDate, formats} = useMemo(
        () => ({
            defaultDate: new Date(),
            formats: {
                weekdayFormat: (date: any, culture: any, loc: any) => {
                    const dayWeek = loc.format(date, "dddd", culture).replace(/-feira$/, "");
                    return dayWeek.charAt(0).toUpperCase() + dayWeek.slice(1);
                }
            },
        }),
        []
    );

    const customEvent = ({event}: any) => {
        const id = event.data.type ?? 0;
        const filled_times = event.data.filled_times;
        const eventType = listEventType.find((eventType) => eventType.id == id);

        return (
            <div style={{
                backgroundColor: "#fff",
                color: "#3d3d3d",
                borderLeft: `6px solid ${eventType?.color}`,
                paddingLeft: 5,
                paddingRight: 5
            }} className='rbc-event-hover'>
                <div style={{display: "flex", alignItems: "center"}}>
                    <IconButton style={{padding: 0}}>
                        {
                            eventType?.is_working_day ?
                                (
                                    filled_times ?
                                        (<Timelapse style={{marginTop: 0}}/>)
                                        :
                                        ""
                                ) : <TimerOffOutlined/>
                        }
                    </IconButton>
                    <span style={{marginLeft: "4px", fontSize: 12, whiteSpace: "normal"}}>{event.title}</span>
                </div>
            </div>
        );
    };

    const dateCellWrapperSlot = ({children, value}: any) => {
        return (
            <div style={{width: "100%", height: "100%"}} className={children.props.className}>
                {(new Date() < value) ? (<div style={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    flexDirection: "column-reverse",
                }}>
                    <Tooltip title={translate.t("add")}>
                        <IconButton className="button-add-calendar" onClick={() => handleSelectSlot(value)}>
                            <AddIcon/>
                        </IconButton>
                    </Tooltip>
                </div>) : null}
            </div>
        );
    };

    return (
        <section>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("calendar")}
                </Typography>
            </div>
            <Grid item xs={12} marginBottom="-1px">
                <Paper variant="outlined" square>
                    <Box>
                        <Formik
                            initialValues={{month: month}}
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {() => (
                                <Form autoComplete="off">
                                    <Grid alignItems="center" container spacing={2} direction={"row"}>
                                        <Grid item xs/>
                                        <Grid justifyContent="center" container spacing={2} item>
                                            <Grid display="flex" alignItems="center" item>
                                                <ArrowBackIcon
                                                    style={{cursor: "pointer"}}
                                                    onClick={() => changeMonth("left")}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControl sx={{width: "140px"}}>
                                                    <InputLabel className={"formControl"} htmlFor="month">
                                                        {translate.t("month")}
                                                    </InputLabel>
                                                    <Field
                                                        id="month"
                                                        variant={"outlined"}
                                                        value={month}
                                                        name="month"
                                                        component={Select}
                                                        onChange={(event: any) => setMonth(event.target.value)}>
                                                        {listMonth.map(
                                                            (month: any) =>
                                                                <MenuItem key={month.id} value={month.id}>
                                                                    {month.name}
                                                                </MenuItem>)
                                                        }
                                                    </Field>
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <FormControl sx={{width: "100px"}}>
                                                    <InputLabel className={"formControl"} htmlFor="year">
                                                        {translate.t("year")}
                                                    </InputLabel>
                                                    <Field
                                                        id="year"
                                                        variant={"outlined"}
                                                        value={year}
                                                        name="year"
                                                        component={Select}
                                                        onChange={(event: any) => setYear(event.target.value)}
                                                    >
                                                        {
                                                            listYear.map((year) =>
                                                                <MenuItem key={year} value={year}>
                                                                    {year}
                                                                </MenuItem>)}
                                                    </Field>
                                                </FormControl>
                                            </Grid>
                                            <Grid display="flex" alignItems="center" item>
                                                <ArrowForwardIcon
                                                    style={{cursor: "pointer"}}
                                                    onClick={() => changeMonth("right")}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs/>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>
            <div style={{backgroundColor: "white"}}>
                <Calendar
                    date={date}
                    onNavigate={onNavigate}
                    views={["month"]}
                    toolbar={false}
                    selectable
                    onSelectEvent={handleSelectEvent}
                    localizer={localize}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    eventPropGetter={eventStyleGetter}
                    showAllEvents
                    defaultDate={defaultDate}
                    formats={formats}
                    components={{
                        dateCellWrapper: dateCellWrapperSlot,
                        month: {
                            event: customEvent,
                        },
                    }}
                />
                <Grid container direction="row" spacing={2} style={{padding: 12}}>
                    {
                        listEventType.map((event) =>
                            <Grid
                                item
                                key={event.id}
                            >
                                <Grid container>
                                    <Grid item style={{display: "flex", alignItems: "center"}}>
                                        <div
                                            style={{
                                                backgroundColor: event.color,
                                                width: 12,
                                                height: 12,
                                                borderRadius: 100,
                                                marginRight: 6
                                            }}
                                        />
                                    </Grid>
                                    <Grid item style={{paddingTop: 1}}>
                                        <div>
                                            {event.description}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    }
                </Grid>
            </div>
            <DialogCalendarComponent
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                typeOperation={typeOperation}
                setTypeOperation={setTypeOperation}
                calendarEvent={calendarEvent}
                setCalendarEvent={setCalendarEvent}
                listEventType={listEventType}
                eventStart={eventStart}
                searchCalendarEvent={searchCalendarEvent}
            />
        </section>
    );
};

export default CalendarComponent;
