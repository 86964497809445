import BaseModel from "../default/base-model";
import {Group} from "./group";

export class User extends BaseModel {
    name: string;
    group: Group[] | undefined;
    username: string;
    password: string;
    email: string;
    is_superuser: boolean;
    is_active: boolean;
    userStatus: string;
    associated: boolean;

    confirmPassword: string;


    constructor() {
        super();
        this.username = "";
        this.password = "";
        this.email = "";
        this.name = "";
        this.userStatus = "ACTIVE";
        this.is_active = true;
        this.is_superuser = false;
        this.group = [];
        this.associated = false;
        this.confirmPassword = "";
    }
}
