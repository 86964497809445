import {format, parseISO} from "date-fns";

export default class Helpers {

    static formatDate(date: string | undefined) {
        if (date) {
            const parseDate = parseISO(date);
            return format(
                parseDate,
                "dd'/'MM'/'yyyy' 'HH:mm:ss"
            );
        }
    }

}
