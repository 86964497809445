import BaseModel from "../default/base-model";
import {Demand} from "./demand";
import {ProductionRamp} from "./production-ramp";

export class GroupPlanning extends BaseModel {
    public demand: Demand;
    public production_ramp: ProductionRamp;

    constructor() {
        super();
        this.demand = new Demand();
        this.production_ramp = new ProductionRamp();
    }
}
