import BaseModel from "../default/base-model";
import {Product} from "./product";
import {Version} from "./version";

export const DEMAND_STATUS = {
    NONE: "0",
    TO_PLAN: "TP",
    PLANNED: "P",
    IN_PRODUCTION: "IP",
    FINALIZED: "F",
    LINE_RETURN: "LR"
};

export class Demand extends BaseModel {
    quantity: number;
    production_order: string;
    color: string;
    planned: boolean;
    font_color: string;
    status: string | undefined;
    status_name: string;
    code_demand_origin: number | undefined;
    demand_type: string | undefined;
    return_line_number: number | undefined;
    planned_quantity: number;
    reference_date: string;
    product: Product | any;
    version: Version | any;
    demand_return_line: any;
    scrap: any;
    balance: any;
    bone_pile: any;

    planned_qty: any;
    line_setting: any;
    top_bot_different_lines: any;
    manual_default_time: any;
    production_ramp: any;

    constructor() {
        super();
        this.quantity = 0;
        this.production_order = "";
        this.color = "";
        this.planned = false;
        this.font_color = "";
        this.status = undefined;
        this.status_name = "";
        this.code_demand_origin = undefined;
        this.demand_type = undefined;
        this.return_line_number = undefined;
        this.planned_quantity = 0;
        this.reference_date = "";
        this.product = "";
        this.version = "";
        this.demand_return_line = undefined;
        this.scrap = undefined;
        this.balance = undefined;
        this.bone_pile = undefined;
    }
}

export class DemandValidate {
    result: boolean;
    message: string;

    constructor() {
        this.result = false;
        this.message = "";
    }
}

export class DemandStatus {
    value: string;
    label: string;

    constructor() {
        this.value = "";
        this.label = "";
    }
}
