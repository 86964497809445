import React, {useEffect} from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {translate} from "../../../../translate/translate";
import {Box, Fab, Grid, Paper, TextField, Typography} from "@mui/material";
import {Field, Form, Formik} from "formik";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import {Close, Search} from "@mui/icons-material";
import {DailyProductionPeripheral} from "../../../../models/basic/daily-production-peripheral";
import {PaginatedResult} from "../../../../models/default/paginated-result";
import TablePaginationComponent from "../../../../components/table/table-pagination.component";
import TableActionComponent from "../../../../components/table/table-action.component";
import {ErrorComponent} from "../../../../components/error/error.component";

const DailyProductionPeripheralDialog = (props: any) => {
    const {
        open,
        onClose,
        data,
    } = props;
    const service = new BaseService<DailyProductionPeripheral>(URLS.DAILY_PRODUCTION_PERIPHERAL);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [productionOrder, setproductionOrder] = React.useState<string>("");
    const [pagination, setPagination] = React.useState<PaginatedResult<DailyProductionPeripheral>>();
    const [dataSource, setDataSource] = React.useState<DailyProductionPeripheral[]>([]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const search = () => {
        if (data) {
            service.clearParameters();
            service.addParameter("active", true);
            service.addParameter("date_of_production", data.date);
            service.addParameter("operation", data.operation);
            service.addParameter("production_line", data.production_line);
            service.addParameter("shift", data.shift);
            service.addParameter("demand", data.demand);
            service.addParameter("expand", "production_order_peripheral");
            if (productionOrder) {
                service.addParameter("production_order", productionOrder);
            }
            service.addParameter("limit", rowsPerPage);
            service.addParameter("offset", (page * rowsPerPage));
            service.getAllPaginate().then(response => {
                setPagination(response.data);
            }).catch((error: any) => {
                ErrorComponent(error);
            });
        }

    };

    useEffect(() => {
        setDataSource(pagination?.results ?? []);
    }, [pagination]);

    useEffect(() => {
        search();
    }, []);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">

            <Grid padding={2} container justifyContent="space-between" sx={{backgroundColor: "primary.main"}}>
                <Grid item>
                    <Typography sx={{color: "#ffffff", fontWeight: "bold", fontSize: "16px"}}>
                        {translate.t("production_order_peripheral")}
                    </Typography>
                </Grid>
                <Grid item sx={{cursor: "pointer", color: "#ffffff"}}>
                    <Close onClick={onClose}/>
                </Grid>
            </Grid>
            <DialogContent>

                <Grid item>
                    <Box p={2}>
                        <Formik
                            enableReinitialize={true}
                            validateOnMount={true}
                            initialValues={{op: ""}}
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    search();
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {() => (
                                <Form autoComplete="off">
                                    <Grid alignItems="center" container spacing={2} direction={"row"}>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="production_order"
                                                label={translate.t("production_order")}
                                                fullWidth
                                                variant={"outlined"}
                                                autoFocus={true}
                                                value={productionOrder}
                                                onChange={(event: React.ChangeEvent<{
                                                    value: unknown
                                                }>) => setproductionOrder(event.target.value as string)}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Fab
                                                size="small"
                                                type="submit"
                                                color="secondary">
                                                <Search/>
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Grid>

                <Paper variant="outlined" sx={{marginTop: "16px"}}>
                    <TablePaginationComponent
                        state={dataSource}
                        displayedColumnsShow={["production_order", "quantity"]}
                        displayedColumns={["production_order", "quantity"]}
                        count={pagination?.count}
                        next={pagination?.next}
                        previous={pagination?.previous}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        actionsComponent={TableActionComponent}
                        columnStyles={{1: "20%", 2: "20%", 3: "20%", 4: "20%"}}/>
                </Paper>
            </DialogContent>
        </Dialog>
    );
};

export default DailyProductionPeripheralDialog;
