import {createTheme} from "@mui/material";
import {yellow} from "@mui/material/colors";
import InterMedium from "../fonts/InterMedium.ttf";
import styled from "@emotion/styled";

export default class Theme {
    static theme = createTheme({
        palette: {
            primary: {
                main: "#2537A8",
                contrastText: "#ffffff",
            },
            secondary: {
                main: "#2962FF",
                contrastText: "#ffffff",
            },
            error: {
                main: "#f44336",
                contrastText: "#ffffff",
            },
            success: {
                main: "#4CAF4F",
                contrastText: "#ffffff",
            },
            warning: {
                main: yellow[800],
            },
            background: {
                default: "#F0F3F6",
            }
        },
        typography: {
            h5: {
                fontWeight: 700,
            },
            button: {
                fontWeight: 700,
                fontSize: 13,
                elevation: 0,
                textTransform: "none",
                minWidth: "fit-content",
            },
            fontSize: 12,
            fontFamily: [
                "InterMedium",
                "sans-serif",
            ].join(",")
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: `
        @font-face {
          font-family: "InterMedium";
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local("InterMedium"), local("InterMedium"), url(${InterMedium}) format("ttf");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, 
          U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `
            }
        }
    });

    static headerStyle = styled.div({
        appBar: {
            boxShadow: "none",
        },
        grow: {
            flexGrow: 1,
        },
        logo: {
            height: 38,
            marginRight: 16,
        },
        headerTitle: {
            marginLeft: 18,
            fontWeight: 500,
        },
        list: {
            width: 250,
        },
        fullList: {
            width: "auto",
        },
    });
}
