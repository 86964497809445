import {translate} from "../../../translate/translate";
import {Box, Grid, Typography} from "@mui/material";
import * as React from "react";
import logo from "../../../../assets/images/logo-pplan.svg";

const HomeComponent = () => {
    return (
        <>
            <div className="bg-home">
                <div className="floating-title title-home">
                    <Typography variant={"h5"}>
                        {translate.t("welcome_home") + " P-Plan"}
                    </Typography>
                </div>
                <div>
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        className="grid-container">
                        <Grid item>
                            <Typography style={{paddingBottom: "8px"}} variant={"h5"}>{translate.t("welcome_home")}</Typography>
                        </Grid>
                        <Grid item>
                            <Box component={"img"} sx={{height: 60}} alt="Logo P-Plan" src={logo}/>
                        </Grid>
                        <Grid item>
                            <Typography style={{fontWeight: 400, fontSize: "1.2em"}}>{translate.t("message_home")}</Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
};

export default HomeComponent;
