import {BaseService} from "../../../../services/base-service";
import "./expression-builder-item.css";
import {
    Box,
    Button,
    Card,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {translate} from "../../../../translate/translate";
import {URLS} from "../../../../services/app-urls";
import {toast} from "react-toastify";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {ExpressionBuilder} from "../../../../models/basic/expression-builder";
import BackspaceIcon from "@mui/icons-material/Backspace";
import Select from "@mui/material/Select";
import {ErrorComponent} from "../../../../components/error/error.component";


const ExpressionBuilderItemComponent = () => {
    const [object, setObject] = React.useState(new ExpressionBuilder());
    const service = new BaseService<ExpressionBuilder>(URLS.EXPRESSION_BUILDER);
    const params = useParams();
    const url = "/settings/expression_builder/";
    const [buttonValue, setButtonValue] = useState("");
    const navigate = useNavigate();
    const [isDefault, setIsDefault] = React.useState(false);
    const [expression, setExpression] = useState("");
    const textColors = ["#7e7c7c", "#5a5a5b"];

    const schema = Yup.object().shape({
        name: Yup.string().max(256, `${translate.t("exceeds_character_limit")}`).required(translate.t("required_field")),
        return_value: Yup.string().required(translate.t("required_field")),
    });

    useEffect(() => {
        if (params["action"] != "create") {
            service.getById(parseInt(params["action"] as string)).then((response) => {
                setObject(response.data);
                setIsDefault(response.data.default);
                setExpression(response.data.expression.replace(/\|+/g, " "));
            });
        }
    }, []);

    const handleCardClick = (value: string) => {
        const newExpression = expression + " " + value + " ";
        if (newExpression.length <= 104) {
            setExpression(newExpression);
        }
    };

    const handleDeleteClick = () => {
        setExpression(prevExpression => {
            const valuesArray = prevExpression.trim().split(/\s+/);
            valuesArray.pop();
            return valuesArray.join(" ");
        });
    };

    const handleChangeIsDefault = (newValue: boolean) => {
        setIsDefault(newValue);
    };

    const onSubmit = (values: ExpressionBuilder, {resetForm}: FormikHelpers<ExpressionBuilder>) => {
        setTimeout(() => {
            const expression_builder = new ExpressionBuilder();
            Object.assign(expression_builder, values);
            expression_builder.expression = expression.replace(/\s+/g, "|");
            expression_builder.default = isDefault;
            if (expression_builder.id) {
                try {
                    service.patch(expression_builder, expression_builder.id)
                        .then(() => {
                            toast.success(translate.t("successfully_updated"));
                            if (buttonValue === "save") {
                                setObject(new ExpressionBuilder());
                                resetForm();
                                setIsDefault(false);
                                setExpression("");
                            } else {
                                navigate(url);
                            }
                        }).catch((error: any) => {
                            ErrorComponent(error);
                        });
                } catch (error) {
                    console.log(error);
                }
            } else {
                try {
                    service.save(expression_builder)
                        .then(() => {
                            toast.success(translate.t("successfully_registered"));
                            if (buttonValue === "save") {
                                setObject(new ExpressionBuilder());
                                resetForm();
                                setIsDefault(false);
                                setExpression("");
                            } else {
                                navigate(url);
                            }
                        })
                        .catch((error: any) => {
                            ErrorComponent(error);
                        });
                } catch (error) {
                    console.log(error);
                }
            }
        }, 500);
    };

    return (
        <section>
            <div className="floating-title floating-title-item"
                style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <IconButton
                    component={NavLink}
                    to={url}>
                    <ArrowBackIcon color="secondary" fontSize="large" fontWeight="fontWeightBold"/>
                </IconButton>
                <Typography variant={"h5"}>
                    {translate.t("expression_builder")}
                </Typography>
            </div>
            <div>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize
                            initialValues={object}
                            onSubmit={onSubmit}
                            validationSchema={schema}
                            validateOnChange={true}>
                            {({isValid, values, isValidating, errors, touched}) => (
                                <Form autoComplete="off">
                                    <Grid container spacing={2} direction={"row"}>
                                        <Grid container item xs={12} spacing={2} justifyContent={"flex-end"}>
                                            <Grid item xs>
                                                <Field
                                                    as={TextField}
                                                    name="name"
                                                    label={translate.t("name")}
                                                    variant={"outlined"}
                                                    fullWidth
                                                    autoFocus={true}
                                                    required={true}
                                                    values={values}
                                                    error={touched.name && Boolean(errors.name)}
                                                    InputProps={{
                                                        maxLength: 104,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {touched.name && Boolean(errors.name) && (
                                                                    <div className={"required-field"}>
                                                                        {translate.t("required_field")}
                                                                    </div>
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}/>
                                            </Grid>
                                            <Grid item xs>
                                                <FormControl fullWidth>
                                                    <InputLabel
                                                        required={true}
                                                        className={touched.return_value && Boolean(errors.return_value) ? "error-required" : ""}
                                                    >
                                                        {translate.t("return_value")}
                                                    </InputLabel>
                                                    <Field
                                                        as={Select}
                                                        name="return_value"
                                                        variant={"outlined"}
                                                        fullWidth
                                                        label={translate.t("return_value")}
                                                        error={touched.return_value && Boolean(errors.return_value)}
                                                        autoFocus={false}
                                                        required={true}
                                                        values={values}>
                                                        <MenuItem value="P">{translate.t("people_quantity")}</MenuItem>
                                                        <MenuItem value="C">Volume</MenuItem>
                                                        <MenuItem value="T">{translate.t("default_time")}</MenuItem>
                                                    </Field>
                                                    {(touched.return_value && Boolean(errors.return_value)) && (
                                                        <div className={"required-field"}>
                                                            {translate.t("required_field")}
                                                        </div>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container item>
                                            <Grid item xs>
                                                <Paper variant="outlined">
                                                    <Box display="flex" className="box-expression">
                                                        <Typography
                                                            variant="h6">{translate.t("assemble_expression")}</Typography>
                                                        <Typography variant="h6">
                                                            {expression.split(/\s+/).map((value, index) => (
                                                                <span
                                                                    key={index}
                                                                    style={{color: textColors[index % textColors.length]}}
                                                                >
                                                                    {translate.t(value)}{" "}
                                                                </span>
                                                            ))}
                                                        </Typography>

                                                        <Card onClick={handleDeleteClick}
                                                            className="button-delete">
                                                            <BackspaceIcon sx={{color: "white"}}/>
                                                        </Card>
                                                    </Box>
                                                    <Box className="box-operators" display="flex">
                                                        <Card onClick={() => handleCardClick("(")}
                                                            className="button-operators">
                                                            (
                                                        </Card>

                                                        <Card onClick={() => handleCardClick(")")}
                                                            className="button-operators">
                                                            )
                                                        </Card>
                                                        <Card onClick={() => handleCardClick("+")}
                                                            className="button-operators">
                                                            +
                                                        </Card>
                                                        <Card onClick={() => handleCardClick("-")}
                                                            className="button-operators">
                                                            -
                                                        </Card>
                                                        <Card onClick={() => handleCardClick("*")}
                                                            className="button-operators">
                                                            *
                                                        </Card>
                                                        <Card onClick={() => handleCardClick("/")}
                                                            className="button-operators">
                                                            /
                                                        </Card>
                                                    </Box>
                                                    <Box display="flex" className="box-operators-text">
                                                        <Card onClick={() => handleCardClick("PEOPLE_QUANTITY")}
                                                            className="button-operators">
                                                            {translate.t("people_quantity").toUpperCase()}
                                                        </Card>
                                                        <Card onClick={() => handleCardClick("CAPACITY")}
                                                            className="button-operators">
                                                            {translate.t("volume").toUpperCase()}
                                                        </Card>
                                                        <Card onClick={() => handleCardClick("AVAILABLE_TIME")}
                                                            className="button-operators">
                                                            {translate.t("available_time").toUpperCase()}
                                                        </Card>
                                                        <Card onClick={() => handleCardClick("DEFAULT_TIME")}
                                                            className="button-operators">
                                                            {(translate.t("default_time")).toUpperCase()}
                                                        </Card>
                                                    </Box>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs alignItems={"center"}>
                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        type="checkbox"
                                                        name="default"
                                                        component={Switch}
                                                        checked={isDefault}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                            handleChangeIsDefault(e.target.checked)}
                                                        color="secondary"
                                                    />
                                                }
                                                label={translate.t("set_default_expression")}
                                            />
                                        </Grid>
                                        <Grid item container direction={"row"}>
                                            <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="secondary"
                                                        component={NavLink}
                                                        to={url}
                                                        variant="outlined">
                                                        {translate.t("cancel")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="success"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save_form"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save_form"
                                                        disabled={!isValid || isValidating || !values || !expression}>
                                                        {translate.t("save_form")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="secondary"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save"
                                                        disabled={!isValid || isValidating || !values || !expression}>
                                                        {translate.t("save_plus")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
            </div>
        </section>
    );
};

export default ExpressionBuilderItemComponent;
