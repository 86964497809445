import * as React from "react";
import {useEffect, useState} from "react";
import {translate} from "../../../translate/translate";
import {
    Box,
    Button,
    Fab,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import TablePaginationComponent from "../../../components/table/table-pagination.component";
import {BaseService} from "../../../services/base-service";
import {Add, Search} from "@mui/icons-material";
import {URLS} from "../../../services/app-urls";
import {NavLink, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {PaginatedResult} from "../../../models/default/paginated-result";
import TableActionComponent from "../../../components/table/table-action.component";
import {ErrorMessage, Field, Form, Formik} from "formik";
import HistoryComponent from "../../../components/history/history.component";
import Select from "@mui/material/Select";
import {Product} from "../../../models/basic/product";
import {Segment} from "../../../models/basic/segment";
import AutocompletePaginated from "../../../components/autocomplete/autocompletePaginated";
import {ErrorComponent} from "../../../components/error/error.component";


const ProductComponent = () => {
    // URL Request
    const service = new BaseService<Product>(URLS.PRODUCT);
    const url = "/registration/product/";
    const navigate = useNavigate();

    // Services
    const segmentService = new BaseService<Segment>(URLS.SEGMENT);

    // Hooks
    const [dataSource, setDataSource] = React.useState<Product[]>([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [openHistory, setOpenHistory] = React.useState(false);
    const [idHistory, setIdHistory] = React.useState(0);
    const [searchName, setSearchName] = React.useState<string>("");
    const [searchStatus, setSearchStatus] = React.useState<string>("");
    const [searchType, setSearchType] = React.useState<string>("");
    const [searchSegment, setSearchSegment] = useState<Segment>();
    const [searchModel, setSearchModel] = React.useState<string>("");
    const [pagination, setPagination] = React.useState<PaginatedResult<Product>>();

    // Use effects
    useEffect(() => {
        setDataSource(pagination?.results ?? []);
    }, [pagination]);

    useEffect(() => {
        search();
    }, [page, rowsPerPage]);

    // Handle and get functions
    const handleOpenDialogHistory = (id: number) => {
        setIdHistory(id);
        setOpenHistory(true);
    };

    const handleCloseDialogHistory = () => {
        setOpenHistory(false);
    };

    const search = () => {
        service.clearParameters();
        if (searchName) {
            service.addParameter("name", searchName);
        }
        if (searchStatus) {
            service.addParameter("active", searchStatus);
        }
        if (searchType) {
            service.addParameter("type", searchType);
        }
        if (searchModel) {
            service.addParameter("model", searchModel);
        }
        if (searchSegment) {
            service.addParameter("segment", searchSegment.id);
        }
        service.addParameter("expand", "segment");
        service.addParameter("limit", rowsPerPage);
        service.addParameter("offset", (page * rowsPerPage));
        service.addParameter("ordering", "-id");
        service.getAllPaginate().then(response => {
            setPagination(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });

    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDoubleClick = (row: Product) => {
        navigate(`${url}${row.id}`);
    };

    const handleDelete = (row: Product) => {
        service.delete(Number(row.id)).then(() => {
            toast.success(translate.t("successfully_deleted"));
            service.getAll().then(() => {
                search();
            });
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const handleSwitch = (row: Product, column: keyof Product) => {
        (row[column] as boolean) = !row[column];
        const payload = {"active": row.active, "is_top_bottom": row.is_top_bottom};
        service.patch(payload, Number(row.id)).then(() => {
            toast.success(translate.t("successfully_updated"));
            service.clearParameters();
            search();
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    // Render return
    return (
        <section>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("product")}
                </Typography>
            </div>
            <div className="floating-button">
                <Button
                    component={NavLink}
                    to={url + "create"}
                    startIcon={<Add/>}
                    variant="contained"
                    color="secondary"
                    className={"button-new"}>
                    {translate.t("new")}
                </Button>
            </div>

            <Grid item xs={12}>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize={true}
                            validateOnMount={true}
                            initialValues={{name: ""}}
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    search();
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {
                                () => (
                                    <Form autoComplete="off">
                                        <Grid alignItems="center" container spacing={2} direction={"row"}>
                                            <Grid item xs>
                                                <Field
                                                    as={TextField}
                                                    name="name"
                                                    label={translate.t("name")}
                                                    helperText={<ErrorMessage name="name"/>}
                                                    fullWidth
                                                    variant={"outlined"}
                                                    autoFocus={true}
                                                    value={searchName}
                                                    onChange={(event: React.ChangeEvent<{
                                                        value: unknown
                                                    }>) => setSearchName(event.target.value as string)}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <Field
                                                    as={TextField}
                                                    name="model"
                                                    label={translate.t("model")}
                                                    helperText={<ErrorMessage name="name"/>}
                                                    fullWidth
                                                    variant={"outlined"}
                                                    autoFocus={false}
                                                    value={searchModel}
                                                    onChange={(event: React.ChangeEvent<{
                                                        value: unknown
                                                    }>) => setSearchModel(event.target.value as string)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                                <AutocompletePaginated
                                                    display="name"
                                                    label="segment"
                                                    service={segmentService}
                                                    onSelectElement={(segment: Segment) => {
                                                        setSearchSegment(segment);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                                <FormControl fullWidth>
                                                    <InputLabel className={"formControl"} htmlFor="type_product">
                                                        {translate.t("product_type")}
                                                    </InputLabel>
                                                    <Field
                                                        variant={"outlined"}
                                                        label={translate.t("product_type")}
                                                        defaultValue=""
                                                        name="type"
                                                        component={Select}
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setSearchType(event.target.value as string)}>
                                                        <MenuItem value="">-</MenuItem>
                                                        <MenuItem id="product" value="P">{translate.t("product")}</MenuItem>
                                                        <MenuItem id="component" value="C">{translate.t("component")}</MenuItem>
                                                    </Field>
                                                    <FormHelperText>
                                                        <ErrorMessage name="type"/>
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                                <FormControl fullWidth>
                                                    <InputLabel className={"formControl"} htmlFor="status">
                                                        {translate.t("status")}
                                                    </InputLabel>
                                                    <Field
                                                        variant={"outlined"}
                                                        label={translate.t("status")}
                                                        defaultValue=""
                                                        name="status"
                                                        component={Select}
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setSearchStatus(event.target.value as string)}>
                                                        <MenuItem value="">-</MenuItem>
                                                        <MenuItem value="true">
                                                            {translate.t("is_active")}</MenuItem>
                                                        <MenuItem value="false">
                                                            {translate.t("inactive")}</MenuItem>
                                                    </Field>
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <Fab
                                                    size="small"
                                                    type="submit"
                                                    color="secondary">
                                                    <Search/>
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>

            <Paper variant="outlined" sx={{marginTop: "16px"}}>
                <TablePaginationComponent
                    field={"type"}
                    service={service}
                    state={dataSource}
                    displayedColumnsShow={["#", "name", "segment", "model", "type", "pn_commercial", "is_top_bottom", "status", "action"]}
                    displayedColumns={["id", "name", "segment.name", "model", "type", "pn_commercial", "is_top_bottom", "active", "action"]}
                    handleDelete={handleDelete}
                    handleSwitch={handleSwitch}
                    url={url}
                    handleDoubleClick={handleDoubleClick}
                    count={pagination?.count}
                    next={pagination?.next}
                    previous={pagination?.previous}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    actionsComponent={TableActionComponent}
                    handleOpenDialogHistory={handleOpenDialogHistory}
                    columnStyles={{1: "8%", 2: "15%", 3: "15%", 4: "12%", 5: "12%", 6: "15%", 7: "8%", 8: "8%"}}
                />
            </Paper>
            <HistoryComponent
                openHistory={openHistory}
                handleCloseDialogHistory={handleCloseDialogHistory}
                idHistory={idHistory}
                columns_history={["name", "segment", "model", "type", "pn_commercial", "is_top_bottom", "sides", "active"]}
                service={service}
            />
        </section>
    );
};

export default ProductComponent;
