import React, {useEffect} from "react";
import {
    Box,
    Fab,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField, Tooltip
} from "@mui/material";
import {translate} from "../../../../translate/translate";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import {Permission} from "../../../../models/account/permission";
import {toast} from "react-toastify";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Search} from "@mui/icons-material";
import Select from "@mui/material/Select";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import Divider from "@mui/material/Divider";
import TableActionComponent from "../../../../components/table/table-action.component";
import {ErrorComponent} from "../../../../components/error/error.component";

interface ContainerProps {
    groupId: any;
}

const GroupPermissionComponent = ({groupId}: ContainerProps) => {
    const service = new BaseService<Permission>(URLS.PERMISSION);
    const displayedColumns = ["name", "codename"];
    const [dataSource, setDataSource] = React.useState<Permission[] | []>([]);
    const [selectedStatus, setSelectedStatus] = React.useState<string>("");
    const [pagination, setPagination] = React.useState<any>();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [searchCodenameOrName, setSearchCodenameOrName] = React.useState<string>("");

    const search = () => {
        service.clearParameters();
        if (searchCodenameOrName) service.addParameter("codename_or_name", searchCodenameOrName);
        if (selectedStatus) service.addParameter("granted", selectedStatus);
        service.addParameter("group", groupId);
        service.addParameter("limit", rowsPerPage);
        service.addParameter("offset", (page * rowsPerPage));
        service.getAllFromListRoute("with_granted").then(response => {
            setPagination(response.data);
        });
    };

    useEffect(() => {
        setDataSource(pagination?.results ?? []);
    }, [pagination]);

    useEffect(() => {
        if (groupId) {
            search();
        }
    }, [groupId, page, rowsPerPage]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSwitch = (id: number, granted: boolean) => {
        service.clearParameters();
        service.saveFromDetailRoute({group: groupId, granted: !granted}, id, "grant").then(() => {
            search();
            toast.success(translate.t("successfully_updated"));
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const handlePermission = (
        granted: boolean) => {
        service.clearParameters();
        service.addParameter("group", groupId);
        const payload = {
            "group": groupId,
            "granted": granted,
        };
        service.postFromListRoute(payload, "grant_all").then(() => {
            search();
            toast.success(translate.t("successfully_updated"));
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const showPaginated = (from: number, to: number, count: number) => {
        return `${from} - ${to} ${translate.t("of")} ${count}`;
    };

    return (
        <Paper variant="outlined">
            <Box p={2}>
                <Grid item marginBottom={2}>
                    <Formik
                        enableReinitialize={true}
                        validateOnMount={true}
                        initialValues={{codename_or_name: "", status: ""}}
                        onSubmit={(values, {setSubmitting}) => {
                            setTimeout(() => {
                                setPage(0);
                                search();
                                setSubmitting(false);
                            }, 500);
                        }}
                    >
                        {() => (
                            <Form autoComplete="off">
                                <Grid alignItems={"center"} container spacing={2} direction={"row"}>
                                    <Grid item xs>
                                        <Field
                                            as={TextField}
                                            name="codename_or_name"
                                            label={translate.t("codename_or_name")}
                                            helperText={<ErrorMessage name="codename_or_name"/>}
                                            fullWidth
                                            variant={"outlined"}
                                            value={searchCodenameOrName}
                                            onChange={(event: React.ChangeEvent<{
                                                value: unknown
                                            }>) => setSearchCodenameOrName(event.target.value as string)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <FormControl fullWidth>
                                            <InputLabel className={"formControl"} htmlFor="status">
                                                Status
                                            </InputLabel>
                                            <Field
                                                variant={"outlined"}
                                                defaultValue=""
                                                name="status"
                                                component={Select}
                                                onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                    setSelectedStatus(event.target.value as string)}>
                                                <MenuItem value="">-</MenuItem>
                                                <MenuItem value="true">{translate.t("is_active")}</MenuItem>
                                                <MenuItem value="false">{translate.t("inactive")}</MenuItem>
                                            </Field>
                                            <FormHelperText><ErrorMessage
                                                name="status"/></FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <Fab
                                            size="small"
                                            type="submit"
                                            color="secondary">
                                            <Search/>
                                        </Fab>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow sx={{flexDirection: "row-reverse"}}>
                            {displayedColumns.map((column: string) => (
                                <TableCell
                                    key={column} sx={{paddingTop: "12px", paddingBottom: "12px"}}>
                                    {translate.t(column)}
                                </TableCell>
                            ))}
                            <TableCell
                                sx={{justifyContent: "flex-end"}}>
                                <Box
                                    component="div"
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "end"
                                    }}
                                >
                                    <Tooltip title={translate.t("deny_permission")}>
                                        <IconButton
                                            component="label"
                                            onClick={() => handlePermission(false)}
                                        >
                                            <ThumbDownAltIcon/>
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={translate.t("grant_permission")}>
                                        <IconButton
                                            color="secondary"
                                            component="label"
                                            onClick={() => handlePermission(true)}
                                        >
                                            <ThumbUpAltIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataSource.length > 0 ? (
                            dataSource.map((permission: any) => (
                                <TableRow
                                    key={permission.id}
                                    sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                    {displayedColumns.map((column: string) => (
                                        <TableCell key={column}>{permission[column]}</TableCell>
                                    ))}
                                    <TableCell>
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "end"
                                        }}>
                                            <Switch
                                                color="secondary"
                                                checked={permission.granted}
                                                onChange={() => handleSwitch(permission.id, permission.granted)}
                                            />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={displayedColumns.length + 1} sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    padding: "8px 0"
                                }}>
                                    {translate.t("empty_listing")}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <Divider/>
                <TablePagination
                    sx={{color: "#45485F", textAlign: "end", minHeight: "52px", maxHeight: "52px"}}
                    component="div"
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={12}
                    count={Number(pagination?.count ?? 0)}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TableActionComponent}
                    labelRowsPerPage={translate.t("rows_per_page")}
                    labelDisplayedRows={({from, to, count}) =>
                        showPaginated(from, to, count)
                    }
                />
            </Box>
        </Paper>
    );
};

export {GroupPermissionComponent};
