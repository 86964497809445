import BaseModel from "../default/base-model";

export class ProductionRamp extends BaseModel {
    name: string;
    days: number;
    is_npi: boolean;
    active: boolean;

    constructor() {
        super();
        this.name = "";
        this.days = 5;
        this.is_npi = false;
        this.active = true;
    }
}
