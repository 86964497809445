import BaseModel from "../default/base-model";
import {Shift} from "./shift";
import {ScheduledStop} from "./scheduled-stop";

export class ScheduledStopShift extends BaseModel {
    shift: string | Shift;
    scheduled_stop: string | ScheduledStop;

    constructor() {
        super();
        this.shift = "";
        this.scheduled_stop = "";
    }
}
