import {BaseService} from "../../../../services/base-service";
import {Box, Button, FormControl, FormControlLabel, Grid, InputAdornment, Paper, Radio, RadioGroup, TextField, Typography,} from "@mui/material";
import React, {useEffect, useState} from "react";
import {MuiColorInput} from "mui-color-input";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {translate} from "../../../../translate/translate";
import {URLS} from "../../../../services/app-urls";
import {toast} from "react-toastify";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {CalendarEventType} from "../../../../models/basic/calendar-event-type";
import {ErrorComponent} from "../../../../components/error/error.component";


const CalendarEventTypeItemComponent = () => {
    const [object, setObject] = React.useState(new CalendarEventType());
    const url = "/registration/calendar_event_type/";
    const navigate = useNavigate();
    const params = useParams();
    const baseService = new BaseService<CalendarEventType>(URLS.CALENDAR_EVENT_TYPE);
    const [buttonValue, setButtonValue] = useState("");
    const [colorValue, setColorValue] = React.useState("#000000");
    const [colorMessage, setColorMessage] = React.useState("");
    const [isWorkingDay, setIsWorkingDay] = React.useState(true);

    const schema = Yup.object().shape({
        description: Yup.string().required()
    });

    useEffect(() => {
        if (params["action"] !== "create") {
            baseService.getById(parseInt(params["action"] as string)).then((response) => {
                setObject(response.data);
                setColorValue(response.data.color);
                setIsWorkingDay(response.data.is_working_day);
            });
        }
    }, []);

    const colorValidate = (newColorValue: string) => {
        const regex = /^#[0-9A-Fa-f]{6}$/;
        if (newColorValue.length === 0) {
            setColorMessage(translate.t("required_field"));
            return false;
        } else if (!regex.test(newColorValue)) {
            setColorMessage(translate.t("invalid_format"));
            return false;
        } else {
            setColorMessage("");
            return true;
        }
    };

    const handleChangeColor = (newColorValue: string) => {
        colorValidate(newColorValue);
        setColorValue(newColorValue);
    };

    return (
        <section>
            <div className="floating-title floating-title-item" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <IconButton
                    component={NavLink}
                    to={url}>
                    <ArrowBackIcon color="secondary" fontSize="large" fontWeight="fontWeightBold"/>
                </IconButton>
                <Typography variant={"h5"}>
                    {translate.t("calendar_event_type")}
                </Typography>
            </div>
            <div>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize
                            initialValues={object}
                            onSubmit={(values: CalendarEventType, {resetForm}: FormikHelpers<CalendarEventType>) => {
                                setTimeout(() => {
                                    const calendar_event_type = new CalendarEventType();
                                    Object.assign(calendar_event_type, values);
                                    calendar_event_type.color = colorValue;
                                    calendar_event_type.is_working_day = isWorkingDay;
                                    if (colorValidate(colorValue) && calendar_event_type.id) {
                                        baseService.patch(calendar_event_type, calendar_event_type.id)
                                            .then(() => {
                                                toast.success(translate.t("successfully_updated"));
                                                if (buttonValue === "save") {
                                                    setObject(new CalendarEventType());
                                                    setColorValue("#000000");
                                                    setIsWorkingDay(true);
                                                    resetForm();
                                                } else {
                                                    navigate(url);
                                                }
                                            }).catch((error: any) => {
                                                ErrorComponent(error);
                                            });
                                    } else if (colorValidate(colorValue)) {
                                        baseService.save(calendar_event_type)
                                            .then(() => {
                                                toast.success(translate.t("successfully_registered"));
                                                if (buttonValue === "save") {
                                                    setObject(new CalendarEventType());
                                                    setColorValue("#000000");
                                                    setIsWorkingDay(true);
                                                    resetForm();
                                                } else {
                                                    navigate(url);
                                                }
                                            })
                                            .catch((error: any) => {
                                                ErrorComponent(error);
                                            });
                                    }
                                }, 500);
                            }}
                            validationSchema={schema}
                            validateOnChange={true}>
                            {({isValid, values, isValidating, errors, touched}) => (
                                <Form autoComplete="off">
                                    <Grid container spacing={2} direction={"column"}>
                                        <Grid container spacing={2} item xs direction={"row"}>
                                            <Grid item xs>
                                                <Field
                                                    as={TextField}
                                                    name="description"
                                                    label={translate.t("description")}
                                                    variant={"outlined"}
                                                    fullWidth
                                                    autoFocus={true}
                                                    required={true}
                                                    values={values}
                                                    error={touched.description && Boolean(errors.description)}
                                                    InputProps={{
                                                        maxLength: 54,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {touched.description && Boolean(errors.description) && (
                                                                    <div className={"required-field"}>
                                                                        {translate.t("required_field")}
                                                                    </div>
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}/>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                <MuiColorInput
                                                    name="color"
                                                    label={translate.t("color")}
                                                    helperText={colorMessage}
                                                    variant={"outlined"}
                                                    fullWidth
                                                    autoFocus={false}
                                                    format="hex"
                                                    isAlphaHidden
                                                    value={colorValue}
                                                    onChange={handleChangeColor}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction={"row"} justifyContent={"space-between"}>
                                            <Grid container direction="row" alignItems="center" spacing={5}>
                                                <Grid item>
                                                    <FormControl component="fieldset">
                                                        <Grid container alignItems="center">
                                                            <RadioGroup
                                                                row
                                                                name="is_working_day"
                                                                onChange={(event) =>
                                                                    setIsWorkingDay(event.target.value === "true")}
                                                            >
                                                                <FormControlLabel
                                                                    value="true"
                                                                    control={<Radio/>}
                                                                    checked={isWorkingDay}
                                                                    label={translate.t("useful_day")}
                                                                />
                                                                <FormControlLabel
                                                                    value="false"
                                                                    control={<Radio/>}
                                                                    checked={!isWorkingDay}
                                                                    label={translate.t("day_off")}
                                                                />
                                                            </RadioGroup>
                                                        </Grid>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid item container direction={"row"}>
                                                <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                                                    <Grid item>
                                                        <Button
                                                            className="button-options"
                                                            color="secondary"
                                                            component={NavLink}
                                                            to={url}
                                                            variant="outlined">
                                                            {translate.t("cancel")}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            className="button-options"
                                                            color="success"
                                                            variant="contained"
                                                            type="submit"
                                                            value="save_form"
                                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                                setButtonValue(event.currentTarget.value);
                                                            }}
                                                            name="save_form"
                                                            disabled={!isValid || isValidating}>
                                                            {translate.t("save_form")}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            className="button-options"
                                                            color="secondary"
                                                            variant="contained"
                                                            type="submit"
                                                            value="save"
                                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                                setButtonValue(event.currentTarget.value);
                                                            }}
                                                            name="save"
                                                            disabled={!isValid || isValidating}>
                                                            {translate.t("save_plus")}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                            }
                        </Formik>
                    </Box>
                </Paper>
            </div>
        </section>
    );
};

export default CalendarEventTypeItemComponent;
