import BaseModel from "../default/base-model";
import {Product} from "./product";
import {ProductionLine} from "./production-line";

export class ManualDefaultTime extends BaseModel {
    product: Product | any;
    lines: ProductionLine[] | any;
    production_line_list: any[];
    people_quantity: any;
    capacity: string;
    default_time_seconds: string;

    constructor() {
        super();
        this.product = "";
        this.people_quantity = "";
        this.capacity = "";
        this.default_time_seconds = "";
        this.production_line_list = [];
    }
}
