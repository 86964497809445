import {Box, Fab, FormControl, Grid, InputLabel, MenuItem, Paper, TextField, Typography} from "@mui/material";
import {translate} from "../../../translate/translate";
import * as React from "react";
import {useEffect, useState} from "react";
import * as echarts from "echarts/core";
import {GridComponent, TitleComponent, TooltipComponent} from "echarts/components";
import {BarChart} from "echarts/charts";
import {CanvasRenderer} from "echarts/renderers";

import EChartsReact, {useChart} from "echarts-for-react-fc";
import {Field, Form, Formik} from "formik";
import Select from "@mui/material/Select";
import {Search} from "@mui/icons-material";
import {BaseService} from "../../../services/base-service";
import {URLS} from "../../../services/app-urls";
import {ProductionLine} from "../../../models/basic/production-line";
import {ErrorComponent} from "../../../components/error/error.component";
import {toast} from "react-toastify";
import "./style.css";

echarts.use([GridComponent, BarChart, CanvasRenderer, TooltipComponent, TitleComponent]);


const UsageProductionLines = () => {
    const service = new BaseService<ProductionLine>(URLS.PRODUCTION_LINE);
    const {chartRef, setChartOption, handleListenChartReady} = useChart();
    const [start_date, setStartDate] = useState("");
    const [end_date, setEndDate] = useState("");
    const [type, setType] = React.useState<string>("S");
    const [groupBy, setGroupBy] = React.useState<string>("consolidated");
    const [chartData, setChartData] = useState<any>(undefined);
    const [showChart, setShowChart] = useState(false);
    const [chartHeight, setChartHeight] = useState<number>(550);
    const labelOption = () => {
        return {
            show: true,
            position: "insideLeft",
            distance: 15,
            align: "left",
            verticalAlign: "middle",
            formatter: "{c}% - {a}",
            fontSize: 16,
            fontWeight: "bold",
            textBorderWidth: 0,
            borderRadius: 4,
            padding: 6,
            color: "#fff"
        };
    };

    const handleStartDateChange = (event: any) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event: any) => {
        setEndDate(event.target.value);
    };

    useEffect(() => {
        if (chartData) {
            const options: any = getChartOptions();
            setChartOption(options, true);
        }
    }, [chartData]);

    const getChartOptions = () => {
        const series: any = [];
        if (chartData.series.length > 0) {
            chartData.series.forEach((el: any) => {
                series.push(
                    {
                        name: el.name,
                        type: "bar",
                        barGap: 0,
                        label: labelOption(),
                        data: el.data,
                    }
                );
            });
            setChartHeight(
                chartData.yAxis.length === 1 ?
                    550 :
                    Number(chartData.yAxis.length * (chartData.legend.length * 70))
            );
            setShowChart(true);
        } else {
            toast.warning(translate.t("no_records_found"));
            setShowChart(false);
        }

        return {
            title: {text: translate.t("production_line_usage")},
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow"
                }
            },
            legend: {
                data: chartData.legend,
                top: 40,
            },
            toolbox: {
                show: true,
                feature: {
                    restore: {show: true, title: translate.t("refresh")},
                    saveAsImage: {show: true, title: translate.t("save")}
                }
            },
            xAxis: {
                type: "value",
                min: 0,
                max: 100
            },
            yAxis: {
                inverse: true,
                type: "category",
                data: chartData.yAxis
            },
            series: series
        };
    };

    const search = () => {
        chartRef.current;
        service.clearParameters();
        service.addParameter("initial_date", start_date);
        service.addParameter("final_date", end_date);
        service.addParameter("process_type", type);
        service.addParameter("result", groupBy);
        service.getFromListRoute("usage")
            .then((response: any) => {
                if (response.data) {
                    setChartData(response.data);
                } else {
                    toast.warning(translate.t("no_records_found"));
                }
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
    };

    return (
        <Box>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("production_line_usage")}
                </Typography>
            </div>
            <Grid>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize={true}
                            validateOnMount={true}
                            initialValues={{type: "S", groupBy: "consolidated"}}
                            onSubmit={(values: any, {setSubmitting}) => {
                                setTimeout(() => {
                                    search();
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {
                                ({values, errors, touched}) => (
                                    <Form autoComplete="off">
                                        <Grid alignItems="center" container spacing={2} direction={"row"}>
                                            <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
                                                <FormControl fullWidth>
                                                    <InputLabel className={"formControl"} htmlFor="type">
                                                        {translate.t("type_process")} *
                                                    </InputLabel>
                                                    <Field
                                                        variant={"outlined"}
                                                        defaultValue="S"
                                                        label={translate.t("type_process")}
                                                        name="type"
                                                        component={Select}
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setType(event.target.value as string)}
                                                    >
                                                        <MenuItem value="S">{translate.t("SMT")}</MenuItem>
                                                        <MenuItem value="M">{translate.t("Manual")}</MenuItem>
                                                    </Field>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                                <Field
                                                    as={TextField}
                                                    id="id_initial_date"
                                                    name="initial_date"
                                                    label={translate.t("initial_date")}
                                                    values={values}
                                                    value={start_date}
                                                    autoFocus={false}
                                                    type="date"
                                                    fullWidth
                                                    required={true}
                                                    onChange={handleStartDateChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={touched.initial_date && Boolean(errors.initial_date)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                                <Field
                                                    as={TextField}
                                                    id="id_end_date"
                                                    name="end_date"
                                                    label={translate.t("final_date")}
                                                    values={values}
                                                    value={end_date}
                                                    autoFocus={false}
                                                    type="date"
                                                    fullWidth
                                                    required={true}
                                                    onChange={handleEndDateChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={touched.end_date && Boolean(errors.end_date)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                                <FormControl fullWidth>
                                                    <InputLabel className={"formControl"} htmlFor="groupBy">
                                                        {translate.t("result")} *
                                                    </InputLabel>
                                                    <Field
                                                        variant={"outlined"}
                                                        defaultValue="consolidated"
                                                        label={translate.t("result")}
                                                        name="groupBy"
                                                        component={Select}
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setGroupBy(event.target.value as string)}
                                                    >
                                                        <MenuItem
                                                            value="consolidated">{translate.t("consolidated")}
                                                        </MenuItem>
                                                        <MenuItem value="detailed">
                                                            {translate.t("detailed")}
                                                        </MenuItem>
                                                    </Field>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={0.6} lg={0.6}>
                                                <Fab
                                                    size="small"
                                                    type="submit"
                                                    disabled={!start_date || !end_date || !type || !groupBy}
                                                    color="secondary">
                                                    <Search/>
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>
            <Grid marginTop={2}>
                {showChart && (<Paper variant="outlined" sx={{marginTop: "10px"}}>
                    <EChartsReact
                        style={{
                            height: chartHeight,
                            padding: 10
                        }}
                        ref={chartRef}
                        echarts={echarts}
                        onChartReady={handleListenChartReady}
                    />
                </Paper>)}
            </Grid>
        </Box>
    );
};

export default UsageProductionLines;
