import React, {FC} from "react";
import "react-toastify/dist/ReactToastify.css";
import {CssBaseline, ThemeProvider} from "@mui/material";
import Theme from "./assets/theme/theme";
import {ToastContainer} from "react-toastify";
import {Routes} from "./app/routes/routes";

const App: FC<any> = () => {
    return (
        <ThemeProvider theme={Theme.theme}>
            <CssBaseline/>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover/>
            <Routes/>
        </ThemeProvider>
    );

};

export default App;
