import BaseModel from "../default/base-model";
import {ProductionLine} from "./production-line";
import {GroupPlanning} from "./group-planning";
import {ReasonReplanning} from "./reason-replanning";

export class WorkerPlanning extends BaseModel {
    public group_planning: GroupPlanning;
    public production_line: ProductionLine;
    public reason: ReasonReplanning;
    public type_production_line: string;
    public start: string;
    public end: string;
    public quantity: number;
    public setup_time: number;
    public status: string;
    public notes: string;
    public intercalate: boolean;
    public type_interruption: boolean;
    public duration: string;
    public first_start: string;

    constructor() {
        super();
        this.group_planning= new GroupPlanning();
        this.production_line = new ProductionLine();
        this.reason = new ReasonReplanning();
        this.type_production_line= "";
        this.start= "";
        this.end= "";
        this.quantity= 0;
        this.setup_time= 0;
        this.status= "";
        this.notes= "";
        this.intercalate= false;
        this.type_interruption= false;
        this.duration = "";
        this.first_start = "";
    }
}
