import BaseModel from "../default/base-model";

export class SmtTimeTable extends BaseModel {
    product: string;
    lines: string;

    constructor() {
        super();
        this.product = "";
        this.lines = "";
    }
}
