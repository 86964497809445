const messages = {
    pt: {
        translations: {
            code: "Código",
            description: "Descrição",
            search: "Buscar",
            active: "Ativo",
            created_at: "Criado em",
            modified_at: "Modificado em",
            actions: "Ações",
            cancel: "Cancelar",
            save: "Salvar",
            sector: "Setor",
            production_line: "Linha de produção",
            automatic_phase: "Fase automática",
            phase: "Fase",
            new_machine: "Nova Máquina",
            machine: "Máquina",
            process: "Processo",
            proceed: "Prosseguir",
            new: "Novo",
            cost_center: "Centro de custo",
            tag_code: "Código da etiqueta",
            criticality: "Criticidade",
            efficiency: "Eficiência",
            last_maintenance_date: "Data da última manutenção",
            frequency: "Periodicidade",
            utilization_index: "Índice de aproveitamento",
            used_calendar: "Calendário utilizado",
            conference_property: "Propriedade de conferência",
            security_interval: "Intervalo de seguranca",
            unlimited_resource: "Recurso ilimitado",
            area: "Área",
            sequence: "Sequência",
            centesimal_working_hours: "Horas úteis trabalhadas centesimais ",
            saturday_working_hours: "Horas úteis trabalhadas no sábado",
            sunday_working_hours: "Horas úteis trabalhadas no domingo",
            period_working_hours: "Horas úteis do período",
            period_used_hours: "Horas utilizadas no período",
            yield_percentage: "% Rendimento",
            warehouse_consumption: "Armazém de consumo",
            warehouse_description: "Descrição do armazém",
            shift: "Turno",
            initial: "Horário inicial",
            final: "Horário final",
            start: "Início",
            product: "Produto",
            unit_measurement: "Unidade de medida",
            abbreviation: "Sigla",
            rows_per_page: "Itens por página",
            product_details: "Detalhes do produto",
            item_code: "Código do item",
            alternative_code: "Código alternativo",
            specific_description: "Descrição específica",
            portuguese_description: "Descrição em português",
            english_description: "Descrição em inglês",
            nickname: "Apelido",
            username: "Usuário",
            password: "Senha",
            recoverPassword: "Redefinir senha",
            confirmPassword: "Confirmar senha",
            email: "E-mail",
            superuser: "Super Usuário",
            product_type: "Tipo do produto",
            raw_material: "Matéria-prima",
            finished_product: "Produto acabado",
            intermediate_product: "Produto intermediário",
            model: "Modelo",
            part_number: "Part number",
            part_number_client: "Part number do cliente",
            manufacturer: "Fabricante",
            expiration_date: "Prazo de validade",
            gross_weight: "Peso bruto",
            revision: "Revisão",
            last_revision: "Data da última revisão",
            minimum_grade: "Nota mínima",
            division: "Divisão",
            inventory_frequency: "Periodicidade do inventário",
            base_quantity_structure: "Quantidade base da estrutura",
            decimal_places_production_order: "Tipo de decimais para ordem de produção",
            normal_treatment_without_decimals: "Tratamento normal sem decimais",
            round: "Arredonda",
            increments: "Incrementa",
            truncate_decimal_places: "Trunca as casas decimais",
            model_car: "Modelo do veículo que recebe o produto acabado (BIKE/CAR)",
            direct_or_indirect_appropriation: "Apropriação direta ou indireta",
            direct: "Direta",
            indirect: "Indireta",
            optional_default_group: "Grupo de opcional padrão",
            truncate_or_round: "Trunca ou arredonda",
            truncate: "Trunca",
            block: "Bloqueio de tela",
            enter_mrp: "Entra no MRP?",
            attend_need: "Atende a necessidade",
            notes: "Observação",
            unit_of_measurement: "Unidade de medida",
            unit_measurement_internal: "Unidade de medida interna",
            unit_measurement_entry: "Unidade de medida de entrada",
            second_unit_measurement: "Segunda unidade de medida",
            conversion_factor: "Fator de conversão de unidade de medida",
            conversion_type: "Tipo de conversão da unidade de medida",
            multiplies: "Multiplica",
            divide: "Divide",
            cost_purchase: "Custo/Compra",
            default_supplier: "Fornecedor padrão",
            store_default_supplier: "Loja fornecedor padrão",
            minimum_quantity_order: "Ponto de pedido",
            reference_value_uss: "Valor referência US$",
            sale_price: "Preço de venda",
            last_purchase_currency: "Última moeda de compra",
            last_purchase_value: "Último valor de compra",
            last_purchase: "Data da última compra",
            default_cost_currency: "Moeda do custo standard",
            default_cost: "Custo standart",
            last_calculation_default_cost: "Data do último cálculo do custo padrão",
            fob_cost_currency: "Moeda do custo FOB",
            fob_cost: "Custo FOB",
            last_calculation_fob_cost: "Data do último cálculo do custo FOB",
            delivery_time: "Prazo de entrega",
            delivery_time_type: "Tipo do prazo de entrega (D/M/A)",
            hours: "Horas",
            days: "Dias",
            months: "Meses",
            years: "Anos",
            calculation_delivery_time_formula: "Formula cálculo do prazo",
            tolerance: "Tolerância",
            stock: "Estoque",
            stock_group: "Grupo de estoque",
            maximum_stock: "Estoque máximo",
            security_stock: "Estoque de segurança",
            economic_lot: "Lote econômico",
            minimum_lot: "Lote mínimo",
            packing_quantity: "Quantidade por embalagem",
            security_stock_formula: "Fórmula do estoque de segurança",
            default_warehouse: "Armazem padrão por requisição",
            traceability: "Rastreabilidade",
            product_origin: "Origem do produto",
            national: "Nacional",
            foreign_direct_import: "Estrangeira importação direta",
            foreign_adq_domestic_market: "Estrangeira-Adq no mercado interno",
            foreign: "Estrangeira",
            product_traceability: "Rastreabilidade do produto",
            subbatch_traceability: "Rastreabilidade sublote",
            does_not_use_traceability: "Não usa rastreabilidade",
            batch_traceability: "Rastreabilidade lote",
            default_production_map_operation: "Roteiro de operação padrão",
            copy_quantity_bar_code: "Quantidade cópias para código de barras",
            bar_code: "Código de barras",
            imported_product: "Produto importado",
            purchased_out_state: "Comprado fora do estado",
            family: "É família",
            ghost: "É fantasma",
            controls_addressing: "Controla endereçamento",
            own_third_or_party: "Produção própria ou terceiro",
            own: "Própria",
            third: "Terceiro",
            product_base: "Produto base",
            product_component: "Produto componente",
            loss_index: "Índice de perda",
            initial_validity: "Data inicial da validade",
            final_validity: "Data final da validade",
            process_time: "Tempo do processo",
            quantity_type: "Quantidade fixa/variável",
            optional_group: "Grupo de opcionais",
            item_optional_group: "Item do grupo de opcionais",
            initial_revision: "Revisão inicial do componente",
            final_revision: "Revisão final do componente",
            product_level: "Nível do produto",
            reverse_product_level: "Nível invertido",
            lot_potency: "Potência do lote",
            warehouse: "Armazém de consumo",
            quantity: "Quantidade",
            alternative_use: "Usa alternativo?",
            file_imported: "Arquivo importado",
            import_file: "Importar arquivo",
            import: "Importar",
            user: "Usuário",
            name: "Nome",
            legacy_code: "Código legado",
            group: "Grupo",
            menu: "Menu",
            visible: "Visível",
            permission: "Permissão",
            successfully_updated: "Atualizado com sucesso",
            successfully_registered: "Cadastrado com sucesso",
            successfully_deleted: "Excluído com sucesso",
            successfully_uploaded: "Importado com sucesso",
            required_field: "O campo é obrigatório.",
            exceeds_character_limit: "Limite de caracteres excedido.",
            must_be_a_integer_number: "deve ser um número inteiro",
            must_be_a_number: "deve ser um número",
            unique_validation_failure: "falha de validação única",
            must_be_a_positive_number: "deve ser um número positivo",
            cannot_insert_update_duplicate_key: "Não é possível inserir ou atualizar um registro duplicado",
            fixed: "Fixo",
            variable: "Variável",
            invalid_time_format: "Formato de hora inválido",
            invalid_date_format: "Formato de data inválido",
            default_moment_locale: "pt-br",
            token_error_redirecting: "Token inválido. Redirecionando...",
            of: "de",
            basic_inputs: "Cadastros básicos",
            product_process: " Produto - Processo",
            invalid_file: "Arquivo inválido",
            enter: "Entrar",
            welcome_home: "Bem vindo ao",
            invalid_credentials: "Não é possível realizar login com as credenciais fornecidas.",
            logout: "Sair",
            profile: "Perfil",
            session_expired: "Sua sessão expirou.",
            session_information: "Faça login para continuar conectado.",
            login_failed: "Não é possível realizar login, verifique sua conexão.",
            confirm: "Confirmar",
            edit: "Editar",
            delete: "Deletar",
            save_form: "Salvar",
            superUser: "Administrador",
            confirm_delete: "Você tem certeza que deseja excluir esse item?",
            password_not_match: "As senhas não são iguais",
            confirm_recover_password_tittle: "Confirmar redefinição de senha",
            confirm_recover_password_text: "Você tem certeza de que deseja redefinir sua senha?",
            product_line: "Linha de produção",
            planning: "Planejamento",
            simulation: "Simulação",
            is_active: "Ativo",
            is_superuser: "Administrador",
            undefined: "Indefinido",
            module: "Módulo",
            root_description: "Módulo",
            empty_listing: "Nenhum registro encontrado.",
            no_shift: "Nenhum turno encontrado.",
            new_password: "Nova senha",
            confirm_new_password: "Confirmar nova senha",
            security: "Segurança",
            status: "Status",
            inactive: "Inativo",
            codename: "Código",
            redefine_password: "Redefinir senha",
            grant_permission: "Conceda todas as permissões.",
            deny_permission: "Negue todas as permissões.",
            codename_or_name: "Nome / Código",
            error_user_selected: "Selecione um usuário para adicionar a permissão.",
            history: "Histórico",
            invalid_edit_group: "Já existe um grupo com este nome.",
            invalid_edit_user: "Já existe um usuário com esses dados.",
            general: "Geral",
            type_process: "Tipo de processo",
            hybrid: "Híbrido",
            available_production_line: "Estações disponíveis para linha de produção:",
            used_production_line: "Utilizados na linha de produção:",
            info_ordination: "Arraste os itens para ordená-los.",
            history_update: "Editado",
            history_create: "Adicionado",
            history_delete: "Excluído",
            is_staff: "É pessoal",
            is_default: "É padrão",
            true: "Sim",
            false: "Não",
            modified_to: "modificado para",
            segment: "Segmento",
            station: "Estação",
            unscheduled_stop: "Parada não programada",
            confirm_type_process: "Confirmar edição",
            confirm_type_process_text: "Esta linha de produção possui estação associada, você realmente deseja alterar o tipo de processo? Associações serão perdidas.",
            programmed_shutdown: "Parada programada",
            duration: "Duração",
            type: "Tipo",
            commercial: "Part Number Commercial",
            name_or_commercial: "Part Number Commercial ou Produto",
            start_validity: "Inicio da vigência",
            end_validity: "Fim da vigência",
            search_name: "Buscar nome",
            search_programmed: "Buscar parada programada",
            validity_start_date: "Início da vigência",
            validity_end_date: "Fim da vigência",
            start_time: "Hora de início",
            end_time: "Hora de término",
            error_required_field: "Verifique se todos os campos obrigatórios foram preenchidos.",
            invalid_group: "Já existe um grupo com este nome.",
            replanning_reason: "Motivo de Replanejamento",
            reason: "Motivo Padrão",
            cause: "Motivo Padrão",
            yes: "Sim",
            no: "Não",
            the_record_already_exists: "O registro já existe",
            loading: "Carregando",
            invalid_format: "Formato inválido",
            order: "Ordem",
            search_shift: "Buscar turno",
            workday: "Jornada de trabalho",
            length: "Duração",
            error_programmed_selected: "Selecione uma parada programada para associar ao turno.",
            module_options: "O que você deseja fazer?",
            production_capacity: "Capacidade de produção",
            basic: "Básico",
            reports: "Relatórios",
            settings: "Configurações",
            access_control: "Controle de acesso",
            invalid_email: "Você deve inserir um e-mail válido.",
            registrations: "Cadastros",
            demand: "Demanda",
            production_planning: "Planejamento de produção",
            replanning_history: "Histórico de replanejamento",
            smt_default_time: "Tempo padrão de SMT",
            manual_default_time: "Tempo padrão manual",
            scheduled_stop: "Paradas programadas",
            reason_replanning: "Motivo de replanejamento",
            production_ramp: "Rampa de produção",
            calendar_event_type: "Tipo de evento do calendário",
            calendar: "Calendário",
            marker: "Marcador",
            reason_replanning_report: "Motivos de replanejamento",
            production_line_usage: "Utilização de linhas de produção",
            realized_vs_planned: "Realizado vs planejado",
            preferences: "Preferências",
            expression_builder: "Construtor de expressões",
            headcount: "Quantidade de pessoas",
            stops: "Paradas",
            line_configuration: "Configuração de linha",
            default: "Padrão",
            color: "Cor",
            search_description: "Buscar descrição",
            useful_day: "Dia útil",
            day_off: "Dia de folga",
            pn_commercial: "PN Comercial",
            is_top_bottom: "Top-Bottom",
            component: "Componente",
            sides: "Lados",
            add: "Adicionar",
            production_ramp_settings: "Configurações da rampa",
            npi: "NPI",
            side: "Lado",
            version: "Versão",
            production_order: "Ordem de produção",
            reference_date: "Data de referência",
            font_color: "Cor da fonte",
            production_order_peripheral: "Ordem de produção dos periféricos",
            lines: "Linhas",
            production_ramp_day: "Dia ",
            validate_production_lines: "As linhas de produção devem conter as mesmas configurações",
            duplicate: "Duplicar",
            default_time_seconds: "Tempo padrão",
            people_quantity: "Quantidade de pessoas",
            capacity: "Capacidade",
            default_expression: "Expressão padrão:",
            initial_date: "Data inicial",
            final_date: "Data final",
            available: "Disponível",
            planned: "Planejado",
            in_production: "Em produção",
            finished: "Finalizado",
            January: "Janeiro",
            February: "Fevereiro",
            March: "Março",
            April: "Abril",
            May: "Maio",
            June: "Junho",
            July: "Julho",
            August: "Agosto",
            September: "Setembro",
            October: "Outubro",
            November: "Novembro",
            December: "Dezembro",
            message_home: "O P-Plan é um sistema de planejamento de produção.",
            save_plus: "Salvar +",
            close: "Fechar",
            clear: "Limpar",
            open: "Abrir",
            month: "Mês",
            year: "Ano",
            event: "Evento",
            start_date: "Data inicial",
            end_date: "Data final",
            validate_end_time: "Este tempo deve ser maior",
            validate_event_create: "Só é permitido criar um evento após a data atual",
            available_time: "Tempo disponível",
            return_value: "Valor de retorno",
            default_time: "Tempo padrão",
            assemble_expression: "Monte a expressão:",
            set_default_expression: "Definir como expressão padrão",
            AVAILABLE_TIME: "TEMPO DISPONÍVEL",
            DEFAULT_TIME: "TEMPO PADRÃO",
            PEOPLE_QUANTITY: "QUANTIDADE DE PESSOAS",
            CAPACITY: "VOLUME",
            volume: "Volume",
            day: "Dia",
            line: "Linha",
            duplicate_version: "Duplicar versão",
            delete_version: "Deletar versão",
            change_final_version: "Alterar para versão final",
            duplicate_version_successfully: "Versão duplicada com sucesso",
            confirm_make_version_final: "Deseja realmente tornar a versão ($description) como final?",
            to_plan: "Planejar",
            Sunday: "Domingo",
            Monday: "Segunda-Feira",
            Tuesday: "Terça-Feira",
            Wednesday: "Quarta-Feira",
            Thursday: "Quinta-Feira",
            Friday: "Sexta-Feira",
            Saturday: "Sábado",
            new_marker: "Novo marcador",
            peripheral: "Periféricos",
            interrupt_demand: "Interromper demanda",
            markers: "Marcadores",
            remove_planning_message: "Deseja remover o planejamento?",
            can_not_edit_planning_with_final_version: "Não é possível fazer alteração em uma versão de produção.Altere uma versão duplicada e depois publique",
            production_line_top: "Linha de produção TOP",
            production_line_bot: "Linha de produção BOTTON",
            demand_total: "Total da demanda",
            shift_to_start: "Turno para inicio",
            line_setup_stop: "Parada de setup de linha",
            different_line: "Fases A e B em linhas diferentes",
            shifts: "Turnos",
            production_line_backend: "Linha de produção backend",
            associate_shift: "Associar turno",
            no_associated_shift: "Nenhum turno associado",
            value_day_one: "Valor do dia 1",
            value_day_two: "Valor do dia 2",
            value_day_three: "Valor do dia 3",
            value_day_four: "Valor do dia 4",
            value_day_five: "Valor do dia 5",
            value_day_six: "Valor do dia 6",
            value_day_seven: "Valor do dia 7",
            production_line_required: "Selecione ao menos uma linha de produção",
            info_demand: "Informação da demanda",
            edit_demand: "Editar demanda",
            history_month: "Histórico no mês",
            balance: "Saldo",
            planned_quantity: "Planejado",
            move_balance: "Mover saldo",
            bone_pile: "Defeito",
            scrap: "Sucata",
            realized: "Realizado",
            finalize_demand: "Finalizar demanda",
            info_finalize_demand: "Deseja finalizar a demanda?",
            finalize: "Finalizar",
            smt_configuration: "Sem configuração de tempo SMT",
            manual_configuration: "Sem configuração de tempo Manual",
            unknown_error: "Oops... ocorreu algo inesperado!",
            new_simulation: "Nova simulação",
            new_version: "Nova versão",
            all_rights_reserved: "Todos os direitos reservados.",
            developed_by: "Desenvolvido por",
            edit_planning: "Editar planejamento",
            smt_warnning: "Tempo SMT",
            manual_warnning: "Tempo Manual",
            valid_quantity: "Quantidade não pode ultrapassar o valor da demanda",
            anticipate_planning: "Antecipar próximos planejamentos",
            replanning_amount: "Quantidade de replanejamento",
            date: "Data",
            must_be_bigger_equal_than_start_date: "Precisa ser maior que a data inicial",
            must_be_less_equal_than_final_date: "Precisa ser menor que a data final",
            line_setup: "Setup de linha",
            no_records_found: "Nenhum registro encontrado",
            demand_used_hours: "Horas usadas por demanda",
            group_by: "Agrupado por",
            consolidated: "Consolidado",
            detailed: "Detalhado",
            import_material_transit: "Importar materiais em trânsito",
            product_description: "Descrição do produto",
            delivery_date: "Data de entrega",
            result: "Resultado",
            side_a: "Lado A",
            side_b: "Lado B",
            max_qtd: "Quantidade máx. ",
            product_model: "Modelo do produto",
            title_event: "evento",
            attention: "Atenção",
            exists_planning: "Já existe planejamento neste período, deseja continuar e replanejar?",
            add_delay: "Preencha para retardar o inicio da linha de produção do backend.",
            delivery: "Entrega",
            planning_by_delivery: "Planejamento por entrega",
            no_planned: "Não planejado",
            input: "Insumo",
            create_demand: "Criar demanda",
            max_value: "O campo não pode ser maior que ",
            min_value: "O campo não pode ser menor que ",
            invalid_quantity: "Quantidade insulficiente para criar demanda",
            value: "Valor",
            MAIL_HOST: "E-mail host",
            MAIL_PORT: "E-mail porta",
            MAIL_SENDER: "Remetente de email",
            MAIL_PASSWORD: "Senha do e-mail",
            MAIL_USE_TLS: "Uso de TLS no e-mail",
            MAIL_USE_SSL: "Uso de SSL no e-mail",
            DEMAND_DAYS_BEFORE_VALIDATE: "Dias antes de validar demandas",
            EMAILS_TO_ALERT: "E-mails para alertar",
            PRODUCTION_PLAN_XLS_PATH: "Local para gerar o arquivo xls do planejamento",
            notifications: "Notificações",
            verify_demand: "Verificar demanda",
            production_line_manual: "Linha de produção manual",
            production_line_smt: "Linha de produção SMT",
            next: "Próximo",
            previous: "Anterior",
            select_demand: "Selecionar demanda",
            adjust_variables: "Ajustar variáveis",
            define_period_and_shifts: "Definir turnos e período",
            top_bottom_different_line: "Top / Bottom em linhas diferentes",
            planned_qty: "Qtd. planejada",
            head_count: "Head count",
            production_time: "Tempo de produção",
            ramp: "Rampa",
            input_a_value: "Digite o valor",
            greater_than_equal_0: "O campo deve ser maior ou igual a 0",
            generate_demand: "Gerar demandas",
            line_setting: "Configuração de linha",
            smt: "SMT",
            manual: "Manual",
            aligned: "Alinhado",
            create: "Criar",
            delete_simulation: "Excluir simulação",
            simulation_result: "Resultado da simulação",
        }
    }
};

export {messages};
