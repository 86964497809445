import {BaseService} from "../../../../services/base-service";
import {Box, Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Paper, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {translate} from "../../../../translate/translate";
import {URLS} from "../../../../services/app-urls";
import {toast} from "react-toastify";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {ScheduledStop} from "../../../../models/basic/scheduled-stop";
import Select from "@mui/material/Select";
import dayjs from "dayjs";
import {LocalizationProvider, TimeField} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {ErrorComponent} from "../../../../components/error/error.component";


const ScheduledStopItemComponent = () => {
    const [object, setObject] = React.useState(new ScheduledStop());
    const service = new BaseService<ScheduledStop>(URLS.SCHEDULED_STOP);
    const params = useParams();
    const url = "/registration/scheduled_stop/";
    const [buttonValue, setButtonValue] = useState("");
    const navigate = useNavigate();
    const [duration, setDuration] = useState<any>();
    const [durationFocus, setDurationFocus] = useState(false);

    const schema = Yup.object().shape({
        name: Yup.string().required(),
        type: Yup.string().required()
    });

    useEffect(() => {
        if (params["action"] != "create") {
            service.getById(parseInt(params["action"] as string))
                .then((response) => {
                    const formattedDuration = dayjs(response.data.duration, "HH:mm:ss");
                    setDuration(formattedDuration);
                    setObject(response.data);
                })
                .catch((error: any) => {
                    ErrorComponent(error);
                });
        }
    }, []);

    const handleDurationFocus = () => {
        setDurationFocus(true);
    };

    const isValidDuration = () => {
        const regex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
        return duration ? regex.test(duration.format("HH:mm:ss")) : false;
    };

    return (
        <section>
            <div className="floating-title floating-title-item" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <IconButton
                    component={NavLink}
                    to={url}>
                    <ArrowBackIcon color="secondary" fontSize="large" fontWeight="fontWeightBold"/>
                </IconButton>
                <Typography variant={"h5"}>
                    {translate.t("scheduled_stop")}
                </Typography>
            </div>
            <div>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize
                            initialValues={object}
                            onSubmit={(values: ScheduledStop, {resetForm}: FormikHelpers<ScheduledStop>) => {
                                setTimeout(() => {
                                    const scheduled_stop = new ScheduledStop();
                                    values.duration = duration.format("HH:mm:ss");
                                    Object.assign(scheduled_stop, values);
                                    if (scheduled_stop.id) {
                                        service.patch(scheduled_stop, scheduled_stop.id)
                                            .then(() => {
                                                toast.success(translate.t("successfully_updated"));
                                                if (buttonValue === "save") {
                                                    setObject(new ScheduledStop());
                                                    setDuration(undefined);
                                                    resetForm();
                                                } else {
                                                    navigate(url);
                                                }
                                            })
                                            .catch((error: any) => {
                                                ErrorComponent(error);
                                            });
                                    } else {
                                        service.save(scheduled_stop)
                                            .then(() => {
                                                toast.success(translate.t("successfully_registered"));
                                                if (buttonValue === "save") {
                                                    setObject(new ScheduledStop());
                                                    resetForm();
                                                    setDuration(undefined);
                                                } else {
                                                    navigate(url);
                                                }
                                            })
                                            .catch((error: any) => {
                                                ErrorComponent(error);
                                            });
                                    }
                                }, 500);
                            }}
                            validationSchema={schema}
                            validateOnChange={true}>
                            {({isValid, values, isValidating, errors, touched}) => (
                                <Form autoComplete="off">
                                    <Grid container spacing={2} direction={"row"}>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="name"
                                                label={translate.t("name")}
                                                variant={"outlined"}
                                                fullWidth
                                                autoFocus={true}
                                                required={true}
                                                values={values}
                                                error={touched.name && Boolean(errors.name)}
                                                InputProps={{
                                                    maxLength: 104,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {touched.name && Boolean(errors.name) && (
                                                                <div className={"required-field"}>
                                                                    {translate.t("required_field")}
                                                                </div>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}/>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TimeField
                                                    name="duration"
                                                    autoFocus={false}
                                                    fullWidth
                                                    format="HH:mm:ss"
                                                    label={translate.t("duration")}
                                                    value={duration}
                                                    onChange={(value: any) => setDuration(value)}
                                                    onBlur={handleDurationFocus}
                                                    InputLabelProps={{tabIndex: 1, variant: "outlined"}}
                                                    required={true}
                                                    className={(!isValidDuration() && durationFocus) ? "error-label-autocomplete" : ""}
                                                    InputProps={{
                                                        className: (!isValidDuration() && durationFocus) ? "error-autocomplete" : "",
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {!isValidDuration() && durationFocus && (
                                                                    <div className={"required-field"}>
                                                                        {translate.t("required_field")}
                                                                    </div>
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs>
                                            <FormControl fullWidth>
                                                <InputLabel required={true} className={touched.type && Boolean(errors.type) ? "error-required" : ""}>
                                                    {translate.t("type")}
                                                </InputLabel>
                                                <Field
                                                    as={Select}
                                                    name="type"
                                                    variant={"outlined"}
                                                    fullWidth
                                                    label={translate.t("type")}
                                                    error={touched.type && Boolean(errors.type)}
                                                    autoFocus={false}
                                                    required={true}
                                                    values={values}>
                                                    <MenuItem value="S">{translate.t("stops")}</MenuItem>
                                                    <MenuItem value="L">{translate.t("line_setup")}</MenuItem>
                                                </Field>
                                                {(touched.type && Boolean(errors.type)) && (
                                                    <div className={"required-field"}>
                                                        {translate.t("required_field")}
                                                    </div>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item container direction={"row"}>
                                            <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="secondary"
                                                        component={NavLink}
                                                        to={url}
                                                        variant="outlined">
                                                        {translate.t("cancel")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="success"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save_form"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save_form"
                                                        disabled={!isValid || isValidating || !values.name ||
                                                            !isValidDuration || !values.type}>
                                                        {translate.t("save_form")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="secondary"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save"
                                                        disabled={!isValid || isValidating || !values.name ||
                                                            !isValidDuration || !values.type}>
                                                        {translate.t("save_plus")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
            </div>
        </section>
    );
};

export default ScheduledStopItemComponent;
