import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography
} from "@mui/material";
import {Close, Delete, MoreVert} from "@mui/icons-material";
import {translate} from "../../../../../translate/translate";
import * as React from "react";
import {useState} from "react";

const TableMenuComponent = (props: any) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const openUserPanel = Boolean(anchorEl);
    const [open, setOpen] = React.useState(false);

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <IconButton color="secondary" size={"small"} onClick={handleClick}>
                <MoreVert></MoreVert>
            </IconButton>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={openUserPanel}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem onClick={() => {
                    handleOpenDialog();
                }}>
                    <ListItemIcon>
                        <Delete/>
                    </ListItemIcon>
                    <ListItemText>
                        {translate.t("delete")}
                    </ListItemText>
                </MenuItem>
            </Menu>

            <Dialog open={open} onClose={handleCloseDialog}>
                <Grid p={2} container justifyContent="space-between" sx={{backgroundColor: "primary.main"}}>
                    <Grid item>
                        <Typography sx={{color: "#ffffff", fontWeight: "bold", fontSize: "16px"}}>
                            {translate.t("delete")}
                        </Typography>
                    </Grid>
                    <Grid item sx={{cursor: "pointer", color: "#ffffff"}}>
                        <Close onClick={() => {
                            handleCloseDialog();
                        }}/>
                    </Grid>
                </Grid>
                <DialogContent>
                    <Grid container flexDirection="column" gap={2}>
                        <Grid item>
                            <Typography>
                                {translate.t("confirm_delete")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{fontWeight: "bold"}}>
                                {props.row.name}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="flex-end" gap={1} p={2}>
                        <Grid item>
                            <Button
                                color='secondary'
                                variant="outlined"
                                onClick={() => {
                                    handleCloseDialog();
                                }}>{translate.t("cancel")}</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color='error'

                                onClick={() => {
                                    props.handleDelete(props.row);
                                    setAnchorEl(null);
                                    handleOpenDialog();
                                }}>{translate.t("delete")}</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TableMenuComponent;
