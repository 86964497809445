import BaseModel from "../default/base-model";
import {ProductionLine} from "./production-line";
import {Product} from "./product";
import {SideProduct} from "./side-product";
import {DtoSmtDefaultTime} from "./smt-default-time";

export class SmtTime extends BaseModel {
    production_line_list: any[] | undefined;
    smt_default_time_list: DtoSmtDefaultTime[] | undefined;
    product_id: number | undefined;
    product: Product;
    lines: ProductionLine[];
    side_products: SideProduct[];

    constructor() {
        super();
        this.production_line_list = undefined;
        this.smt_default_time_list = [];
        this.product_id = 0;
        this.product = new Product();
        this.lines = [];
        this.side_products = [];
    }
}

export class SmtTimeUpdateCreate {
    id: number | undefined;
    production_line_list: string[];
    product: string;

    constructor() {
        this.id = 0;
        this.production_line_list = [];
        this.product = "";
    }
}
