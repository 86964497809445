import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {translate} from "../../../translate/translate";
import React, {useEffect, useState} from "react";
import {BaseService} from "../../../services/base-service";
import {URLS} from "../../../services/app-urls";
import {Shift} from "../../../models/basic/shift";
import {toast} from "react-toastify";
import {ProductionLine} from "../../../models/basic/production-line";
import {Headcount} from "../../../models/settings/headcount";
import {ErrorComponent} from "../../../components/error/error.component";
import {Check, Close} from "@mui/icons-material";

const HeadcountComponent = () => {
    const serviceShift = new BaseService<Shift>(URLS.SHIFT);
    const serviceProductionLine = new BaseService<ProductionLine>(URLS.PRODUCTION_LINE);
    const serviceHeadcount = new BaseService<Headcount>(URLS.HEADCOUNT);
    const [listShift, setListShift] = React.useState<Shift[]>([]);
    const [listProductionLine, setListProductionLine] = React.useState<ProductionLine[]>([]);
    const [listHeadcount, setListHeadcount] = React.useState<Headcount[]>([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [headcountDelete, setHeadcountDelete] = useState<Headcount>(new Headcount());
    const [eventDelete, setEventDelete] = useState<any>(undefined);

    const textFieldStyle = {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                border: "none",
            },
            "&:hover fieldset": {
                border: "none",
            },
            "&.Mui-focused fieldset": {
                border: "none",
            },
        },
    };

    const searchHeadcount = async () => {
        serviceHeadcount.clearParameters();
        serviceHeadcount.addParameter("active", "True");
        serviceHeadcount.addParameter("ordering", "id");
        return serviceHeadcount.getAll();
    };

    const searchShift = async () => {
        serviceShift.clearParameters();
        serviceShift.addParameter("active", "True");
        serviceShift.addParameter("ordering", "id");
        return serviceShift.getAll();
    };

    const searchProductionLine = async () => {
        serviceProductionLine.clearParameters();
        serviceProductionLine.addParameter("active", "True");
        serviceProductionLine.addParameter("ordering", "id");
        serviceProductionLine.addParameter("type", "M");
        return serviceProductionLine.getAll();
    };

    const searchAll = async () => {
        const shifts = await searchShift();
        const productionLines = await searchProductionLine();
        const headcounts = await searchHeadcount();
        setListShift(shifts.data);
        setListProductionLine(productionLines.data);
        setListHeadcount(headcounts.data);
    };

    useEffect(() => {
        searchAll().then();
    }, []);

    const handleInputChange = (event: any) => {
        event.target.value = event.target.value.replace(/\D/g, "");
    };

    const quantityOperations = (event: any, url_productionLine: string, url_shift: string) => {
        const value = String(event.target.value).trim();
        const obj = getHeadcount(url_productionLine, url_shift);
        if (value && obj === undefined) // Create
        {
            const headcount = new Headcount();
            headcount.production_line = url_productionLine;
            headcount.shift = url_shift;
            headcount.quantity = Number(value);

            serviceHeadcount.save(headcount)
                .then(() => {
                    toast.success(translate.t("successfully_registered"));
                    searchAll().then();
                })
                .catch((error: any) => {
                    ErrorComponent(error);
                });
        } else {
            if (value && obj?.quantity !== Number(value)) // Update
            {
                const headcount = new Headcount();
                headcount.production_line = url_productionLine;
                headcount.shift = url_shift;
                headcount.quantity = Number(value);

                serviceHeadcount.patch(headcount, obj?.id ?? 0)
                    .then(() => {
                        toast.success(translate.t("successfully_updated"));
                        searchAll().then();
                    }).catch((error) => {
                        ErrorComponent(error);
                    });
            } else if (!value && obj) // Delete
            {
                setOpenDialog(true);
                setHeadcountDelete(obj);
                setEventDelete(event);
            }
        }
    };

    const handleOnBlur = (event: any, url_productionLine: string, url_shift: string) => {
        quantityOperations(event, url_productionLine, url_shift);
    };

    const HandleOnKeyUp = (event: any, url_productionLine: string, url_shift: string) => {
        if (event.keyCode === 13) {
            quantityOperations(event, url_productionLine, url_shift);
        }
    };

    const getHeadcount = (url_productionLine: string, url_shift: string): Headcount | undefined => {
        return listHeadcount.find((headcount: Headcount) =>
            headcount.production_line === url_productionLine && headcount.shift === url_shift
        );
    };

    const getQuantity = (url_productionLine: string, url_shift: string): string => {
        const object = getHeadcount(url_productionLine, url_shift);
        return object === undefined ? "" : object.quantity.toString();
    };

    const setButtonDelete = (value: string) => {
        if (value === "delete") {
            serviceHeadcount.delete(headcountDelete.id ?? 0).then(() => {
                if (eventDelete !== undefined) {
                    eventDelete.target.value = "";
                }
                toast.success(translate.t("successfully_deleted"));
                setOpenDialog(false);
                searchAll().then();
            }).catch((error: any) => {
                ErrorComponent(error);
            });
        } else if (value === "cancel") {
            if (eventDelete !== undefined) {
                eventDelete.target.value = `${headcountDelete.quantity}`;
            }
            setOpenDialog(false);
        }
    };

    useEffect(() => {
        if (!openDialog) {
            setHeadcountDelete(new Headcount());
            setEventDelete(undefined);
        }
    }, [openDialog]);

    return (
        <section>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("headcount")}
                </Typography>
            </div>
            <div>
                <>
                    <TableContainer component={Paper} sx={{marginTop: 2}}>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{backgroundColor: "#2537A8", borderRight: "1px solid #ccc"}}>
                                        <div style={{color: "white"}}>
                                            {translate.t("line")}
                                        </div>
                                    </TableCell>
                                    {
                                        listShift.map((column: Shift, index: number) => (
                                            <TableCell
                                                key={index}
                                                align="left"
                                                sx={{backgroundColor: "#2537A8", borderRight: "1px solid #ccc"}}
                                            >
                                                <div style={{color: "white"}}>
                                                    {column.name}
                                                </div>
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    listProductionLine.map((productionLine: ProductionLine) => (
                                        <TableRow key={productionLine.id}>
                                            <TableCell sx={{backgroundColor: "#cfd8dc", borderRight: "1px solid #ccc"}}>
                                                {productionLine.name}
                                            </TableCell>
                                            {
                                                listShift.map((shift: Shift, index: number) => (
                                                    <TableCell key={index} sx={{margin: 0, padding: 0, borderRight: "1px solid #ccc"}}>
                                                        <TextField
                                                            sx={textFieldStyle}
                                                            fullWidth
                                                            id="outlined-required"
                                                            size="small"
                                                            defaultValue={getQuantity(
                                                                productionLine.url ?? "",
                                                                shift.url ?? ""
                                                            )}
                                                            onBlur={(event: any) => handleOnBlur(
                                                                event,
                                                                productionLine.url ?? "",
                                                                shift.url ?? ""
                                                            )}
                                                            onKeyUp={(event: any) => HandleOnKeyUp(
                                                                event,
                                                                productionLine.url ?? "",
                                                                shift.url ?? ""
                                                            )}
                                                            onInput={handleInputChange}
                                                            inputProps={{
                                                                maxLength: 3,
                                                            }}
                                                        />
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div>
                        <Dialog
                            open={openDialog}
                            onClose={() => {
                                setOpenDialog(false);
                            }}
                        >
                            <Grid
                                padding={2}
                                container
                                justifyContent="space-between"
                                sx={{backgroundColor: "primary.main"}}
                            >
                                <Grid item>
                                    <Typography sx={{color: "#ffffff", fontWeight: "bold", fontSize: "16px"}}>
                                        {translate.t("delete")}
                                    </Typography>
                                </Grid>
                                <Grid item sx={{cursor: "pointer", color: "#ffffff"}}>
                                    <Close onClick={() => {
                                        setOpenDialog(false);
                                    }}/>
                                </Grid>
                            </Grid>
                            <DialogContent sx={{textAlign: "center"}}>
                                <p style={{marginTop: 0}}>{translate.t("confirm_delete")}</p>
                                <p style={{marginBottom: 0, fontWeight: "bold"}}>{`${headcountDelete.quantity}`}</p>
                            </DialogContent>
                            <DialogActions sx={{paddingTop: 0}}>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    value="cancel"
                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                        setButtonDelete(event.currentTarget.value);
                                    }}
                                    name="btn_cancel"
                                >
                                    {translate.t("cancel")}
                                </Button>
                                <Button
                                    startIcon={<Check/>}
                                    color="error"
                                    variant="contained"
                                    value="delete"
                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                        setButtonDelete(event.currentTarget.value);
                                    }}
                                    name="btn_delete"
                                >
                                    {translate.t("delete")}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </>
            </div>
        </section>
    );
};

export default HeadcountComponent;