import React, {useContext, useEffect, useState} from "react";
import {Box, Button, ButtonGroup, Card, CardContent, Grid, TextField} from "@mui/material";
import {Field, Form, Formik} from "formik";
import {LoginModel} from "../../../models/default/login";
import bgLogin from "../../../../assets/images/bg-login.svg";
import logo from "../../../../assets/images/logo-login.svg";
import {translate} from "../../../translate/translate";
import {AuthContext} from "../../../contexts/auth-context";
import PropTypes from "prop-types";

const LoginComponent = () => {
    const [username, setUsername] = useState<string>("");
    const [usernameMessage, setUsernameMessage] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordMessage, setPasswordMessage] = useState<string>("");
    const {logout, login} = useContext(AuthContext);
    const [passwordTranslate, setPasswordTranslate] =
        useState<string>(translate.t("password"));
    const [usernameLabel, setUsernameLabel] =
        useState<string>(translate.t("username"));

    useEffect(() => {
        logout();
    }, []);

    const handleLanguageChange = (lng: string) => {
        translate.changeLanguage(lng).then();
        validate();
    };

    const validate = () => {
        let usernameOk;
        let passwordOk;

        if (username.length === 0) {
            usernameOk = false;
            setUsernameMessage(translate.t("required_field"));
        } else if (username.length > 64) {
            usernameOk = false;
            setUsernameMessage(translate.t("exceeds_character_limit"));
        } else {
            usernameOk = true;
            setUsernameMessage("");
        }

        if (password.length === 0) {
            passwordOk = false;
            setPasswordMessage(translate.t("required_field"));
        } else if (password.length > 64) {
            passwordOk = false;
            setPasswordMessage(translate.t("exceeds_character_limit"));
        } else {
            passwordOk = true;
            setPasswordMessage("");
        }

        setUsernameLabel(translate.t("username"));
        setPasswordTranslate(translate.t("password"));

        return (usernameOk && passwordOk);
    };

    return (
        <div className="bg-login"
            style={{
                backgroundImage: `url(${bgLogin})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                position: "absolute",
                bottom: 0,
                top: 0,
                right: 0,
                left: 0}}>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{minHeight: "100%"}}>
                <Grid item sx={{width: "400px"}}>
                    <Card elevation={4}>
                        <CardContent>
                            <Box
                                marginTop={4}
                                marginBottom={5}
                                component={"img"}
                                sx={{
                                    height: 32,
                                }}
                                alt="Logo P-Plan"
                                src={logo}/>
                            <Grid container alignItems="center" justifyContent="center">
                                <Grid item sx={{width: "90%"}}>
                                    <Formik
                                        initialValues={new LoginModel()}
                                        onSubmit={(values, {setSubmitting}) => {
                                            if (validate()) {
                                                setTimeout(() => {
                                                    login(username, password ?? "")
                                                        .finally(() => {
                                                            setSubmitting(false);
                                                        });
                                                }, 500);
                                            }
                                        }}
                                        validateOnChange={false}>
                                        {(props) => (
                                            <Form>
                                                <Grid container spacing={2} direction="column">
                                                    <Grid item>
                                                        <Field
                                                            as={TextField}
                                                            error={props.errors.username && props.touched.username}
                                                            name="username"
                                                            label={usernameLabel}
                                                            helperText={usernameMessage}
                                                            variant={"outlined"}
                                                            fullWidth
                                                            id="inp_username"
                                                            value={username}
                                                            onChange={(event: React.ChangeEvent<{
                                                                value: unknown
                                                            }>) => setUsername(event.target.value as string)}
                                                            autoFocus={true}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <Field
                                                            as={TextField}
                                                            error={props.errors.password && props.touched.password}
                                                            name="password"
                                                            type="password"
                                                            label={passwordTranslate}
                                                            helperText={passwordMessage}
                                                            variant={"outlined"}
                                                            fullWidth
                                                            id="inp_password"
                                                            value={password}
                                                            onChange={(event: React.ChangeEvent<{
                                                                value: unknown
                                                            }>) => setPassword(event.target.value as string)}
                                                            autoFocus={false}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            style={{
                                                                width: "100%",
                                                                height: "52px",
                                                                boxShadow: "none",
                                                            }}
                                                            className="bt-login"
                                                            size="large"
                                                            color="secondary"
                                                            variant="contained"
                                                            type="submit"
                                                            id="btn_submit"
                                                            disabled={!props.isValid}>
                                                            {translate.t("enter")}
                                                        </Button>
                                                    </Grid>
                                                    <Grid marginTop={2} container justifyContent="center">
                                                        <Grid item>
                                                            <ButtonGroup
                                                                variant="outlined"
                                                                size="small"
                                                                style={{alignItems: "center"}}>
                                                                <Button id="btn_pt" onClick={() =>
                                                                    handleLanguageChange("pt")}>
                                                                    PT
                                                                </Button>
                                                                <Button id="btn_en" onClick={() =>
                                                                    handleLanguageChange("en")}>
                                                                    EN
                                                                </Button>
                                                            </ButtonGroup>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        )
                                        }
                                    </Formik>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );

};

LoginComponent.propTypes = {
    errors: PropTypes.shape({
        username: PropTypes.string,
        password: PropTypes.string
    }),
    touched: PropTypes.shape({
        username: PropTypes.bool,
        password: PropTypes.bool
    }),
    isValid: PropTypes.bool
};

export default LoginComponent;
