import {Box, Button, Checkbox, FormControlLabel, Grid, InputAdornment, Paper, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import {translate} from "../../../../translate/translate";
import {ProductionRamp} from "../../../../models/basic/production-ramp";
import {ProductionRampItemShift} from "./production-ramp-item-shift.component";
import {ErrorComponent} from "../../../../components/error/error.component";

const ProductionRampItemComponent = () => {
    const service = new BaseService<ProductionRamp>(URLS.PRODUCTION_RAMP);
    const [object, setObject] = React.useState(new ProductionRamp());
    const [is_npi, setIsNPI] = React.useState(false);
    const [buttonValue, setButtonValue] = useState("");
    const params = useParams();
    const navigate = useNavigate();
    const url = "/registration/production_ramp/";

    const schema = Yup.object().shape({
        name: Yup.string().required(),
        days: Yup.number().required().min(1).max(7),
        is_npi: Yup.bool()
    });

    const newUrl = (value: any) => {
        const urlBase = `${url}${value}`;
        navigate(urlBase);
        window.history.replaceState(null, "", urlBase);
    };

    const onSubmit = (values: any, {resetForm}: FormikHelpers<ProductionRamp>) => {
        service.clearParameters();
        const productionRamp = new ProductionRamp();
        Object.assign(productionRamp, values);
        productionRamp.is_npi = is_npi;
        if (productionRamp.id) {
            service.patch(productionRamp, productionRamp.id).then((response) => {
                toast.success(translate.t("successfully_updated"));
                if (buttonValue === "save") {
                    setObject(new ProductionRamp());
                    resetForm();
                    setIsNPI(false);
                } else {
                    setObject(response.data);
                    newUrl(response.data.id);
                    navigate(url);
                }
            }).catch((error: any) => {
                ErrorComponent(error);
            });
        } else {
            service.save(productionRamp)
                .then(response => {
                    toast.success(translate.t("successfully_registered"));
                    if (buttonValue === "save") {
                        setObject(new ProductionRamp());
                        resetForm();
                        setIsNPI(false);
                    } else {
                        setObject(response.data);
                        newUrl(response.data.id);
                    }
                })
                .catch((error: any) => {
                    ErrorComponent(error);
                });
        }
    };

    useEffect(() => {
        if (params["action"] !== "create") {
            service.getById(parseInt(params["action"] as string)).then((response) => {
                setObject(response.data);
                setIsNPI(response.data.is_npi);
            });
        }
    }, []);

    const handleChangeIsNPI = (newValue: boolean) => {
        setIsNPI(newValue);
    };

    return (
        <section>
            <div className="floating-title floating-title-item" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <IconButton
                    component={NavLink}
                    to={url}>
                    <ArrowBackIcon color="secondary" fontSize="large" fontWeight="fontWeightBold"/>
                </IconButton>
                <Typography variant={"h5"}>
                    {translate.t("production_ramp")}
                </Typography>
            </div>
            <div>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize
                            initialValues={object}
                            onSubmit={onSubmit}
                            validationSchema={schema}
                            validateOnChange={true}>
                            {
                                ({isValid, values, isValidating, errors, touched}) => (
                                    <Form autoComplete="off">
                                        <Grid container gap={2} direction={"column"}>
                                            <Grid container gap={2} direction={"row"} sx={{width: "100%"}}>
                                                <Grid item xs>
                                                    <Field
                                                        as={TextField}
                                                        name="name"
                                                        label={translate.t("name")}
                                                        variant={"outlined"}
                                                        fullWidth
                                                        autoFocus={true}
                                                        required={true}
                                                        values={values}
                                                        error={touched.name && Boolean(errors.name)}
                                                        InputProps={{
                                                            maxLength: 104,
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {touched.name && Boolean(errors.name) && (
                                                                        <div className={"required-field"}>
                                                                            {translate.t("required_field")}
                                                                        </div>
                                                                    )}
                                                                </InputAdornment>
                                                            ),
                                                        }}/>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                                    <Field
                                                        as={TextField}
                                                        name="days"
                                                        label={translate.t("days")}
                                                        variant={"outlined"}
                                                        fullWidth
                                                        autoFocus={false}
                                                        required={true}
                                                        values={values}
                                                        error={touched.days && Boolean(errors.days)}
                                                        InputProps={{
                                                            pattern: "[1-7]",
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {touched.days && Boolean(errors.days) && (
                                                                        <div className={"required-field"}>
                                                                            {translate.t("required_field")}
                                                                        </div>
                                                                    )}
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        inputProps={{
                                                            pattern: "[1-7]",
                                                        }}/>
                                                </Grid>
                                            </Grid>
                                            <Grid item container justifyContent={"space-between"}>
                                                <Grid item xs alignItems={"center"}>
                                                    <FormControlLabel
                                                        control={
                                                            <Field
                                                                type="checkbox"
                                                                name="is_npi"
                                                                component={Checkbox}
                                                                checked={is_npi}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeIsNPI(e.target.checked)}
                                                                color="secondary"
                                                            />
                                                        }
                                                        label={translate.t("npi")}
                                                    />
                                                </Grid>
                                                <Grid item container direction={"row"}>
                                                    <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                                                        <Grid item>
                                                            <Button
                                                                className="button-options"
                                                                color="secondary"
                                                                component={NavLink}
                                                                to={url}
                                                                variant="outlined">
                                                                {translate.t("cancel")}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button
                                                                className="button-options"
                                                                color="success"
                                                                variant="contained"
                                                                type="submit"
                                                                value="save_form"
                                                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                                    setButtonValue(event.currentTarget.value);
                                                                }}
                                                                name="save_form"
                                                                disabled={!isValid || isValidating || !values.name || !values.days}>
                                                                {translate.t("save_form")}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button
                                                                className="button-options"
                                                                color="secondary"
                                                                variant="contained"
                                                                type="submit"
                                                                value="save"
                                                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                                    setButtonValue(event.currentTarget.value);
                                                                }}
                                                                name="save"
                                                                disabled={!isValid || isValidating || !values.name || !values.days}>
                                                                {translate.t("save_plus")}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                        </Formik>
                    </Box>
                </Paper>
            </div>
            {
                object.id && <ProductionRampItemShift productionRamp={object}/>
            }
        </section>
    );
};

export default ProductionRampItemComponent;
