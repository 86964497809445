import BaseModel from "../default/base-model";

export class SmtDefaultTime {
    smt_time: string;
    station: string;
    side_product: string;
    default_time_seconds: number;

    constructor() {
        this.smt_time = "";
        this.station = "";
        this.side_product = "";
        this.default_time_seconds = 0;
    }
}

export class DtoSmtDefaultTime extends BaseModel {
    smt_time: string;
    station: string;
    side_product: string;
    default_time_seconds: number;
    side_name: string;
    station_id: number;

    constructor() {
        super();
        this.smt_time = "";
        this.station = "";
        this.side_product = "";
        this.default_time_seconds = 0;
        this.side_name = "";
        this.station_id = 0;
    }
}
