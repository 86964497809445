import React, {useEffect, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import {translate} from "../../../../translate/translate";
import {BaseService} from "../../../../services/base-service";
import {ObjProductionLineStation} from "../../../../models/basic/production-line-station";
import {URLS} from "../../../../services/app-urls";
import {ProductionLine} from "../../../../models/basic/production-line";
import {DtoSmtDefaultTime, SmtDefaultTime} from "../../../../models/basic/smt-default-time";
import {toast} from "react-toastify";
import Typography from "@mui/material/Typography";
import {Check, Close} from "@mui/icons-material";
import {ErrorComponent} from "../../../../components/error/error.component";

const SmtDefaultTimeItemTableComponent = (props: any) => {
    const serviceSmtDefaultTime = new BaseService<SmtDefaultTime>(URLS.SMT_DEFAULT_TIME_);
    const serviceProductionLineStation = new BaseService<ObjProductionLineStation>(URLS.PRODUCTION_LINE_STATION);
    const {smtTime, getById} = props;
    const [listStation, setListStation] =
        useState<ObjProductionLineStation[]>([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [secondsDelete, setSecondsDelete] = useState<DtoSmtDefaultTime>(new DtoSmtDefaultTime());
    const [eventDelete, setEventDelete] = useState<any>(undefined);

    useEffect(() => {
        if (smtTime.id) {
            setListStation([]);
            getListStation();
        }
    }, [smtTime]);

    const searchTimeSeconds = (url_station:string, url_side_product:string):DtoSmtDefaultTime => {
        return smtTime.smt_default_time_list.find((smtDefaultTime:DtoSmtDefaultTime) =>
            smtDefaultTime.station === url_station && smtDefaultTime.side_product === url_side_product
        );
    };

    const formatTimeSeconds = (url_station:string, url_side_product:string):string => {
        const object = searchTimeSeconds(url_station, url_side_product);
        return object === undefined ? "" : object.default_time_seconds.toString() + " s";
    };

    const getListStation = () => {
        const fetchDataForLine = (line: ProductionLine) => {
            serviceProductionLineStation.clearParameters();
            serviceProductionLineStation.addParameter("production_line", line.id);
            serviceProductionLineStation.addParameter("expand", "station");
            return serviceProductionLineStation.getAll().then(response => response.data);
        };

        const fetchAllData = async () => {
            const promises = smtTime.lines.map((line: ProductionLine) => fetchDataForLine(line));
            const allData = await Promise.all(promises);
            const combinedData = allData.flat();
            const newList:ObjProductionLineStation[] = [];
            combinedData.forEach((obj:ObjProductionLineStation) => {
                if (newList.find((pls) => pls.station.id === obj.station.id) === undefined) {
                    newList.push(obj);
                }
            });
            setListStation(newList);
        };

        fetchAllData().then();
    };

    const getSeconds = (event:any, url_station:string, url_side_product:string) => {
        const value = String(event.target.value).replace("s", "").trim();
        const timeSeconds = searchTimeSeconds(url_station, url_side_product);
        if (value && timeSeconds === undefined) {
            const smtDefaultTime = new SmtDefaultTime();
            smtDefaultTime.smt_time = smtTime.url;
            smtDefaultTime.station = url_station;
            smtDefaultTime.side_product = url_side_product;
            smtDefaultTime.default_time_seconds = Number(value);
            serviceSmtDefaultTime.save(smtDefaultTime)
                .then(() => {
                    toast.success(translate.t("successfully_registered"));
                    getById(smtTime.id);
                })
                .catch((error: any) => {
                    ErrorComponent(error);
                });
        } else {
            if (value && timeSeconds.default_time_seconds !== Number(value)) {
                const smtDefaultTime = new SmtDefaultTime();
                smtDefaultTime.smt_time = smtTime.url;
                smtDefaultTime.station = url_station;
                smtDefaultTime.side_product = url_side_product;
                smtDefaultTime.default_time_seconds = Number(value);
                serviceSmtDefaultTime.patch(smtDefaultTime, timeSeconds.id ?? 0)
                    .then(() => {
                        toast.success(translate.t("successfully_updated"));
                        getById(smtTime.id);
                    }).catch((error: any) => {
                        ErrorComponent(error);
                    });
            } else if (!value && timeSeconds) {
                setOpenDialog(true);
                setSecondsDelete(timeSeconds);
                setEventDelete(event);
            }
        }
    };

    const handleInputChange = (event:any) => {
        event.target.value = event.target.value.replace(/\D/g, "");
    };

    const textFieldStyle = {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                border: "none",
            },
            "&:hover fieldset": {
                border: "none",
            },
            "&.Mui-focused fieldset": {
                border: "none",
            },
        },
    };

    const handleOnBlur = (event:any, url_station:string, url_side_product:string) => {
        getSeconds(event, url_station, url_side_product);
    };

    const HandleOnKeyUp = (event:any, url_station:string, url_side_product:string) => {
        if (event.keyCode === 13) {
            getSeconds(event, url_station, url_side_product);
        }
    };

    const setButtonDelete = (value: string) => {
        if (value === "delete") {
            serviceSmtDefaultTime.delete(secondsDelete.id ?? 0).then(() => {
                toast.success(translate.t("successfully_deleted"));
                setOpenDialog(false);
                getById(smtTime.id);
            }).catch((error: any) => {
                ErrorComponent(error);
            });
        } else if (value === "cancel") {
            setOpenDialog(false);
        }
    };

    useEffect(() => {
        if (!openDialog) {
            if (eventDelete !== undefined) {
                eventDelete.target.value = `${secondsDelete.default_time_seconds} s`;
            }
            setSecondsDelete(new DtoSmtDefaultTime());
            setEventDelete(undefined);
        }
    }, [openDialog]);

    return (
        <>
            <TableContainer component={Paper} sx={{marginTop: 2}}>
                {
                    listStation.length === 0 && (
                        <DialogContent>Nenhum registro encontrado</DialogContent>
                    )
                }
                {
                    listStation.length > 0 && (
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: "#2537A8" }}>
                                        <div style={{color: "white"}}>
                                            {translate.t("side")}s
                                        </div>
                                    </TableCell>
                                    {
                                        listStation.map((column: ObjProductionLineStation, index: number) => (
                                            <TableCell
                                                key={index}
                                                align="left"
                                                sx={{ backgroundColor: "#2537A8" }}
                                            >
                                                <div style={{color: "white"}}>
                                                    {column.station.name}
                                                </div>
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {smtTime.side_products.map((side_product: any) => (
                                    <TableRow
                                        key={side_product.id}
                                    >
                                        <TableCell sx={{ backgroundColor: "#cfd8dc" }}>
                                            {translate.t("side")} {side_product.name}
                                        </TableCell>
                                        {
                                            listStation.map((
                                                productionLineStation: ObjProductionLineStation,
                                                index: number
                                            ) => (
                                                <TableCell key={index} sx={{margin: 0, padding: 0}}>
                                                    <TextField
                                                        sx={textFieldStyle}
                                                        fullWidth
                                                        id="outlined-required"
                                                        size="small"
                                                        defaultValue={formatTimeSeconds(
                                                            productionLineStation.station.url ?? "",
                                                            side_product.url
                                                        )}
                                                        onBlur={(event:any) => handleOnBlur(
                                                            event,
                                                            productionLineStation.station.url ?? "",
                                                            side_product.url
                                                        )}
                                                        onKeyUp={(event:any) => HandleOnKeyUp(
                                                            event,
                                                            productionLineStation.station.url ?? "",
                                                            side_product.url
                                                        )}
                                                        onInput={handleInputChange}
                                                    />
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )
                }
            </TableContainer>
            <div>
                <Dialog
                    open={openDialog}
                    onClose={() => {
                        setOpenDialog(false);
                    }}
                >
                    <Grid
                        padding={2}
                        container
                        justifyContent="space-between"
                        sx={{backgroundColor: "primary.main"}}
                    >
                        <Grid item >
                            <Typography sx={{color: "#ffffff", fontWeight: "bold", fontSize: "16px"}}>
                                {translate.t("delete")}
                            </Typography>
                        </Grid>
                        <Grid item sx={{cursor: "pointer", color: "#ffffff"}}>
                            <Close onClick={() => {
                                setOpenDialog(false);
                            }}/>
                        </Grid>
                    </Grid>
                    <DialogContent sx={{textAlign: "center"}}>
                        <p style={{ marginTop: 0 }}>{translate.t("confirm_delete")}</p>
                        <p style={{ marginBottom: 0, fontWeight: "bold" }}>{`${secondsDelete.default_time_seconds} s`}</p>
                    </DialogContent>
                    <DialogActions sx={{paddingTop: 0}}>
                        <Button
                            color="secondary"
                            variant="outlined"
                            value="cancel"
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                setButtonDelete(event.currentTarget.value);
                            }}
                            name="btn_cancel"
                        >
                            {translate.t("cancel")}
                        </Button>
                        <Button
                            startIcon={<Check/>}
                            color="error"
                            variant="contained"
                            value="delete"
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                setButtonDelete(event.currentTarget.value);
                            }}
                            name="btn_delete"
                        >
                            {translate.t("delete")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
};

export default SmtDefaultTimeItemTableComponent;
