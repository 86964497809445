import "./planning-by-delivery.component.css";
import {Box, Fab, Grid, IconButton, Paper, Typography} from "@mui/material";
import {translate} from "../../../translate/translate";
import * as React from "react";
import {useEffect, useState} from "react";
import {Form, Formik} from "formik";
import AutocompletePaginated from "../../../components/autocomplete/autocompletePaginated";
import {Product} from "../../../models/basic/product";
import {Search, Assignment} from "@mui/icons-material";
import {ErrorComponent} from "../../../components/error/error.component";
import {BaseService} from "../../../services/base-service";
import {URLS} from "../../../services/app-urls";
import {DateNowEnUS} from "../../../components/date/date-timeFormat";
import {Delivery} from "../../../models/planning/delivery";
import DialogCreateDemandComponent from "./dialog-create-demand/dialog-create-demand.component";
import DialogPlanningDemandComponent from "../../basic/production-planning/dialog-planning-demand/dialog-planning-demand.component";
import {toast} from "react-toastify";
import Loading from "../../../components/loading/loading";


class DemandQuantityDto {
    balance: number;
    delivery: number;
    consuming_planned: boolean;

    constructor() {
        this.balance = 0;
        this.delivery = 0;
        this.consuming_planned = false;
    }
}

class ResultProductDto {
    product: string;
    product_id: number;
    balance: number;
    spreadsheet: number;
    not_planned: number;
    planned: number;
    demand_quantities: DemandQuantityDto[];

    constructor() {
        this.product = "";
        this.product_id = 0;
        this.balance = 0;
        this.spreadsheet = 0;
        this.not_planned = 0;
        this.planned = 0;
        this.demand_quantities = [];
    }
}

class ResultDto {
    days: string[];
    products: ResultProductDto[];

    constructor() {
        this.days = [];
        this.products = [];
    }
}

const PlanningByDeliveryComponent = () => {
    const service = new BaseService<Delivery>(URLS.DELIVERY);
    const productService = new BaseService<Product>(URLS.PRODUCT);
    const [searchProduct, setSearchProduct] = useState<Product>();
    const [dataSource, setDataSource] = React.useState<ResultDto>();
    const [selectedProduct, setSelectedProduct] = useState<any>();
    const [maxQuantity, setMaxQuantity] = useState<any>();
    const [createdDemand, setCreatedDemand] = useState<any>();
    const [openDialogPlanning, setOpenDialogPlanning] = useState(false);
    const [openDialogCreateDemand, setOpenDialogCreateDemand] = useState(false);
    const [loading, setLoading] = React.useState(false);

    const search = () => {
        setLoading(true);
        service.clearParameters();
        if (searchProduct) service.addParameter("product", searchProduct.id);
        const end = new Date();
        end.setDate(end.getDate() + 29);
        service.addParameter("start_date", DateNowEnUS());
        service.addParameter("end_date", DateNowEnUS(end));
        service.getFromListRoute("get_planning_by_delivery").then((response: any) => {
            setDataSource(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        }).finally(() => setLoading(false));
    };

    useEffect(() => {
        search();
    }, []);

    useEffect(() => {
        if (createdDemand) {
            setOpenDialogPlanning(true);
        }
    }, [createdDemand]);

    const formatDate = (date: string) => {
        return date.split("-")[2].padStart(2, "0") + "/" + date.split("-")[1].padStart(2, "0") + "/" + date.split("-")[0];
    };

    const getQuantityClass = (quantity: any) => {
        let css_class = "";
        if (quantity.balance < 0) {
            css_class = "warn";
        } else if (quantity.is_consuming_planned && quantity.delivery > 0){
            css_class = "info";
        }
        return css_class;
    };

    const handleOpenDialogPlanning = (planning: boolean, demand: any) => {
        setOpenDialogCreateDemand(false);
        if (planning) {
            setCreatedDemand(demand);
        }
    };

    const handleOpenDialogCreateDemand = (event: any, item: any) => {
        const availableQuantity = item.spreadsheet - (item.not_planned + item.planned);
        if (availableQuantity > 0) {
            setSelectedProduct(item.product_id);
            setMaxQuantity(availableQuantity >= 0 ? availableQuantity : 0);
            setOpenDialogCreateDemand(true);
        } else {
            toast.error(translate.t("invalid_quantity"));
        }
    };

    const handleCloseDialog = (result: boolean) => {
        setOpenDialogPlanning(false);
        if (result) {
            search();
        }
    };

    return (
        <Box minHeight={"100%"} display={"flex"} flexDirection={"column"}>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("planning_by_delivery")}
                </Typography>
            </div>
            <Loading open={loading}/>
            <Grid>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize={true}
                            validateOnMount={true}
                            initialValues={{name: ""}}
                            onSubmit={(values: any, {setSubmitting}) => {
                                setTimeout(() => {
                                    search();
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {
                                () => (
                                    <Form autoComplete="off">
                                        <Grid alignItems="center" container spacing={2} direction={"row"}>
                                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                                <AutocompletePaginated
                                                    display="name"
                                                    label="product"
                                                    service={productService}
                                                    onSelectElement={(product: Product) => {
                                                        setSearchProduct(product);
                                                    }}
                                                    autoFocus={true}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={0.6}>
                                                <Fab
                                                    size="small"
                                                    type="submit"
                                                    color="secondary">
                                                    <Search/>
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>

            {dataSource && (<div className="mat-repeat-container">
                <div className="mat-repeat-scroller">
                    <Box position="sticky" top="0" zIndex={3}>
                        <Box width="auto" display="flex" flexDirection="row">
                            <Box position="sticky" left="0" display="flex" flexDirection="column" className="product">
                                <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-date">
                                    <Typography variant="body2" color="white">{translate.t("product")}</Typography>
                                </Box>
                            </Box>
                            <Box position="sticky" left="150px" display="flex" flexDirection="column" className="header-date">
                                <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-date">
                                    <Typography variant="body2">{translate.t("balance")}</Typography>
                                </Box>
                            </Box>
                            <Box position="sticky" left="240px" display="flex" flexDirection="column" className="header-date">
                                <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-date">
                                    <Typography variant="body2">{translate.t("input")}</Typography>
                                </Box>
                            </Box>
                            <Box position="sticky" left="330px" display="flex" flexDirection="column" className="header-date">
                                <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-date">
                                    <Typography variant="body2">{translate.t("planned")}</Typography>
                                </Box>
                            </Box>
                            <Box position="sticky" left="420px" display="flex" flexDirection="column" className="header-date">
                                <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-date">
                                    <Typography variant="body2">{translate.t("no_planned")}</Typography>
                                </Box>
                            </Box>
                            <Box position="sticky" left="510px" display="flex" flexDirection="column" className="header-date">
                                <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-date">
                                    <Typography variant="body2">{translate.t("planning")}</Typography>
                                </Box>
                            </Box>
                            {dataSource.days.map((day, index) => {
                                return (
                                    <Box key={index} display="flex" flexDirection="column" className="header-date">
                                        <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-date">
                                            <Typography variant="body2">{formatDate(day)}</Typography>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                    {dataSource.products.map((item, index) => {
                        return (
                            <Box key={index}>
                                <Box display="flex" flexDirection="row">
                                    <Box position="sticky" zIndex={2} left="0" display="flex" flexDirection="column" className="product">
                                        <Box style={{backgroundColor: "#f5f5f5", paddingLeft: "8px", paddingRight: "8px"}}
                                            display="flex" flex="1" justifyContent="left" alignItems="center" overflow="hidden">
                                            <Typography variant="body2" fontWeight="bold">{item.product}</Typography>
                                        </Box>
                                    </Box>
                                    <Box position="sticky" left="150px" display="flex" flexDirection="column" className="line-date">
                                        <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                            <Typography variant="body2">{item.balance}</Typography>
                                        </Box>
                                    </Box>
                                    <Box position="sticky" left="240px" display="flex" flexDirection="column" className="line-date">
                                        <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                            <Typography variant="body2">{item.spreadsheet}</Typography>
                                        </Box>
                                    </Box>
                                    <Box position="sticky" left="330px" display="flex" flexDirection="column" className="line-date">
                                        <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                            <Typography variant="body2">{item.planned}</Typography>
                                        </Box>
                                    </Box>
                                    <Box position="sticky" left="420px" display="flex" flexDirection="column" className="line-date">
                                        <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                            <Typography variant="body2">{item.not_planned}</Typography>
                                        </Box>
                                    </Box>
                                    <Box position="sticky" left="510px" display="flex" flexDirection="column" className="line-date">
                                        <Box onClick={(event) => handleOpenDialogCreateDemand(event, item)}
                                            display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                            <IconButton>
                                                <Assignment className="person-add"></Assignment>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    {item.demand_quantities.map((quantity, index) => {
                                        return (
                                            <Box key={index} display="flex" flexDirection="column" className="line-date">
                                                <Box className={getQuantityClass(quantity)} display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                                    <Typography variant="body2">{quantity.delivery} / {quantity.balance}</Typography>
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        );
                    })}
                </div>
            </div>)}
            {openDialogCreateDemand && (
                <DialogCreateDemandComponent style="width: 1200px!important; max-width: 1200px!important;"
                    open={openDialogCreateDemand} onClose={handleOpenDialogPlanning}
                    product={selectedProduct} maxQuantity={maxQuantity}></DialogCreateDemandComponent>)}
            {openDialogPlanning && (
                <DialogPlanningDemandComponent style="width: 1200px!important; max-width: 1200px!important;"
                    open={openDialogPlanning} onClose={handleCloseDialog}
                    demand={createdDemand}/>)}
        </Box>
    );
};

export default PlanningByDeliveryComponent;
