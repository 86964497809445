import {Backdrop, Box} from "@mui/material";
import loading from "../../../assets/images/double-ring-2s-200px.gif";
import * as React from "react";

const Loading = (props: any) => {
    const {open} = props;

    return (
        <Backdrop sx={{backgroundColor: "rgba(0,0,0,.75)", zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
            <Box component={"img"} sx={{height: 100, width: 100}} alt="Logo P-Plan" src={loading}/>
        </Backdrop>
    );
};

export default Loading;
