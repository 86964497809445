import {translate} from "../../translate/translate";
import {DateFormat} from "../date/date-timeFormat";

export const FieldsComponent = (changes: any, type: string, column: string, isDate: boolean) => {
    let field;

    if (type === "new") {
        field = changes.find((obj: any) => obj.field == column)?.new;
    } else if (type === "old") {
        field = changes.find((obj: any) => obj.field == column)?.old;
    }

    if (isDate){
        return DateFormat(field);
    } else if (field === undefined || field === null || field === "" || column === "password") {
        return "-";
    } else if (String(field) == "true" || String(field) == "false") {
        return translate.t(String(field));
    } else {
        return field;
    }
};
