import {Box, Button, Checkbox, Fab, FormControl, Grid, InputLabel, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Paper, Popover, Select, TextField, Typography,} from "@mui/material";
import {translate} from "../../../translate/translate";
import * as React from "react";
import {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {Version} from "../../../models/basic/version";
import {BaseService} from "../../../services/base-service";
import {URLS} from "../../../services/app-urls";
import {toast} from "react-toastify";
import {AccountCircle, Add, Assignment, ArrowRight, Delete, DisplaySettings, Edit, Error, GridOff, MoreVertOutlined, Mouse, PersonAdd, Search} from "@mui/icons-material";
import {Month} from "../../../dto/months";
import "./simulation.component.css";
import DialogComponent from "../../../components/dialog/dialog.component";
import {PlanningCalendar, PlanningMonthKey} from "../../../dto/planning-calendar";
import {DateNowEnUS} from "../../../components/date/date-timeFormat";
import {Tooltip} from "react-tooltip";
import {Marker} from "../../../models/basic/marker";
import {DailyPlanning} from "../../../models/basic/daily-planning";
import Divider from "@mui/material/Divider";
import {useNavigate} from "react-router-dom";
import {ErrorComponent} from "../../../components/error/error.component";
import DialogVersionComponent from "../production-planning/dialog-version/dialog-version.component";
import DailyProductionPeripheralDialog from "../production-planning/daily-production-peripheral-dialog/daily-production-peripheral-dialog.component";
import DialogMarkers from "../production-planning/dialog-markers/dialog-markers.component";
import DialogPlanningDemandComponent from "../production-planning/dialog-planning-demand/dialog-planning-demand.component";
import DialogInterruptDemandComponent from "../production-planning/dialog-interrupt-demand/dialog-interrupt-demand.component";
import DialogSimulationComponent from "./dialog-simulation/dialog-simulation.component";
import DialogCreateDemandComponent from "./dialog-create-demand/dialog-create-demand.component";
import {Simulation} from "../../../models/planning/Simulation";
import Loading from "../../../components/loading/loading";


const SimulationComponent = () => {
    const service = new BaseService<PlanningCalendar>(URLS.PLANNING);
    const serviceVersion = new BaseService<Version>(URLS.VERSION);
    const serviceMarker = new BaseService<Marker>(URLS.MARKER);
    const serviceDailyPlanning = new BaseService<DailyPlanning>(URLS.DAILY_PLANNING);
    const serviceSimulation = new BaseService<Simulation>(URLS.SIMULATION);
    const [selectedShift, setSelectedShift] = useState<any>();
    const [selectedLine, setSelectedLine] = useState<any>();
    const [selectedDailyPlanning, setSelectedDailyPlanning] = useState<any>();
    const [version, setVersion] = useState(new Version());
    const [searchDemand, setSearchDemand] = useState(false);
    const [versionList, setVersionList] = useState<Version[]>([]);
    const [markerList, setMarkerList] = useState<Marker[]>([]);
    const [dailyPlanningMarkers, setDailyPlanningMarkers] = useState<Marker[]>([]);
    const [start_date, setStartDate] = useState("");
    const [end_date, setEndDate] = useState("");
    const [openDialogMarkers, setOpenDialogMarkers] = useState(false);
    const [openVersionDialog, setOpenVersionDialog] = useState(false);
    const [openPeripheralDialog, setOpenPeripheralDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openDeletePlanningDialog, setOpenDeletePlanningDialog] = useState(false);
    const [openChangeFinalVersionDialog, setOpenChangeFinalVersionDialog] = useState(false);
    const [openEditPlanningDialog, setOpenEditPlanningDialog] = useState(false);
    const [openInterruptDemandDialog, setOpenInterruptDemandDialog] = useState(false);
    const [planning, setPlanning] = useState<PlanningCalendar>();
    const [monthKeys, setMonthKeys] = useState<PlanningMonthKey[]>([]);
    const [data, setData] = useState<any>();
    const navigate = useNavigate();
    const month_calendar = Month.loadObject();
    const [anchorEl, setAnchorEl] = useState(null);
    const [dailyAnchorEl, setDailyAnchorEl] = useState(null);
    const [markerAnchorEl, setMarkerAnchorEl] = useState(null);
    const [openSimulationDialog, setOpenSimulationDialog] = useState(false);
    const [openCreateDemandDialog, setOpenCreateDemandDialog] = useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleOpenDialogSimulation = () => {
        setOpenSimulationDialog(true);
        setDailyAnchorEl(null);
    };

    const removeSimulation = () => {
        serviceSimulation.clearParameters();
        serviceSimulation.addParameter("version", version.id);
        serviceSimulation.getFromListRoute("remove_simulation")
            .then(() => {
                reloadPlanningBoard();
                setSearchDemand(!searchDemand);
                toast.success(translate.t("successfully_deleted"));
            }).catch((error: any) => {
                ErrorComponent(error);
            });
    };

    const handleOpenCreateDemandDialog = () => {
        setOpenCreateDemandDialog(true);
        setDailyAnchorEl(null);
    };

    const handleVersionMenuOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleVersionMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDailyMenuOpen = (event: any, dailyPlanning: any, shift: any, line: any) => {
        setSelectedShift(shift);
        setSelectedLine(line);
        setSelectedDailyPlanning(dailyPlanning);
        setDailyAnchorEl(event.currentTarget);
    };

    const handleOpenDialogMarker = (event: any, dailyPlanning: any) => {
        event.stopPropagation();
        setSelectedDailyPlanning(dailyPlanning);
        setOpenDialogMarkers(true);
    };

    const handleDailyMenuClose = () => {
        setDailyAnchorEl(null);
    };

    const handleMarkerMenuClick = (event: any) => {
        setMarkerAnchorEl(event.currentTarget);
    };

    const handleMarkerMenuClose = () => {
        setMarkerAnchorEl(null);
    };

    const handleOpenVersionDialog = () => {
        setOpenVersionDialog(true);
        handleVersionMenuClose();
    };

    const handleOpenPeripheralDialog = () => {
        const dialogData = {
            date: selectedDailyPlanning["daily_planning_date"],
            operation: selectedDailyPlanning["status"],
            production_line: selectedLine.id,
            shift: selectedShift.id,
            demand: selectedDailyPlanning["demand_id"]
        };
        setData(dialogData);
        setOpenPeripheralDialog(true);
    };

    const handleClosePeripheralDialog = () => {
        setOpenPeripheralDialog(false);
    };

    const handleCloseDeleteDialog = (result: boolean) => {
        setOpenDeleteDialog(false);
        if (result) {
            if (version.final_version) {
                toast.error("can_not_edit_planning_with_final_version");
                return;
            }
            const data = {version: version.url};
            service.clearParameters();
            service.postFromListRoute(data, "remove_version")
                .then(
                    () => {
                        getVersionList();
                        toast.success(translate.t("successfully_deleted"));
                    })
                .catch((error: any) => {
                    ErrorComponent(error);
                });
        }
    };

    const handleCloseDeletePlanningDialog = (result: boolean) => {
        setOpenDeletePlanningDialog(false);
        if (result) {
            service.delete(selectedDailyPlanning.worker_planning_id)
                .then(
                    () => {
                        reloadPlanningBoard();
                        setSearchDemand(!searchDemand);
                        toast.success(translate.t("successfully_deleted"));
                    })
                .catch((error: any) => {
                    ErrorComponent(error);
                });
        }
    };

    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
        handleVersionMenuClose();
    };

    const handleOpenDeletePlanningDialog = () => {
        setOpenDeletePlanningDialog(true);
        setDailyAnchorEl(null);
    };

    const handleOpenEditPlanningDialog = () => {
        setOpenEditPlanningDialog(true);
    };

    const handleInterruptDemandDialog = () => {
        setOpenInterruptDemandDialog(true);
    };

    const handleCloseChangeFinalVersionDialog = (result: boolean) => {
        setOpenChangeFinalVersionDialog(false);
        if (result) {
            const data = {version: version.url};
            service.clearParameters();
            service.postFromListRoute(data, "set_as_final_version")
                .then(
                    () => {
                        getVersionList();
                        toast.success(translate.t("successfully_updated"));
                    })
                .catch((error: any) => {
                    ErrorComponent(error);
                });
        }
    };

    const handleOpenChangeFinalVersionDialog = () => {
        setOpenChangeFinalVersionDialog(true);
        handleVersionMenuClose();
    };

    const handleCloseVersionDialog = (version?: Version) => {
        setOpenVersionDialog(false);
        if (version) {
            getVersionList(version);
        }
    };

    const handleScroll = (event: any) => {
        const newScrollPosition = event.target.scrollLeft;

        const boxHeader = document.getElementById("boxHeader");
        if (boxHeader) {
            boxHeader.scrollLeft = newScrollPosition;
        }
    };

    const schema = Yup.object().shape({
        version: Yup.string(),
        start_date: Yup.date(),
        end_date: Yup.date(),
        production_line: Yup.string()
    });

    const handleVersionChange = (event: any) => {
        setVersion(event.target.value);
    };

    const handleStartDateChange = (event: any) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event: any) => {
        setEndDate(event.target.value);
    };

    const reloadPlanningBoard = () => {
        setLoading(true);
        service.clearParameters();
        service.addParameter("initial_date", start_date);
        service.addParameter("final_date", end_date);
        service.addParameter("version", version.id);
        service.getFromListRoute("get_planning_calendar")
            .then((response) => {
                const keys = [];
                keys.push(
                    {
                        "year": "0",
                        "month": "0",
                        "yearMonth": "Lines"
                    }
                );
                Object.keys(response.data.header).forEach(yearMonth => {
                    const yearMonthSplit = yearMonth.split("-");
                    keys.push(
                        {
                            "year": yearMonthSplit[0],
                            "month": yearMonthSplit[1],
                            "yearMonth": yearMonth
                        }
                    );
                });
                setMonthKeys(keys);
                setPlanning(response.data);
            }).catch((error: any) => {
                ErrorComponent(error);
            }).finally(() => setLoading(false));
        setDailyAnchorEl(null);
        setMarkerAnchorEl(null);
    };

    const getVersionList = (selectedVersion?: Version) => {
        serviceVersion.addParameter("simulation_version", "true");
        serviceVersion.getAll().then(response => {
            setVersionList(response.data);
            let simulation_version;
            if (selectedVersion) {
                simulation_version = response.data.find(x => x.id === selectedVersion.id) as Version;
            } else {
                simulation_version = response.data.find(x => x.simulation_version) as Version;
            }
            setVersion(simulation_version);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const getMarkerList = () => {
        serviceMarker.clearParameters();
        serviceMarker.addParameter("active", true);
        serviceMarker.getAll().then(response => {
            setMarkerList(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const handleDailyPlanningMarkers = () => {
        const dayMarkers = JSON.parse(JSON.stringify(markerList));
        if (selectedDailyPlanning.markers) {
            selectedDailyPlanning.markers.forEach((marker: any) => {
                const some = dayMarkers.find((m: any) => m.id === marker.marker);
                if (some) {
                    some.checked = true;
                }
            });
        }
        setDailyPlanningMarkers(dayMarkers);
    };

    const addMarker = (event: any, marker: Marker) => {
        if (selectedDailyPlanning.markers === null) {
            selectedDailyPlanning.markers = [];
        }
        const markers: any = [];
        selectedDailyPlanning.markers.forEach((marker: any) => markers.push(marker.url));
        const exists = markers.some((m: any) => m === marker.url);
        if (!exists) {
            markers.push(marker.url);
        } else if (exists) {
            const index = markers.indexOf(marker.url);
            markers.splice(index, 1);
        }
        serviceDailyPlanning.update({"marker": markers}, selectedDailyPlanning.daily_planning_id).then(
            () => toast.success(translate.t("successfully_updated"))
        );
        reloadPlanningBoard();
    };

    const tooltipDailyPlanning = (daily_planning: any) => {
        const hour = (daily_planning.shift_liquid_minutes / 60).toFixed(2);
        return translate.t("model") + ": " + daily_planning.model + " / " +
            translate.t("legacy_code") + ": " + daily_planning.product_description + " / " +
            translate.t("OP") + ": " + (daily_planning.production_order ? daily_planning.production_order : "-") + " / " +
            translate.t("available_time") + ": " + hour + translate.t("hours");
    };

    useEffect(() => {
        getMarkerList();
        setStartDate(DateNowEnUS());
        const end = new Date();
        end.setDate(end.getDate() + 14);
        setEndDate(DateNowEnUS(end));
        getVersionList();
    }, []);

    useEffect(() => {
        if (start_date && end_date) {
            reloadPlanningBoard();
        }
    }, [version]);

    const getStringMonth = (index: string) => {
        return Object.values(month_calendar)[Number(index) - 1] as string;
    };

    const handleDialogClose = (value: any) => {
        setSelectedDailyPlanning(value);
        setOpenEditPlanningDialog(false);
        setOpenInterruptDemandDialog(false);
        reloadPlanningBoard();
        setSearchDemand(!searchDemand);
    };

    const handleSimulationClose = (value: any) => {
        setOpenSimulationDialog(false);
        if (value) {
            reloadPlanningBoard();
        }
    };

    const handleCreateDemandClose = () => {
        setOpenCreateDemandDialog(false);
    };

    return (
        <Box>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("simulation")}
                </Typography>
            </div>
            <Loading open={loading}/>
            <Box className="main-content-planning">
                <Paper elevation={0} variant="outlined" sx={{
                    border: "0",
                    borderRadius: "0",
                    minHeight: "100%",
                    position: "relative",
                    marginLeft: 0,
                    width: "100%"
                }}>
                    {/* Filtro */}
                    <Box sx={{padding: "10px"}}>
                        <Formik
                            initialValues={{version: ""}}
                            validationSchema={schema}
                            validateOnChange={true}
                            enableReinitialize
                            onSubmit={(values: any, {setSubmitting}) => {
                                setTimeout(() => {
                                    setSubmitting(false);
                                }, 500);
                            }}>
                            {({isValid, values, isValidating, errors, touched}) => (
                                <Form autoComplete="off" lang="pt-BR">
                                    <Grid alignItems="center" container spacing={1} direction={"row"}>
                                        <Grid item xs lg={3}>
                                            <FormControl fullWidth>
                                                <InputLabel className={"formControl"} htmlFor="version">
                                                    {translate.t("version")}
                                                </InputLabel>
                                                <Field
                                                    as={TextField}
                                                    name="version"
                                                    variant={"outlined"}
                                                    fullWidth
                                                    component={Select}
                                                    autoFocus={false}
                                                    values={values}
                                                    value={version}
                                                    onChange={handleVersionChange}
                                                    error={touched.version && Boolean(errors.version)}
                                                >
                                                    {
                                                        versionList.map((version: any) =>
                                                            <MenuItem key={version.id} value={version}>
                                                                {version.name} {version.final_version ? " - *FINAL*" : ""}
                                                            </MenuItem>
                                                        )
                                                    }
                                                </Field>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <Fab
                                                size="small"
                                                color="secondary"
                                                onClick={handleVersionMenuOpen}>
                                                <MoreVertOutlined/>
                                            </Fab>
                                            <DialogVersionComponent
                                                open={openVersionDialog}
                                                onClose={handleCloseVersionDialog}
                                                version={version}
                                            />
                                            <DialogComponent
                                                open={openDeleteDialog} onClose={handleCloseDeleteDialog}
                                                labelButton={translate.t("delete")} title={translate.t("delete")}
                                                message={translate.t("confirm_delete")} description={version.name}
                                            />
                                            <DialogComponent
                                                open={openChangeFinalVersionDialog}
                                                onClose={handleCloseChangeFinalVersionDialog}
                                                labelButton={translate.t("yes")} colorButton="success"
                                                title={translate.t("change_final_version")}
                                                message={translate.t("confirm_make_version_final").replace("$description", version.name)}
                                            />
                                        </Grid>
                                        <Grid item xs lg={2}>
                                            <Field
                                                as={TextField}
                                                id="id_initial_date"
                                                name="initial_date"
                                                label={translate.t("initial_date")}
                                                values={values}
                                                value={start_date}
                                                autoFocus={false}
                                                type="date"
                                                fullWidth
                                                onChange={handleStartDateChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={touched.initial_date && Boolean(errors.initial_date)}
                                            />
                                        </Grid>
                                        <Grid item xs lg={2}>
                                            <Field
                                                as={TextField}
                                                id="id_end_date"
                                                name="end_date"
                                                label={translate.t("final_date")}
                                                values={values}
                                                value={end_date}
                                                autoFocus={false}
                                                type="date"
                                                fullWidth
                                                onChange={handleEndDateChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={touched.end_date && Boolean(errors.end_date)}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Fab
                                                size="small"
                                                color="secondary"
                                                disabled={!isValid || isValidating}
                                                onClick={reloadPlanningBoard}>
                                                <Search/>
                                            </Fab>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={handleOpenCreateDemandDialog}>
                                                <Assignment/>
                                                {translate.t("create_demand")}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={handleOpenDialogSimulation}>
                                                <Add/>
                                                {translate.t("new_simulation")}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={removeSimulation}>
                                                <Delete/>
                                                {translate.t("delete_simulation")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>

                    <Box className="box-header" id="boxHeader" onScroll={handleScroll}>
                        {monthKeys.map((month, index) => {
                            return (
                                <Box width="auto" key={index}>
                                    <Box display="flex" justifyContent="start" alignItems="center" className="month">
                                        {month.month != "0" &&
                                            (<Typography variant="body2">
                                                {translate.t(getStringMonth(month.month))}
                                            </Typography>)
                                        }
                                    </Box>
                                    <Box width="auto" display="flex" flexDirection="row" className="header-planning">
                                        {month.month === "0" ?
                                            (<>
                                                <Box className="phantom-box"></Box>
                                                <Box className="phantom-box"></Box>
                                            </>)
                                            :
                                            (<Box display="flex" flexDirection="row">
                                                {Object.keys(planning?.header).map((monthKey) => {
                                                    const monthData = planning?.header[monthKey];
                                                    return (
                                                        Object.keys(monthData).map((itemKey, index) => {
                                                            const item = monthData[itemKey];
                                                            return (
                                                                parseInt(month.month) == item.month &&
                                                                (<Box key={index} display="flex" flexDirection="column" className="header-day">
                                                                    <Box
                                                                        display="flex" flex="1"
                                                                        justifyContent="center" alignItems="center"
                                                                        overflow="hidden" className="week-day">
                                                                        <Typography variant="body2">{translate.t(item.week_day)}</Typography>
                                                                    </Box>
                                                                    <Box
                                                                        display="flex" flex="1"
                                                                        justifyContent="center" alignItems="center"
                                                                        overflow="hidden"
                                                                        className="header-number-day">
                                                                        <Typography variant="body2">{item.day}</Typography>
                                                                    </Box>
                                                                </Box>)
                                                            );
                                                        })
                                                    );
                                                })}
                                            </Box>)
                                        }
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                    {planning?.body?.lines && (
                        <Box onScroll={handleScroll} className="body-planning">
                            {planning.body.lines.map((line: any, index: number) => {
                                return (
                                    <Box key={index} width="fit-content" display="flex" position="relative" flexDirection="row">
                                        {line.type === "S" ?
                                            (<Box className="sticky-bg">
                                                <Box
                                                    className="smt-line-name"
                                                    display="flex"
                                                    flexDirection="column"
                                                    justifyContent="center"
                                                    alignItems="center">
                                                    <DisplaySettings></DisplaySettings>
                                                    <Typography variant="body2">{line.name}</Typography>
                                                </Box>
                                            </Box>)
                                            :
                                            (<Box className="sticky-bg">
                                                <Box
                                                    className="manual-line-name"
                                                    display="flex"
                                                    flexDirection="column"
                                                    justifyContent="center"
                                                    alignItems="center">
                                                    <AccountCircle></AccountCircle>
                                                    <Typography variant="body2">{line.name}</Typography>
                                                </Box>
                                            </Box>)
                                        }
                                        <Box position="relative" display="flex" flexDirection="column" className="shifts">
                                            {line.shifts.map((shift: any, index: number) => {
                                                return (
                                                    <Box key={index} position="relative" display="flex" flexDirection="row">
                                                        {line.type === "S" ?
                                                            (<Box className="sticky-bg" left="100px">
                                                                <Box
                                                                    className="smt-shift-name" display="flex"
                                                                    justifyContent="center" alignItems="center">
                                                                    <Typography variant="body2">{shift.name}</Typography>
                                                                </Box>
                                                            </Box>)
                                                            :
                                                            (<Box className="sticky-bg" left="100px">
                                                                <Box
                                                                    className="manual-shift-name" display="flex"
                                                                    justifyContent="center" alignItems="center">
                                                                    <Typography variant="body2">{shift.name}</Typography>
                                                                </Box>
                                                            </Box>)
                                                        }
                                                        {/*Dados dos dias*/}
                                                        {shift.days.map((day: any, index: number) => {
                                                            return (
                                                                <Box
                                                                    id={`${line.id}${shift.id}${day}`}
                                                                    key={index} width="auto"
                                                                    position="relative" display="flex"
                                                                    flexDirection="row">
                                                                    <Box
                                                                        display="flex" flex="1"
                                                                        flexDirection="column">
                                                                        <Box
                                                                            display="flex" height="100%"
                                                                            position="relative"
                                                                            flexDirection="column"
                                                                            justifyContent="center">
                                                                            {!day.is_work_day && !day?.event_description &&
                                                                                <Box
                                                                                    className="weekend"
                                                                                    display="flex"
                                                                                    alignItems="center"
                                                                                    justifyContent="center">
                                                                                </Box>
                                                                            }
                                                                            {(day.daily_plannings.length == 0 && day.availability && day.is_work_day) &&
                                                                                <Box
                                                                                    className="day"
                                                                                    display="flex"
                                                                                    alignItems="center"
                                                                                    justifyContent="center">
                                                                                    <PersonAdd className="person-add"></PersonAdd>
                                                                                </Box>
                                                                            }
                                                                            {!day?.is_work_day && day?.event_description &&
                                                                                <Box
                                                                                    className="holiday"
                                                                                    display="flex"
                                                                                    alignItems="center"
                                                                                    justifyContent="center"
                                                                                    data-tooltip-content={day?.event_description}
                                                                                    data-tooltip-id="tooltip"
                                                                                >
                                                                                    <Tooltip id="tooltip"/>
                                                                                    <Box className="ic-holiday"></Box>
                                                                                </Box>
                                                                            }
                                                                            {day?.daily_plannings.map((daily_planning: any, index: number) => {
                                                                                return (
                                                                                    <div
                                                                                        className="used-day"
                                                                                        key={index}>
                                                                                        <Box
                                                                                            key={index}
                                                                                            className="used-day"
                                                                                            display="flex"
                                                                                            flexDirection="row"
                                                                                            alignItems="center"
                                                                                            justifyContent="center"
                                                                                            data-tooltip-content={tooltipDailyPlanning(daily_planning)}
                                                                                            data-tooltip-id="daily_planning_tooltip"
                                                                                            sx={{
                                                                                                backgroundColor: daily_planning?.color,
                                                                                                height: (day.availability && day?.is_work_day && day.daily_plannings.length == 1) ? "50%" : "100%"
                                                                                            }}
                                                                                            onClick={(event) => handleDailyMenuOpen(event, daily_planning, shift, line)}
                                                                                        >
                                                                                            <Tooltip id="daily_planning_tooltip" className="tooltip"/>
                                                                                            {daily_planning?.markers && daily_planning?.markers.length > 0 &&
                                                                                                <Box
                                                                                                    onClick={(event) => handleOpenDialogMarker(event, daily_planning)}
                                                                                                    className="marker"
                                                                                                    data-tooltip-content=""
                                                                                                    data-tooltip-id="empty_tooltip"
                                                                                                >
                                                                                                    <Tooltip id="empty_tooltip"/>
                                                                                                </Box>
                                                                                            }
                                                                                            <Typography
                                                                                                sx={{color: daily_planning.font_color}}>
                                                                                                {
                                                                                                    daily_planning.is_npi == true ? "NPI" + " / " +
                                                                                                        daily_planning?.total_realized : daily_planning?.total_goal + " / " +
                                                                                                        daily_planning?.total_realized
                                                                                                }
                                                                                            </Typography>
                                                                                        </Box>
                                                                                        {day.availability && day?.is_work_day && day.daily_plannings.length == 1 &&
                                                                                            <Box
                                                                                                sx={{height: "50%"}}
                                                                                                className="day"
                                                                                                display="flex"
                                                                                                alignItems="center"
                                                                                                justifyContent="center">
                                                                                                <PersonAdd className="person-add"></PersonAdd>
                                                                                            </Box>
                                                                                        }
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </Box>
                                                                    </Box>
                                                                    {/*{provided.placeholder}*/}
                                                                </Box>
                                                            );
                                                        })}
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    )}
                </Paper>

            </Box>

            {/*MENU VERSAO*/}
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleVersionMenuClose}>
                <MenuItem onClick={handleOpenVersionDialog}>
                    <ListItemIcon>
                        <Add/>
                    </ListItemIcon>
                    <ListItemText>
                        {translate.t("new_version")}
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={handleOpenDeleteDialog}>
                    <ListItemIcon>
                        <Delete/>
                    </ListItemIcon>
                    <ListItemText>
                        {translate.t("delete_version")}
                    </ListItemText>
                </MenuItem>
            </Menu>

            {/*MENU DAIlYPLANNING*/}
            <Menu id="menu_daily" anchorEl={dailyAnchorEl} open={Boolean(dailyAnchorEl)} onClose={handleDailyMenuClose}>
                <MenuList>
                    {selectedDailyPlanning && new Date(selectedDailyPlanning?.daily_planning_date) > new Date() && !version.final_version && (
                        <MenuItem onClick={() => handleOpenEditPlanningDialog()}>
                            <ListItemIcon>
                                <Edit/>
                            </ListItemIcon>
                            <ListItemText>
                                {translate.t("edit_planning")}
                            </ListItemText>
                        </MenuItem>
                    )}
                    <MenuItem onClick={handleOpenPeripheralDialog}>
                        <ListItemIcon>
                            <Mouse/>
                        </ListItemIcon>
                        <ListItemText>
                            {translate.t("peripheral")}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleOpenDeletePlanningDialog}>
                        <ListItemIcon>
                            <Delete/>
                        </ListItemIcon>
                        <ListItemText>
                            {translate.t("delete")}
                        </ListItemText>
                    </MenuItem>
                    {selectedLine?.type == "M" &&
                        <MenuItem onClick={handleOpenChangeFinalVersionDialog}>
                            <ListItemIcon>
                                <Edit/>
                            </ListItemIcon>
                            <ListItemText>
                                {translate.t("edit")}
                            </ListItemText>
                        </MenuItem>
                    }
                    <MenuItem onClick={handleInterruptDemandDialog}>
                        <ListItemIcon>
                            <GridOff/>
                        </ListItemIcon>
                        <ListItemText>
                            {translate.t("interrupt_demand")}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onMouseOver={(event) => {
                        handleDailyPlanningMarkers();
                        handleMarkerMenuClick(event);
                    }}>
                        <ListItemIcon>
                            <Error/>
                        </ListItemIcon>
                        <ListItemText>
                            <Box display="flex" justifyContent="space-between" sx={{padding: 0}}>
                                <Typography>
                                    {translate.t("markers")}
                                </Typography>
                                <ArrowRight/>
                            </Box>
                        </ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>

            {/*MENU MARCADORES*/}
            <Popover
                anchorEl={markerAnchorEl}
                open={Boolean(markerAnchorEl)}
                onClose={handleMarkerMenuClose}
                anchorOrigin={{vertical: "top", horizontal: "left"}}
                transformOrigin={{vertical: "top", horizontal: "left"}}>
                <MenuList sx={{width: "400px"}}>
                    {dailyPlanningMarkers.map((marker: any, index: number) => {
                        return (
                            <MenuItem key={index} onClick={(event) => addMarker(event, marker)}>
                                <ListItemIcon>
                                    <Checkbox edge="start" checked={marker.checked} tabIndex={-1} disableRipple/>
                                </ListItemIcon>
                                <ListItemText>
                                    <Box display="flex" flexDirection="row">
                                        <Typography
                                            className="marker-color"
                                            sx={{backgroundColor: marker.color}}>
                                        </Typography>
                                        <Typography sx={{marginLeft: "5px"}}>{marker.description}</Typography>
                                    </Box>
                                </ListItemText>
                            </MenuItem>
                        );
                    })}
                    <Divider/>
                    <MenuItem onClick={() => navigate("/registration/marker/create")}>
                        <ListItemIcon>
                            <Add/>
                        </ListItemIcon>
                        <ListItemText>
                            {translate.t("new_marker")}
                        </ListItemText>
                    </MenuItem>
                </MenuList>
            </Popover>

            <DailyProductionPeripheralDialog
                open={openPeripheralDialog} onClose={handleClosePeripheralDialog}
                data={data}/>
            <DialogComponent
                open={openDeletePlanningDialog} onClose={handleCloseDeletePlanningDialog}
                labelButton={translate.t("delete")}
                title={translate.t("delete")} message={translate.t("remove_planning_message")}/>
            {openDialogMarkers &&
                <DialogMarkers
                    open={openDialogMarkers} onClose={() => setOpenDialogMarkers(false)}
                    dailyPlanning={selectedDailyPlanning}>
                </DialogMarkers>}
            {openEditPlanningDialog && selectedDailyPlanning && (
                <DialogPlanningDemandComponent
                    style="width: 1200px!important; max-width: 1200px!important;"
                    dailyPlanning={selectedDailyPlanning}
                    open={openEditPlanningDialog}
                    onClose={handleDialogClose}>
                </DialogPlanningDemandComponent>)}
            {openInterruptDemandDialog && (
                <DialogInterruptDemandComponent
                    style="width: 1200px!important; max-width: 1200px!important;"
                    dailyPlanning={selectedDailyPlanning}
                    open={openInterruptDemandDialog}
                    onClose={handleDialogClose}
                    version={version}>
                </DialogInterruptDemandComponent>)}
            {openSimulationDialog && (
                <DialogSimulationComponent
                    open={openSimulationDialog}
                    onClose={(value: boolean) => handleSimulationClose(value)}
                    version={version}>
                </DialogSimulationComponent>)}
            {openCreateDemandDialog && (
                <DialogCreateDemandComponent
                    open={openCreateDemandDialog}
                    onClose={handleCreateDemandClose}
                    version={version}>
                </DialogCreateDemandComponent>)}
        </Box>
    );
};

export default SimulationComponent;
