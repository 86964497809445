import BaseModel from "../default/base-model";

export class Shift extends BaseModel {
    name: string;
    start_time: string;
    end_time: string;
    liquid_minutes: number | undefined;
    order: string;
    checked: boolean;

    constructor() {
        super();
        this.name = "";
        this.start_time = "";
        this.end_time = "";
        this.liquid_minutes = 0;
        this.order = "";
        this.checked = false;
    }
}
