import BaseModel from "../default/base-model";

export const PRODUCTION_RAMP_SHIFT_TYPE_CHOICES = {
    QUANTITY: "Q",
    PERCENTAGE: "P"
};

export class ProductionRampShift extends BaseModel {
    production_ramp: string;
    shift: any;
    type: string;
    active: boolean;
    value_day_one: number;
    value_day_two: number;
    value_day_three: number;
    value_day_four: number;
    value_day_five: number;
    value_day_six: number;
    value_day_seven: number;

    [key: string]: any;

    constructor() {
        super();
        this.production_ramp = "";
        this.shift = "";
        this.type = "P";
        this.active = true;
        this.value_day_one = 100;
        this.value_day_two = 100;
        this.value_day_three = 100;
        this.value_day_four = 100;
        this.value_day_five = 100;
        this.value_day_six = 100;
        this.value_day_seven = 100;
    }
}
