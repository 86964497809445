import {Dialog, DialogContent, Grid, Link, Typography} from "@mui/material";
import * as Icons from "@mui/icons-material";
import {Close} from "@mui/icons-material";
import React, {useEffect} from "react";
import {translate} from "../../translate/translate";
import bgMenuModules from "../../../assets/images/bg-menu-modules.svg";
import {useNavigate} from "react-router-dom";

interface SelectedOption {
    module: {
        label: string;
        icon: string;
    };
}

const ModuleDialogComponent = (props: any) => {
    const {
        openProceed,
        handleCloseDialogProceed,
        modules,
        handleSelectedModule,
        handleClearModuleMenu,
        searchMenus
    } = props;
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] =
        React.useState<SelectedOption>({module: {label: "", icon: ""}});

    const selectModule = (module: any) => {
        setSelectedOption({module});
        handleSelectedModule(selectedOption);
        handleCloseDialogProceed();
        handleClearModuleMenu();
        navigate("/home");
        searchMenus();
    };

    useEffect(() => {
        if (selectedOption?.module?.label !== "") {
            handleSelectedModule(selectedOption);
        }
    }, [selectedOption, handleSelectedModule]);

    return (
        <Dialog
            fullScreen
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                },
            }}
            BackdropProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                },
            }}
            sx={{
                backgroundImage: `url(${bgMenuModules})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
            }}
            open={openProceed} onClose={handleCloseDialogProceed}>
            <Grid p={2} container justifyContent="flex-end"
            >
                <Grid item sx={{cursor: "pointer", color: "#FFFFFF"}}>
                    <Close fontSize="large" onClick={() => {
                        handleCloseDialogProceed();
                    }}/>
                </Grid>
            </Grid>
            <DialogContent sx={{backgroundColor: "transparent", boxShadow: "none"}}>
                <Grid p={8} container justifyContent="center">
                    <Typography variant={"h4"} sx={{color: "#FFFFFF"}}>
                        {translate.t("module_options")}
                    </Typography>
                </Grid>
                <Grid container spacing={2} justifyContent="center">
                    {modules.map((module: any) => (
                        <Grid item xs={12} sm={4} md={3} lg={2} xl={2} key={module.label}>
                            <Link
                                className="module-name"
                                component="button"
                                variant="body2"
                                onClick={() => selectModule(module)}
                                key={module.value}
                                value={module.value}
                            >
                                {React.createElement((Icons as any)[module.icon])}
                                {translate.t(module.label)}
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
        </Dialog>
    );

};

export default ModuleDialogComponent;
