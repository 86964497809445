import * as React from "react";
import {useEffect, useState} from "react";
import {translate} from "../../../translate/translate";
import {Box, Fab, Grid, Paper, TextField, Typography} from "@mui/material";
import {BaseService} from "../../../services/base-service";
import {Search, UploadFile} from "@mui/icons-material";
import {URLS} from "../../../services/app-urls";
import {Field, Form, Formik} from "formik";
import {ErrorComponent} from "../../../components/error/error.component";
import {Delivery} from "../../../models/planning/delivery";
import AutocompletePaginated from "../../../components/autocomplete/autocompletePaginated";
import {Product} from "../../../models/basic/product";
import TablePaginationComponent from "../../../components/table/table-pagination.component";
import TableActionComponent from "../../../components/table/table-action.component";
import {PaginatedResult} from "../../../models/default/paginated-result";
import Loading from "../../../components/loading/loading";
import {toast} from "react-toastify";

const DeliveryComponent = () => {
    const service = new BaseService<Delivery>(URLS.DELIVERY);
    const productService = new BaseService<Product>(URLS.PRODUCT);
    const [selectedFiles, setSelectedFiles] = React.useState<any>();
    const [searchProduct, setSearchProduct] = useState<Product>();
    const [searchStart, setSearchStart] = useState("");
    const [searchEnd, setSearchEnd] = useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [pagination, setPagination] = React.useState<PaginatedResult<Delivery>>();
    const [dataSource, setDataSource] = React.useState<Delivery[] | []>([]);
    const [loading, setLoading] = React.useState(false);
    const fileInputRef = React.useRef<any>(null);

    useEffect(() => {
        search();
    }, [page, rowsPerPage]);

    useEffect(() => {
        setDataSource(pagination?.results ?? []);
    }, [pagination]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleStartDateChange = (event: any) => {
        setSearchStart(event.target.value);
    };

    const handleEndDateChange = (event: any) => {
        setSearchEnd(event.target.value);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const search = () => {
        service.clearParameters();

        if (searchProduct) service.addParameter("product", searchProduct.id);
        if (searchStart) service.addParameter("start", searchStart);
        if (searchEnd) service.addParameter("end", searchEnd);

        service.addParameter("expand", "product");
        service.addParameter("limit", rowsPerPage);
        service.addParameter("offset", (page * rowsPerPage));
        service.getAllPaginate()
            .then(response => {
                setPagination(response.data);
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
    };

    return (
        <section>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("delivery")}
                </Typography>
            </div>
            <Loading open={loading}/>
            <Grid item xs={12} sx={{paddingBottom: "16px"}}>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize={true}
                            validateOnMount={true}
                            initialValues={{}}
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    setLoading(true);
                                    service.uploadFile("delivery_import", selectedFiles)
                                        .then(() => {
                                            toast.success(translate.t("successfully_uploaded"));
                                            fileInputRef.current.value = null;
                                            setSelectedFiles(null);
                                            search();
                                        })
                                        .catch((error: any) => {
                                            ErrorComponent(error);
                                        })
                                        .finally(() => setLoading(false));
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {
                                () => (
                                    <Form autoComplete="off">
                                        <Grid alignItems="center" container spacing={2} direction={"row"}>
                                            <Grid item xs>
                                                <Field
                                                    as={TextField}
                                                    className={"input-file"}
                                                    name="file"
                                                    label={""}
                                                    type={"file"}
                                                    variant={"outlined"}
                                                    fullWidth
                                                    autoFocus={true}
                                                    inputProps={{
                                                        accept: ".xls,.xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                                    }}
                                                    inputRef={fileInputRef}
                                                    onChange={(event: any) => {
                                                        const files = Array.from(event.target.files);
                                                        setSelectedFiles(files);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Fab
                                                    size="small"
                                                    type="submit"
                                                    color="secondary"
                                                    disabled={!selectedFiles}>
                                                    <UploadFile/>
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>

            <Grid item xs={12} sx={{paddingBottom: "16px"}}>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize={true}
                            validateOnMount={true}
                            initialValues={{}}
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    search();
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {
                                () => (
                                    <Form autoComplete="off">
                                        <Grid alignItems="center" container spacing={2} direction={"row"}>
                                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                                <AutocompletePaginated
                                                    display="name"
                                                    label="product"
                                                    service={productService}
                                                    onSelectElement={(product: Product) => {
                                                        setSearchProduct(product);
                                                    }}
                                                    autoFocus={true}
                                                />
                                            </Grid>
                                            <Grid item xs lg={2}>
                                                <Field
                                                    as={TextField}
                                                    id="id_initial_date"
                                                    name="initial_date"
                                                    label={translate.t("initial_date")}
                                                    autoFocus={false}
                                                    inputProps={{max: searchEnd}}
                                                    type="date"
                                                    fullWidth
                                                    onChange={handleStartDateChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs lg={2}>
                                                <Field
                                                    as={TextField}
                                                    id="id_end_date"
                                                    name="end_date"
                                                    label={translate.t("final_date")}
                                                    autoFocus={false}
                                                    inputProps={{min: searchStart}}
                                                    type="date"
                                                    fullWidth
                                                    onChange={handleEndDateChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Fab
                                                    size="small"
                                                    type="submit"
                                                    color="secondary">
                                                    <Search/>
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>

            <Paper variant="outlined" sx={{marginTop: "16px"}}>
                <TablePaginationComponent
                    state={dataSource}
                    displayedColumnsShow={["#", "product", "delivery_date", "quantity"]}
                    displayedColumns={["id", "product.model_and_name", "delivery_date", "quantity"]}
                    dateColumns={["delivery_date"]}
                    count={pagination?.count}
                    next={pagination?.next}
                    previous={pagination?.previous}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    actionsComponent={TableActionComponent}
                    columnStyles={{1: "5%", 2: "20%", 3: "10%", 4: "10%"}}
                />
            </Paper>
        </section>
    );
};

export default DeliveryComponent;
