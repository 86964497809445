import React, {useEffect} from "react";
import {Box, Paper, Switch, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {translate} from "../../../../translate/translate";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import {toast} from "react-toastify";
import TablePagination from "@mui/material/TablePagination";
import {Group} from "../../../../models/account/group";
import Divider from "@mui/material/Divider";
import {ErrorComponent} from "../../../../components/error/error.component";

interface ContainerProps {
    userId: any;
}

const UserGroupComponent = ({userId}: ContainerProps) => {
    const service = new BaseService<Group>(URLS.GROUP);
    const displayedColumns = ["name"];
    const [dataSource, setDataSource] = React.useState<Group[] | []>([]);
    const [pagination, setPagination] = React.useState<any>();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);

    const search = () => {
        service.clearParameters();
        service.addParameter("user", userId);
        service.addParameter("limit", rowsPerPage);
        service.addParameter("offset", (page * rowsPerPage));
        service.getAllFromListRoute("with_granted").then(response => {
            setPagination(response.data);
        });
    };

    useEffect(() => {
        setDataSource(pagination?.results ?? []);
    }, [pagination]);

    useEffect(() => {
        if (userId) {
            search();
        }
    }, [userId, page, rowsPerPage]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSwitch = (id: number, granted: boolean) => {
        service.clearParameters();
        service.addParameter("source", id);
        service.addParameter("target", userId);
        service.addParameter("associated", !granted);
        service.saveFromRoute({
            source: id,
            associated: !granted,
            target: userId,
            is_active: true,
            is_default: false
        }, "associate_user").then(() => {
            search();
            toast.success(translate.t("successfully_updated"));
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    return (
        <Paper variant="outlined">
            <Box p={2}>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow sx={{flexDirection: "row-reverse"}}>
                            {displayedColumns.map((column: string) => (
                                <TableCell key={column} sx={{paddingTop: "12px", paddingBottom: "12px"}}>
                                    {translate.t(column)}
                                </TableCell>
                            ))}
                            <TableCell sx={{justifyContent: "end"}}>
                                <div>Status</div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataSource.length > 0 ? (
                            dataSource.map((permission: any) => (
                                <TableRow
                                    key={permission.id}
                                    sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                    {displayedColumns.map((column: string) => (
                                        <TableCell key={column}>{permission[column]}</TableCell>
                                    ))}
                                    <TableCell>
                                        <Switch
                                            color="secondary"
                                            checked={permission.granted}
                                            onChange={() => handleSwitch(permission.id, permission.granted)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={displayedColumns.length + 1} sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    padding: "8px 0"
                                }}>
                                    {translate.t("empty_listing")}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <Divider/>
                <TablePagination
                    sx={{textAlign: "center"}}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={pagination?.count || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={translate.t("rows_per_page")}
                    labelDisplayedRows={({from, to, count}) => `${from} - ${to} ${translate.t("of")} ${count}`}/>
            </Box>
        </Paper>
    );
};

export {UserGroupComponent};
