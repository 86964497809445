import {Button, Dialog, DialogContent, Grid, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import {translate} from "../../../../translate/translate";
import {Add, Check, Close} from "@mui/icons-material";
import {Field, Form, Formik} from "formik";
import * as React from "react";
import {useState} from "react";
import {ProductionOrderPeripheral} from "../../../../models/basic/production-order-peripheral";
import {toast} from "react-toastify";
import * as Yup from "yup";
import {ErrorComponent} from "../../../../components/error/error.component";

const DialogProductionOrderPeripheralComponent = (props: any) => {
    const {
        openDialog,
        setOpenDialog,
        typeOperation,
        productionOrderPeripheral,
        demand,
        service,
        search
    } = props;

    const [buttonValue, setButtonValue] = useState("");

    const schema = Yup.object().shape({
        production_order: Yup.string().required(translate.t("required_field")),
    });

    return (
        <Dialog
            open={openDialog}
            onClose={() => {
                setOpenDialog(false);
            }}
        >
            <Grid
                padding={2}
                container
                justifyContent="space-between"
                sx={{backgroundColor: "primary.main", minWidth: 480}}
            >
                <Grid item >
                    <Typography sx={{color: "#ffffff", fontWeight: "bold", fontSize: "16px"}}>
                        {translate.t(typeOperation)} - {translate.t("production_order")}
                    </Typography>
                </Grid>
                <Grid item sx={{cursor: "pointer", color: "#ffffff"}}>
                    <Close onClick={() => {
                        setOpenDialog(false);
                    }}/>
                </Grid>
            </Grid>
            <DialogContent>
                <Formik
                    initialValues={productionOrderPeripheral}
                    onSubmit={(values:ProductionOrderPeripheral, {setSubmitting}) => {
                        setTimeout(() => {
                            if (values.id){
                                service.patch(values, values.id ?? 0).then(() => {
                                    toast.success(translate.t("successfully_updated"));
                                    search();
                                    if (buttonValue === "save_form") {
                                        setOpenDialog(false);
                                    } else {
                                        setOpenDialog(true);
                                    }
                                }).catch((error: any) => {
                                    ErrorComponent(error);
                                });
                            } else {
                                values.demand = demand.url;
                                service.save(values).then(() => {
                                    toast.success(translate.t("successfully_registered"));
                                    search();
                                    if (buttonValue === "save_form") {
                                        setOpenDialog(false);
                                    } else {
                                        setOpenDialog(true);
                                    }
                                }).catch((error: any) => {
                                    ErrorComponent(error);
                                });
                            }
                            setSubmitting(false);
                        }, 400);
                    }}
                    validationSchema={schema}
                    validateOnChange={true}
                >
                    {({isValid, values, isValidating}) => (
                        <Form autoComplete="off">
                            <Grid container spacing={2} direction={"column"}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Field
                                        as={TextField}
                                        name="production_order"
                                        label={translate.t("production_order")}
                                        fullWidth
                                        variant={"outlined"}
                                        autoFocus={true}
                                        values={values}
                                    />
                                </Grid>
                                <Grid container direction={"row"} spacing={2} item justifyContent={"flex-end"}>
                                    <Grid item>
                                        <Button
                                            startIcon={<Check/>}
                                            color="success"
                                            variant="contained"
                                            value="save_form"
                                            name="save_form"
                                            type="submit"
                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                setButtonValue(event.currentTarget.value);
                                            }}
                                            disabled={!isValid || isValidating}
                                        >
                                            {translate.t("save_form")}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            startIcon={<Add/>}
                                            color="secondary"
                                            variant="contained"
                                            value="save"
                                            name="save"
                                            type="submit"
                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                setButtonValue(event.currentTarget.value);
                                            }}
                                            disabled={!isValid || isValidating}
                                        >
                                            {translate.t("save")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default DialogProductionOrderPeripheralComponent;
