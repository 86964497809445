import BaseModel from "../default/base-model";

export class Group extends BaseModel {
    name: string;
    is_active: boolean;
    isDefault: boolean;

    constructor() {
        super();
        this.name = "";
        this.is_active = true;
        this.isDefault = true;
    }
}
