import {Button, Dialog, DialogContent, Grid, TextField, Typography} from "@mui/material";
import {translate} from "../../translate/translate";
import {Check, Close} from "@mui/icons-material";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {User} from "../../models/account/user";
import {toast} from "react-toastify";
import React from "react";
import {BaseService} from "../../services/base-service";
import {URLS} from "../../services/app-urls";
import {ErrorComponent} from "../error/error.component";


const ChangePasswordComponent = (props: any) => {
    const [confirmPassword, setConfirmPassword] = React.useState<string>("");
    const userService = new BaseService<User>(URLS.USER);

    return (
        <Dialog fullWidth maxWidth={"xs"} open={props.openProceed} onClose={props.handleCloseDialogProceed}>
            <Grid p={2} container justifyContent="space-between" sx={{backgroundColor: "primary.main"}}>
                <Grid item>
                    <Typography sx={{fontWeight: "bold", fontSize: "16px", color: "#ffffff"}}>
                        {translate.t("recoverPassword")}
                    </Typography>
                </Grid>
                <Grid item sx={{cursor: "pointer", color: "#ffffff"}}>
                    <Close onClick={() => {
                        props.handleCloseDialogProceed();
                    }}/>
                </Grid>
            </Grid>
            <DialogContent>
                <Grid container flexDirection="column" gap={3}>
                    <Grid item>
                        <Formik
                            enableReinitialize
                            initialValues={new User()}
                            onSubmit={(values) => {
                                setTimeout(() => {
                                    if (values.password == confirmPassword) {
                                        const user = new User();
                                        Object.assign(user, values);
                                        const objectId = props.object.id ?? "";
                                        userService.changePassword(
                                            props.object, objectId, confirmPassword, "change_password")
                                            .then(() => {
                                                toast.success(translate.t("successfully_updated"));
                                            }).catch((error: any) => {
                                                ErrorComponent(error);
                                            });
                                        props.handleCloseDialogProceed();
                                    } else {
                                        toast.error(translate.t("password_not_match"));
                                    }
                                }, 500);
                            }}>
                            {
                                () => (
                                    <Form autoComplete="off">
                                        <Grid container p={"3"} spacing={2} direction={"column"}>
                                            <Grid item xs>
                                                <Field
                                                    as={TextField}
                                                    name="password"
                                                    type="password"
                                                    label={translate.t("new_password")}
                                                    helperText={<ErrorMessage name="password"/>}
                                                    variant={"outlined"}
                                                    fullWidth
                                                    autoFocus={false}/>
                                            </Grid>
                                            <Grid item xs>
                                                <Field
                                                    as={TextField}
                                                    value={confirmPassword}
                                                    type="password"
                                                    onChange={(e: any) => {
                                                        setConfirmPassword(e.target.value);
                                                    }}
                                                    label={translate.t("confirm_new_password")}
                                                    helperText={<ErrorMessage name="confirmPassword"/>}
                                                    variant={"outlined"}
                                                    fullWidth
                                                    autoFocus={false}/>
                                            </Grid>
                                            <Grid
                                                direction={"row"} container item xs={12} spacing={1}
                                                sx={{justifyContent: "flex-end"}}>
                                                <Grid item>
                                                    <Button
                                                        color="secondary"
                                                        variant="outlined"
                                                        onClick={() => {
                                                            props.handleCloseDialogProceed();
                                                        }}>
                                                        {translate.t("cancel")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        startIcon={<Check/>}
                                                        color="success"
                                                        variant="contained"
                                                        type="submit">
                                                        {translate.t("recoverPassword")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                        </Formik>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );

};

export default ChangePasswordComponent;

