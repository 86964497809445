import React, {useEffect} from "react";
import {Box, Button, Checkbox, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import {translate} from "../../../../translate/translate";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import {toast} from "react-toastify";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {Station} from "../../../../models/basic/station";
import {ProductionLineStation} from "../../../../models/basic/production-line-station";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SettingsIcon from "@mui/icons-material/Settings";

interface ContainerProps {
    productionLine: any;
    typeProcess: any;
    updateStations: () => void;
}

const ProductionLineStationComponent = ({productionLine, typeProcess, updateStations}: ContainerProps) => {
    const service = new BaseService<ProductionLineStation>(URLS.PRODUCTION_LINE_STATION);
    const stationService = new BaseService<Station>(URLS.STATION);
    const displayedColumnsStation = ["select", "type", "name"];
    const [station, setStation] = React.useState<Station[] | []>([]);
    const [dataSource, setDataSource] =
        React.useState<ProductionLineStation[] | []>([]);
    const [selectedAvailable, setSelectedAvailable] =
        React.useState<any[]>([]);
    const [selectedUsed, setSelectedUsed] = React.useState<any[]>([]);

    const search = () => {
        service.clearParameters();
        service.addParameter("production_line", productionLine.id);
        service.addParameter("expand", "station");
        service.getAll()
            .then(response => {
                setDataSource(response.data);
            });
    };

    useEffect(() => {
        if (productionLine.id) {
            getStation();
            search();
        }
    }, [updateStations]);

    const getStation = () => {
        if (typeProcess) {
            stationService.clearParameters();
            stationService.addParameter("not_associated", productionLine.id);
            stationService.addParameter("active", "true");
            stationService.addParameter("type", typeProcess);
            stationService.getAll()
                .then(response => {
                    setStation(response.data);
                });
        }
    };

    useEffect(() => {
        if (productionLine.id) {
            getStation();
            search();
        }
    }, [productionLine.id]);

    const updateOrder = (productionLineStation: any, id: any) => {
        service.clearParameters();
        service.patch(productionLineStation, id).then();
    };

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }
        const items = Array.from(dataSource);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setDataSource(items);
        items.forEach((currentItem, index) => {
            const productionLineStation: ProductionLineStation = currentItem;
            if (index === items.length - 1) {
                toast.success(translate.t("successfully_updated"));
            }
            updateOrder({order: index + 1}, productionLineStation.id);
        });
    };

    const handleSelectAvailable = (station: any) => {
        if (selectedAvailable.includes(station)) {
            setSelectedAvailable(selectedAvailable.filter((item) => item !== station));
        } else {
            setSelectedAvailable([...selectedAvailable, station]);
        }
    };

    const handleSelectUsed = (station: any) => {
        if (selectedUsed.includes(station)) {
            setSelectedUsed(selectedUsed.filter((item) => item !== station));
        } else {
            setSelectedUsed([...selectedUsed, station]);
        }
    };

    const handleCheckedRight = () => {
        service.clearParameters();
        if (selectedAvailable.length === 1) {
            const data: any = {};
            data["production_line"] = productionLine.url;
            data["production_line_id"] = productionLine.id;
            data["station"] = selectedAvailable[0].url;
            service.save(data).then(() => {
                toast.success(translate.t("successfully_updated"));
                search();
                getStation();
                setSelectedAvailable([]);
            });
        }
        if (selectedAvailable.length > 1) {
            let toastDisplayed = false;
            selectedAvailable.forEach((item: any, index: number) => {
                const data: any = {};
                data["production_line"] = productionLine.url;
                data["production_line_id"] = productionLine.id;
                data["station"] = item.url;
                service.save(data).then(() => {
                    if (index === selectedAvailable.length - 1 && !toastDisplayed) {
                        toastDisplayed = true;
                        toast.success(translate.t("successfully_updated"));
                    }
                    search();
                    getStation();
                    setSelectedAvailable([]);
                });
            });
        }
    };

    const handleAllRight = () => {
        service.clearParameters();
        const payload = {"production_line": productionLine.id, "type": typeProcess};
        service.postFromListRoute(payload, "associate_all").then(() => {
            search();
            getStation();
        });
        toast.success(translate.t("successfully_updated"));
    };

    const handleCheckedLeft = () => {
        service.clearParameters();
        if (selectedUsed.length === 1) {
            const payload = {
                "production_line": productionLine.id,
                "station": selectedUsed[0].id
            };
            service.postFromListRoute(payload, "delete_associated").then(() => {
                toast.success(translate.t("successfully_updated"));
                search();
                getStation();
                setSelectedUsed([]);
            });
        }
        if (selectedUsed.length > 1) {
            let toastDisplayed = false;
            selectedUsed.forEach((item: any, index: number) => {
                const payload = {
                    "production_line": productionLine.id,
                    "station": item.id
                };
                service.postFromListRoute(payload, "delete_associated").then(() => {
                    if (index === selectedUsed.length - 1 && !toastDisplayed) {
                        toastDisplayed = true;
                        toast.success(translate.t("successfully_updated"));
                    }
                    search();
                    getStation();
                    setSelectedUsed([]);
                });
            });
        }
    };

    const handleAllLeft = () => {
        service.clearParameters();
        const payload = {"production_line": productionLine.id};
        service.postFromListRoute(payload, "delete_all").then(() => {
            search();
            getStation();
            toast.success(translate.t("successfully_updated"));
        });
    };

    return (
        <Grid style={{display: "flex", flexDirection: "row"}}>
            <Box>
                <Paper variant="outlined">
                    <Box sx={{padding: "16px 16px 0 16px"}}>
                        <Typography variant="h6">{translate.t("available_production_line")}</Typography>
                    </Box>
                    <Box sx={{height: "calc(100vh - 422px)", padding: "16px"}}>
                        <Box sx={{overflow: "auto"}}>
                            <TableContainer elevation={0} variant="outlined" component={Paper}>
                                <Table className="station-list" size={"small"}>
                                    <TableBody sx={{width: "100%", alignItems: "flex-start"}}>
                                        {station.length > 0 ? (
                                            station.map((station: any) => (
                                                <TableRow
                                                    key={station.id}
                                                    sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                                    {displayedColumnsStation.map((column: string) => (
                                                        <TableCell key={column}>
                                                            {column === "select" ? (
                                                                <Checkbox
                                                                    checked={selectedAvailable.includes(station)}
                                                                    onChange={() => handleSelectAvailable(station)}/>
                                                            ) : (
                                                                column === "type" && station.type === "M" ? (
                                                                    <ManageAccountsIcon className="list-icons"/>
                                                                ) : column === "type" && station.type === "S" ? (
                                                                    <SettingsIcon className="list-icons"/>
                                                                ) : (
                                                                    station[column]
                                                                )
                                                            )}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))
                                        ) : (
                                            <Paper>
                                                <Box sx={{
                                                    textAlign: "center",
                                                    fontSize: "13px",
                                                    padding: "16px 0"
                                                }}>
                                                    <Typography>{translate.t("empty_listing")}</Typography>
                                                </Box>
                                            </Paper>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Paper>
            </Box>
            <Grid item>
                <Box sx={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Button
                        color="secondary"
                        sx={{my: 0.5}}
                        variant="outlined"
                        size="small"
                        onClick={handleAllRight}
                        aria-label="move all right"
                        disabled={station.length === 0}
                    >
                        <KeyboardDoubleArrowRightIcon/>
                    </Button>
                    <Button
                        color="secondary"
                        sx={{my: 0.5}}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        aria-label="move selected right"
                        disabled={selectedAvailable.length === 0}
                    >
                        <KeyboardArrowRightIcon/>
                    </Button>
                    <Button
                        color="secondary"
                        sx={{my: 0.5}}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        aria-label="move selected left"
                        disabled={selectedUsed.length === 0}
                    >
                        <KeyboardArrowLeftIcon/>
                    </Button>
                    <Button
                        color="secondary"
                        sx={{my: 0.5}}
                        variant="outlined"
                        size="small"
                        onClick={handleAllLeft}
                        aria-label="move all left"
                        disabled={dataSource.length === 0}
                    >
                        <KeyboardDoubleArrowLeftIcon/>
                    </Button>
                </Box>
            </Grid>
            <Box>
                <Paper variant="outlined">
                    <Box style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: "16px 16px 0 16px"
                    }}>
                        <Typography variant="h6">{translate.t("used_production_line")}</Typography>
                        <Typography variant="body2">{translate.t("info_ordination")}</Typography>
                    </Box>
                    <Box sx={{height: "calc(100vh - 422px)", padding: "16px"}}>
                        <Box sx={{overflow: "auto"}}>
                            <Grid item xs>
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    <Droppable droppableId="production-lines">
                                        {(provided) => (
                                            <TableContainer elevation={0} variant="outlined" component={Paper}>
                                                <Table
                                                    className="station-list"
                                                    size={"small"}
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}>
                                                    <TableBody sx={{width: "100%", alignItems: "flex-start"}}>
                                                        {dataSource.length > 0 ? (
                                                            dataSource.map((productionLineStation: any, index: number) => (
                                                                <Draggable
                                                                    key={productionLineStation.station.id}
                                                                    draggableId={
                                                                        productionLineStation?.station?.id?.toString()}
                                                                    index={index}>
                                                                    {(provided) => (
                                                                        <TableRow
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            sx={{
                                                                                "&:last-child td, &:last-child th":
                                                                                    {border: 0}
                                                                            }}>
                                                                            {displayedColumnsStation.map((
                                                                                column: string) => (
                                                                                <TableCell key={column}>
                                                                                    {column === "select" ? (
                                                                                        <Checkbox
                                                                                            checked={
                                                                                                selectedUsed.includes(
                                                                                                    productionLineStation.station)}
                                                                                            onChange={
                                                                                                () => handleSelectUsed(
                                                                                                    productionLineStation.station)}
                                                                                        />
                                                                                    ) : (
                                                                                        column === "type" && productionLineStation.station.type === "M" ? (
                                                                                            <ManageAccountsIcon className="list-icons"/>
                                                                                        ) : column === "type" && productionLineStation.station.type === "S" ? (
                                                                                            <SettingsIcon className="list-icons"/>
                                                                                        ) : (
                                                                                            productionLineStation.station[column]
                                                                                        )
                                                                                    )}
                                                                                </TableCell>

                                                                            ))}
                                                                        </TableRow>
                                                                    )}
                                                                </Draggable>
                                                            ))
                                                        ) : (
                                                            <Paper>
                                                                <Box sx={{
                                                                    textAlign: "center",
                                                                    fontSize: "13px",
                                                                    padding: "16px 0"
                                                                }}>
                                                                    <Typography>{translate.t("empty_listing")}</Typography>
                                                                </Box>
                                                            </Paper>
                                                        )}
                                                        {provided.placeholder}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Grid>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Grid>
    );
};

export {ProductionLineStationComponent};
