import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { messages } from "./languages";
import "i18next";

declare module "i18next" {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

const languageDetector = new LanguageDetector(null, {
    order: ["localStorage"],
    lookupLocalStorage: "lang",
});

i18n
    .use(languageDetector)
    .init({
        debug: false,
        returnNull: false,
        defaultNS: "translations",
        fallbackLng: "pt",
        ns: ["translations"],
        resources: messages
        // eslint-disable-next-line @typescript-eslint/no-empty-function
    }).then(() => {
    });

export { i18n as translate };
