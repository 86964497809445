import {Checkbox, Dialog, DialogContent, FormControlLabel, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {translate} from "../../../../translate/translate";
import {Close} from "@mui/icons-material";
import * as React from "react";
import {useEffect, useState} from "react";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import {Shift} from "../../../../models/basic/shift";
import {toast} from "react-toastify";
import {ProductionRampShift} from "../../../../models/basic/production-ramp-shift";

const DialogProductionRampShiftSelectionComponent = (props: any) => {
    const {
        openDialog,
        setOpenDialog,
        productionRamp,
        searchMain
    } = props;
    const service = new BaseService<Shift>(URLS.SHIFT);
    const serviceProductionRampShift = new BaseService<ProductionRampShift>(URLS.PRODUCTION_RAMP_SHIFT);
    const [shifts, setShifts] = useState<Shift[]>([]);

    useEffect(() => {
        search();
    }, [openDialog]);

    const search = () => {
        service.clearParameters();
        service.addParameter("production_ramp", productionRamp.id);
        service.getAllFromListRoute("get_all_shifts_production_ramp").then(response => {
            setShifts(response.data);
        });
    };

    const handleChange = (shift: Shift) => {
        serviceProductionRampShift.clearParameters();
        if (!shift.checked) {
            shift.checked = !shift.checked;
            const productionRampShift = new ProductionRampShift();
            productionRampShift.production_ramp = productionRamp.url;
            productionRampShift.shift = shift.url ? shift.url : "";
            serviceProductionRampShift.save(productionRampShift)
                .then(() => {
                    toast.success(translate.t("successfully_registered"));
                    search();
                    searchMain();
                });
        } else {
            const payload = {production_ramp: productionRamp.id, shift: shift.id};
            serviceProductionRampShift.postFromListRoute(payload, "remove_production_ramp_shift")
                .then(() => {
                    toast.success(translate.t("successfully_deleted"));
                    search();
                    searchMain();
                });
        }
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    return (
        <Dialog open={openDialog} onClose={handleDialogClose}>
            <Grid
                padding={2}
                container
                justifyContent="space-between"
                sx={{backgroundColor: "primary.main", minWidth: "auto"}}
            >
                <Grid item>
                    <Typography sx={{color: "#ffffff", fontWeight: "bold", fontSize: "16px"}}>
                        {translate.t("shift")}
                    </Typography>
                </Grid>
                <Grid item sx={{cursor: "pointer", color: "#ffffff"}}>
                    <Close onClick={() => {
                        setOpenDialog(false);
                    }}/>
                </Grid>
            </Grid>
            <DialogContent>
                <Grid container gap={2} direction={"column"}>
                    <Grid container spacing={1} direction={"column"}>
                        {shifts.map((shift: any) => (
                            <Grid item key={shift.name}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name={shift.name}
                                            checked={shift.checked}
                                            onChange={() => handleChange(shift)}
                                            color="secondary"
                                        />
                                    }
                                    label={shift.name + " - " + shift.start_time + " às " + shift.end_time}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default DialogProductionRampShiftSelectionComponent;
