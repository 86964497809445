import React, {useEffect, useState} from "react";
import {translate} from "../../../../translate/translate";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import {ProductionRampShift} from "../../../../models/basic/production-ramp-shift";
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, IconButton, TableCell, TextField, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DialogProductionRampShiftSelectionComponent from "./dialog-production-ramp-shift-selection.component";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import {ProductionRamp} from "../../../../models/basic/production-ramp";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import "./../production-ramp.css";
import {History} from "@mui/icons-material";
import {toast} from "react-toastify";
import HistoryComponent from "../../../../components/history/history.component";


const ProductionRampItemShift = ({productionRamp}: { productionRamp: ProductionRamp }) => {
    const service = new BaseService<ProductionRampShift>(URLS.PRODUCTION_RAMP_SHIFT);
    const [expanded, setExpanded] = useState<boolean>(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [openHistory, setOpenHistory] = React.useState(false);
    const [idHistory, setIdHistory] = React.useState(0);
    const [dataSource, setDataSource] = React.useState<ProductionRampShift[] | []>([]);
    const dayNames = ["one", "two", "three", "four", "five", "six", "seven"];


    useEffect(() => {
        search();
    }, [productionRamp]);

    const search = () => {
        service.clearParameters();
        service.addParameter("expand", "shift");
        service.addParameter("production_ramp", productionRamp.id);
        service.getAll().then(response => {
            setDataSource(response.data);
        });
    };

    const updateProductionRampShift = (productionRampShift: ProductionRampShift) => {
        productionRampShift.shift = productionRampShift.shift.url;
        service.clearParameters();
        service.patch(productionRampShift, productionRampShift.id)
            .then(() => {
                search();
                toast.success(translate.t("successfully_updated"));
            });
    };

    const handleChange = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    const addShift = () => {
        setOpenDialog(true);
    };

    const handleOnChange = (event: any) => {
        const enteredValue = event.target.value;
        const regex = /^[1-9]\d?$|100$/;
        if (!regex.test(enteredValue) && enteredValue !== "") {
            event.target.value = 100;
        }
    };

    const handleOnKeyDown = (event: any, productionRampShift: ProductionRampShift, fieldName: string) => {
        const value = event.target.value;
        if (productionRampShift[fieldName] != value && event.key === "Enter" || event.key === "Tab" && value > 0 && value <= 100 && value !== "") {
            productionRampShift[fieldName] = value;
            updateProductionRampShift(productionRampShift);
        }
    };

    const handleOnBlur = (event: any, productionRampShift: ProductionRampShift, fieldName: string) => {
        const value = event.target.value;
        if (productionRampShift[fieldName] != value && value > 0 && value <= 100 && value !== "") {
            productionRampShift[fieldName] = value;
            updateProductionRampShift(productionRampShift);
        }
    };

    const handleOpenDialogHistory = (id: number) => {
        setIdHistory(id);
        setOpenHistory(true);
    };

    const handleCloseDialogHistory = () => {
        setOpenHistory(false);
    };

    const getSizeColumn = () => {
        const result = 80 / productionRamp.days;
        return `${result.toFixed(2)}%`;
    };

    return (
        <Box sx={{marginTop: 2}}>
            <Accordion expanded={expanded} onChange={handleChange}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header" sx={{backgroundColor: "#e4e4e4", borderTopRightRadius: 5, borderTopLeftRadius: 5}}
                >
                    <Typography sx={{fontWeight: "bold"}}>{translate.t("production_ramp_settings")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container gap={2} direction="column">
                        <Grid pt={1} item container spacing={1} direction="row" justifyContent="flex-end" xs={12}>
                            <Grid item>
                                <Button sx={{marginTop: "7px"}} color="secondary" variant="contained" onClick={addShift}>
                                    {translate.t("associate_shift")}
                                </Button>
                            </Grid>
                        </Grid>
                        <Table size={"small"}>
                            <TableHead sx={{borderRadius: "15px"}}>
                                <TableRow className={"table-prs"}>
                                    <TableCell sx={{width: "15%"}} key={"shift"}>{translate.t("shift")}</TableCell>
                                    {Array.from({length: productionRamp.days}).map((_, index) => (
                                        <TableCell sx={{width: getSizeColumn()}} key={index + 1}>
                                            {translate.t("day") + " " + (index + 1)}
                                        </TableCell>
                                    ))}
                                    <TableCell sx={{width: "5%"}} key={"actions"}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataSource.length > 0 ? (dataSource.map((productionRampShift: any) => (
                                    <TableRow key={productionRampShift.id} className={"table-prs"}>
                                        <TableCell sx={{width: "15%"}}>{productionRampShift.shift.name}</TableCell>
                                        {dayNames.slice(0, productionRamp.days).map((dayName, index) => (
                                            <TableCell sx={{width: getSizeColumn()}} key={index}>
                                                <TextField
                                                    name={`value_day_${dayName}`}
                                                    required={true}
                                                    defaultValue={productionRampShift[`value_day_${dayName}`]}
                                                    InputProps={{endAdornment: "%"}}
                                                    onChange={(event: any) => handleOnChange(event)}
                                                    onKeyDown={(event: any) => handleOnKeyDown(event, productionRampShift, `value_day_${dayName}`)}
                                                    onBlur={(event: any) => handleOnBlur(event, productionRampShift, `value_day_${dayName}`)}
                                                />
                                            </TableCell>
                                        ))}
                                        <TableCell className={"table-prs-actions"}>
                                            <IconButton color="secondary" size={"small"} onClick={() => handleOpenDialogHistory(productionRampShift.id)}>
                                                <History sx={{fontSize: "28px"}}></History>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))) : (
                                    <TableRow>
                                        <TableCell colSpan={8} sx={{textAlign: "center", fontSize: "13px", padding: "8px 0"}}>
                                            {translate.t("no_associated_shift")}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <DialogProductionRampShiftSelectionComponent
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                productionRamp={productionRamp}
                service={service}
                searchMain={search}
            />
            <HistoryComponent
                openHistory={openHistory}
                handleCloseDialogHistory={handleCloseDialogHistory}
                idHistory={idHistory}
                columns_history={[
                    "production_ramp",
                    "shift",
                    "active",
                    "value_day_one",
                    "value_day_two",
                    "value_day_three",
                    "value_day_four",
                    "value_day_five",
                    "value_day_six",
                    "value_day_seven"
                ]}
                service={service}
            />
        </Box>
    );
};

export {ProductionRampItemShift};
