import React, {useEffect, useState} from "react";
import {Box, Grid, Paper, Switch, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import {translate} from "../../../../translate/translate";
import {toast} from "react-toastify";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import {Module} from "../../../../models/default/module";
import {ModuleMenu} from "../../../../models/default/module-menu";

interface ContainerProps {
    groupId: any;
}

const GroupModuleComponent = ({groupId}: ContainerProps) => {
    const [menus, setMenus] = React.useState<ModuleMenu[]>([]);
    const [modules, setModules] = useState<Module[]>([]);
    const [selectMenu, setselectMenu] = useState<string | undefined>();
    const displayedColumns = ["description"];
    const displayedColumnsMenu = ["description", "granted_all"];
    const service = new BaseService<Module>(URLS.MODULE);
    const serviceModuleMenu = new BaseService<ModuleMenu>(URLS.MODULE_MENU);

    const search = () => {
        service.clearParameters();
        service.addParameter("group", groupId);
        service
            .getAllFromListRoute("with_granted")
            .then((response) => {
                const uniqueModules: Module[] = [];
                response.data.forEach((item: Module) => {
                    const foundIndex = uniqueModules.findIndex(
                        (module) => module.description === item.description
                    );
                    if (foundIndex === -1) {
                        uniqueModules.push(item);
                    }
                });
                setModules(uniqueModules);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        search();
    }, []);

    const handleSwitchMenu = (event: React.ChangeEvent<HTMLInputElement>, menu: any) => {
        serviceModuleMenu.clearParameters();
        if (menu.id !== undefined) {
            serviceModuleMenu.saveFromDetailRoute({
                group: groupId,
                granted: event.target.checked.toString()
            }, menu.id, "grant")
                .then(() => {
                    search();
                    getMenus(menu?.module?.id);
                    toast.success(translate.t("successfully_updated"));
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    const handleSwitchModule = (event: React.ChangeEvent<HTMLInputElement>, module: any) => {
        service.clearParameters();
        service.saveFromDetailRoute({granted: event.target.checked.toString(), group: groupId}, module, "grant")
            .then(() => {
                toast.success(translate.t("successfully_updated"));
                search();
            });
    };

    const handleModule = (
        granted: boolean) => {
        serviceModuleMenu.clearParameters();
        serviceModuleMenu.addParameter("group", groupId);
        serviceModuleMenu.addParameter("module", selectMenu);
        serviceModuleMenu.addParameter("is_active", true);
        const payload = {
            "group": groupId,
            "granted": granted,
        };
        serviceModuleMenu.postFromListRoute(payload, "grant_all").then(() => {
            search();
            getMenus(selectMenu);
            toast.success(translate.t("successfully_updated"));
        })
            .catch(error => {
                console.log(error);
            });
    };

    const getMenus = (id: any) => {
        setselectMenu(id);
        serviceModuleMenu.clearParameters();
        serviceModuleMenu.addParameter("group", groupId);
        serviceModuleMenu.addParameter("module", id);
        serviceModuleMenu.addParameter("expand", "menu,module");
        serviceModuleMenu.getAllFromListRoute("with_granted")
            .then(response => {
                const menuDescriptions = response.data.slice(1);
                setMenus(menuDescriptions);
            });
    };

    const ColumnHeader = () => {
        return (
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end"
            }}>
                <Tooltip title={translate.t("deny_permission")}>
                    <IconButton
                        component="label"
                        onClick={() => handleModule(false)}
                        disabled={!selectMenu}>
                        <ThumbDownAltIcon/>
                    </IconButton>
                </Tooltip>

                <Tooltip title={translate.t("grant_permission")}>
                    <IconButton
                        color="secondary"
                        component="label"
                        onClick={() => handleModule(true)}
                        disabled={!selectMenu}>
                        <ThumbUpAltIcon/>
                    </IconButton>
                </Tooltip>
            </Box>
        );
    };

    return (
        <Box sx={{width: "100%"}}>
            <Paper>
                <Grid container spacing={2} padding={2}>
                    <Grid item xs>
                        <Table size={"small"}>
                            <TableHead sx={{height: "49px"}}>
                                <TableRow>
                                    {displayedColumns.map((column: string) => (
                                        <TableCell key={column}>{translate.t(column)}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {modules && modules.length > 0 ? (
                                    modules.map((module: any) => (
                                        <TableRow key={module.id}>
                                            {displayedColumns.map((column: string) => (
                                                <TableCell key={column} sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    whiteSpace: "nowrap",
                                                }}>
                                                    {translate.t(module[column])}
                                                    <Box sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "end",
                                                        marginRight: "10px"
                                                    }}>
                                                        <Switch
                                                            color="secondary"
                                                            name="menu"
                                                            checked={module?.granted}
                                                            onChange={(e: any) => handleSwitchModule(e, module.id)}
                                                        />
                                                    </Box>
                                                    <Box sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "end",
                                                        width: "55px",
                                                        minWidth: "55px"
                                                    }}>
                                                        <IconButton onClick={() => getMenus(module.id)}>
                                                            <ArrowForwardIosIcon/>
                                                        </IconButton>
                                                    </Box>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={displayedColumns.length} sx={{
                                            textAlign: "center",
                                            fontSize: "13px",
                                            padding: "8px 0"
                                        }}>
                                            {translate.t("empty_listing")}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs>
                        <Table size={"small"}>
                            <TableHead sx={{width: "100%"}}>
                                <TableRow>
                                    {displayedColumnsMenu.map((column: string) => (
                                        <TableCell key={column}>
                                            {column === "granted_all" ? <ColumnHeader/> : translate.t(column)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{width: "100%", alignItems: "flex-start"}}>
                                {menus && menus.length > 0 ? (
                                    menus.map((menu: any) => (
                                        <TableRow key={menu.id}>
                                            {displayedColumnsMenu.map((column: string) => (
                                                <TableCell key={column}>
                                                    {column === "granted_all" ? (
                                                        <Box sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "end"
                                                        }}>
                                                            <Switch
                                                                color="secondary"
                                                                name="menu"
                                                                checked={menu?.granted}
                                                                onChange={(e: any) => handleSwitchMenu(e, menu)}/>
                                                        </Box>
                                                    ) : (
                                                        <Box sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "start"
                                                        }}>
                                                            {translate.t(menu.menu?.description)}
                                                        </Box>
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={displayedColumnsMenu.length} sx={{
                                            textAlign: "center",
                                            fontSize: "13px",
                                            padding: "8px 0"
                                        }}>
                                            {translate.t("empty_listing")}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
};

export {GroupModuleComponent};
