import BaseModel from "../default/base-model";

export class Headcount extends BaseModel {
    quantity: number;
    production_line: string;
    shift: string;

    constructor() {
        super();
        this.quantity = 0;
        this.production_line = "";
        this.shift = "";
    }
}

