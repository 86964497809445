import * as React from "react";
import {useEffect, useState} from "react";
import {translate} from "../../../translate/translate";
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Fab,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import TablePaginationComponent from "../../../components/table/table-pagination.component";
import {BaseService} from "../../../services/base-service";
import {Add, Close, Search} from "@mui/icons-material";
import {URLS} from "../../../services/app-urls";
import {NavLink, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {PaginatedResult} from "../../../models/default/paginated-result";
import TableActionComponent from "../../../components/table/table-action.component";
import {Field, Form, Formik} from "formik";
import HistoryComponent from "../../../components/history/history.component";
import Select from "@mui/material/Select";
import {ManualDefaultTime} from "../../../models/basic/manual-default-time";
import {Product} from "../../../models/basic/product";
import {ProductionLine} from "../../../models/basic/production-line";
import {ErrorComponent} from "../../../components/error/error.component";


const ManualDefaultTimeComponent = () => {
    const service = new BaseService<ManualDefaultTime>(URLS.MANUAL_DEFAULT_TIME);
    const serviceProduct = new BaseService<Product>(URLS.PRODUCT);
    const serviceLines = new BaseService<ProductionLine>(URLS.PRODUCTION_LINE);
    const url = "/production_capacity/manual_default_time/";
    const [dataSource, setDataSource] = React.useState<ManualDefaultTime[]>([]);
    const navigate = useNavigate();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [openHistory, setOpenHistory] = React.useState(false);
    const [idHistory, setIdHistory] = React.useState(0);
    const [searchStatus, setSearchStatus] = React.useState<string>("");
    const [searchProductionLine, setSearchProductionLine] = React.useState<string>("");
    const [lines, setLines] = useState<ProductionLine[]>([]);
    const [listProduct, setListProduct] = React.useState<Product[]>([]);
    const [productDuplicate, setProductDuplicate] = React.useState<Product | null>(null);
    const [rowHandleDuplicate, setRowHandleDuplicate] = useState<ManualDefaultTime>(new ManualDefaultTime());
    const [openDialogDuplicate, setOpenDialogDuplicate] = useState<boolean>(false);
    const [productValue, setProductValue] = React.useState<Product | null>(null);

    const handleDuplicate = (row: ManualDefaultTime) => {
        setRowHandleDuplicate(row);
        setOpenDialogDuplicate(true);
    };

    const duplicateNow = () => {
        const payload = {
            manual_default_time_id: rowHandleDuplicate?.id,
            product_id: productDuplicate?.id,
        };
        service.postFromListRoute(payload, "save_duplicate")
            .then(() => {
                toast.success(translate.t("successfully_registered"));
                search();
            }).catch((error: any) => {
                ErrorComponent(error);
            });

        setOpenDialogDuplicate(false);
    };

    const defaultPropsProduct = {
        options: listProduct,
        getOptionLabel: (option: Product) => option.name,
    };

    useEffect(() => {
        if (!openDialogDuplicate) {
            setRowHandleDuplicate(new ManualDefaultTime());
            setProductDuplicate(null);
        }
    }, [openDialogDuplicate]);

    const [pagination, setPagination] =
        React.useState<PaginatedResult<ManualDefaultTime>>();

    const handleOpenDialogHistory = (id: number) => {
        setIdHistory(id);
        setOpenHistory(true);
    };

    const handleCloseDialogHistory = () => {
        setOpenHistory(false);
    };

    const searchProduct = () => {
        serviceProduct.clearParameters();
        serviceProduct.getAll().then(response => {
            setListProduct(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const search = () => {
        service.clearParameters();
        if (searchStatus) {
            service.addParameter("active", searchStatus);
        }
        if (productValue) {
            service.addParameter("product", productValue.id);
        }
        if (searchProductionLine) {
            service.addParameter("lines", searchProductionLine);
        }
        service.addParameter("expand", "product,lines");
        service.addParameter("limit", rowsPerPage);
        service.addParameter("offset", (page * rowsPerPage));
        service.addParameter("ordering", "-id");
        service.getAllPaginate().then(response => {
            setPagination(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const getLineNames = (lines: any) => {
        return lines.map((line: any) => line.name).join(", ");
    };

    useEffect(() => {
        searchProduct();
    }, []);

    useEffect(() => {
        if (pagination?.results) {
            const updatedDataSource = pagination.results.map((item) => ({
                ...item,
                lines: getLineNames(item.lines),
            }));
            setDataSource(updatedDataSource);
        } else {
            setDataSource([]);
        }
    }, [pagination]);

    useEffect(() => {
        search();
    }, [page, rowsPerPage]);

    useEffect(() => {
        getLines();
    }, []);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDoubleClick = (row: ManualDefaultTime) => {
        navigate(`${url}${row.id}`);
    };

    const handleDelete = (row: ManualDefaultTime) => {
        service.delete(Number(row.id)).then((response: any) => {
            if (response?.status == 403) {
                toast.error(response.data.detail);
            } else {
                toast.success(translate.t("successfully_deleted"));
                service.getAll().then(() => {
                    search();
                });
            }
        }).catch(() => {
        });
    };

    const handleSwitch = (row: ManualDefaultTime, column: keyof ManualDefaultTime) => {
        (row[column] as boolean) = !row[column];
        const payload = {"active": row.active};
        service.patch(payload, Number(row.id)).then(() => {
            toast.success(translate.t("successfully_updated"));
            service.clearParameters();
            search();
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const getLines = () => {
        serviceLines.clearParameters();
        serviceLines.addParameter("active", true);
        serviceLines.getAll()
            .then((response: any) => {
                setLines(response.data);
            });
    };

    return (
        <Box>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("manual_default_time")}
                </Typography>
            </div>
            <div className="floating-button">
                <Button
                    component={NavLink}
                    to={url + "create"}
                    startIcon={<Add/>}
                    variant="contained"
                    color="secondary"
                    className={"button-new"}>
                    {translate.t("new")}
                </Button>
            </div>

            <Grid item xs={12}>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize={true}
                            validateOnMount={true}
                            initialValues={{product: "", lines: ""}}
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    search();
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {() => (
                                <Form autoComplete="off">
                                    <Grid alignItems="center" container spacing={2} direction={"row"}>
                                        <Grid item xs>
                                            <Autocomplete
                                                disablePortal
                                                id="cb-product"
                                                {...defaultPropsProduct}
                                                fullWidth
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        label={translate.t("product")}
                                                    />
                                                }
                                                onChange={(event: any, newValue: Product | null) => {
                                                    setProductValue(newValue);
                                                }}
                                                noOptionsText={translate.t("empty_listing")}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <FormControl fullWidth>
                                                <InputLabel className={"formControl"} htmlFor="product">
                                                    {translate.t("production_line")}
                                                </InputLabel>
                                                <Field
                                                    id="lines"
                                                    variant={"outlined"}
                                                    defaultValue=""
                                                    name="lines"
                                                    component={Select}
                                                    onChange={(event: any) => setSearchProductionLine(event.target.value)}>
                                                    {lines.length > 0 && (
                                                        <MenuItem value="">-</MenuItem>
                                                    )}
                                                    {lines.length > 0 ? (
                                                        lines.map((line) => (
                                                            <MenuItem key={line.id} value={line.id}>
                                                                {line.name}
                                                            </MenuItem>
                                                        ))
                                                    ) : (
                                                        <MenuItem disabled>{translate.t("empty_listing")}</MenuItem>
                                                    )}
                                                </Field>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs>
                                            <FormControl fullWidth>
                                                <InputLabel className={"formControl"} htmlFor="status">
                                                    Status
                                                </InputLabel>
                                                <Field
                                                    variant={"outlined"}
                                                    defaultValue=""
                                                    name="status"
                                                    component={Select}
                                                    onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                        setSearchStatus(event.target.value as string)}>
                                                    <MenuItem value="">-</MenuItem>
                                                    <MenuItem value="true">
                                                        {translate.t("is_active")}</MenuItem>
                                                    <MenuItem value="false">
                                                        {translate.t("inactive")}</MenuItem>
                                                </Field>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <Fab
                                                size="small"
                                                type="submit"
                                                color="secondary">
                                                <Search/>
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>

            <Paper variant="outlined" sx={{marginTop: "16px"}}>
                <TablePaginationComponent
                    service={service}
                    state={dataSource}
                    displayedColumnsShow={["#", "product", "default_time_seconds", "capacity", "people_quantity", "production_line", "Status", "action"]}
                    displayedColumns={["id", "product.name", "default_time_seconds", "capacity", "people_quantity", "lines", "active", "action"]}
                    handleDelete={handleDelete}
                    handleDuplicate={handleDuplicate}
                    handleSwitch={handleSwitch}
                    url={url}
                    handleDoubleClick={handleDoubleClick}
                    count={pagination?.count}
                    next={pagination?.next}
                    previous={pagination?.previous}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    actionsComponent={TableActionComponent}
                    handleOpenDialogHistory={handleOpenDialogHistory}
                    columnStyles={{1: "10%", 2: "20%", 3: "15%", 4: "15%", 5: "15%", 6: "20%", 7: "20%"}}
                    showActions={["edit", "delete", "duplicate", "history"]}
                />
            </Paper>
            <HistoryComponent
                openHistory={openHistory}
                handleCloseDialogHistory={handleCloseDialogHistory}
                idHistory={idHistory}
                columns_history={["product.name", "default_time_seconds", "capacity", "people_quantity", "lines", "active"]}
                service={service}
            />
            <Dialog open={openDialogDuplicate} onClose={() => setOpenDialogDuplicate(false)}>
                <Grid padding={2} container justifyContent="space-between" sx={{backgroundColor: "primary.main"}}>
                    <Grid item sx={{marginRight: 5}}>
                        <Typography sx={{color: "#ffffff", fontWeight: "bold", fontSize: "16px"}}>
                            {translate.t("duplicate")}&nbsp;
                            {translate.t("smt_default_time")}
                        </Typography>
                    </Grid>
                    <Grid item sx={{cursor: "pointer", color: "#ffffff"}}>
                        <Close onClick={() => setOpenDialogDuplicate(false)}/>
                    </Grid>
                </Grid>
                <DialogContent>
                    <Grid container flexDirection="column" gap={1}>
                        <Autocomplete
                            id="cb-product-duplicate"
                            {...defaultPropsProduct}
                            fullWidth
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label={translate.t("product")}
                                />
                            }
                            value={productDuplicate}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event: any, newValue: Product | null) => {
                                setProductDuplicate(newValue);
                            }}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="flex-end" gap={1} padding={2}>
                        <Grid item>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => setOpenDialogDuplicate(false)}
                            >
                                {translate.t("cancel")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="info"
                                onClick={duplicateNow}
                                disabled={productDuplicate === null}
                            >
                                {translate.t("duplicate")}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ManualDefaultTimeComponent;
