import * as React from "react";
import {useEffect} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {translate} from "../../../../translate/translate";
import {Autocomplete, Button, Dialog, DialogActions, DialogContent, Fab, Grid, Paper, TextField} from "@mui/material";
import {Field, Form, Formik} from "formik";
import {Add, Check, Close, Search} from "@mui/icons-material";
import {toast} from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import {ScheduledStop} from "../../../../models/basic/scheduled-stop";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import {ScheduledStopShift} from "../../../../models/basic/scheduled-stop-shift";
import TablePaginationComponent from "../../../../components/table/table-pagination.component";
import TableActionComponent from "../../../../components/table/table-action.component";
import {Shift} from "../../../../models/basic/shift";
import {PaginatedResult} from "../../../../models/default/paginated-result";
import HistoryComponent from "../../../../components/history/history.component";
import {ErrorComponent} from "../../../../components/error/error.component";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3, backgroundColor: "white"}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const scheduledStopService = new BaseService<ScheduledStop>(URLS.SCHEDULED_STOP);
const scheduledStopShiftService = new BaseService<ScheduledStopShift>(URLS.SCHEDULED_STOP_SHIFT);

const TabsScheduledStop = (props: any) => {
    const { shift } = props;
    const url = `/registration/shift/${shift.id}`;
    const [value, setValue] = React.useState(0);
    const [searchName, setSearchName] = React.useState<string>("");
    const [openDialog, setOpenDialog] = React.useState(false);
    const [scheduledStops, setScheduledStops] = React.useState<ScheduledStop[]>([]);
    const [typeOperation, setTypeOperation] = React.useState<string>("");
    const [dataSource, setDataSource] = React.useState<ScheduledStopShift[]>([]);
    const [selectedScheduledStop, setSelectedScheduledStop] = React.useState<ScheduledStop | null>(null);
    const [scheduledStopShift, setShceduledStopShift] = React.useState<ScheduledStopShift>(new ScheduledStopShift());

    const [pagination, setPagination] = React.useState<PaginatedResult<ScheduledStopShift>>();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);

    const [openHistory, setOpenHistory] = React.useState(false);
    const [idHistory, setIdHistory] = React.useState(0);

    useEffect(() => {
        listScheduledStop();
    }, []);

    useEffect(() => {
        searchScheduledStopShift();
    }, [page, rowsPerPage]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const addScheduledStop = () => {
        setTypeOperation("add");
        setSelectedScheduledStop(null);
        setShceduledStopShift(new ScheduledStopShift());
        setOpenDialog(true);
    };

    const setButtonAdd = (value: string) => {
        if (selectedScheduledStop === null) {
            toast.error(translate.t("error_programmed_selected"));
        } else {
            const objScheduledStopShift = new ScheduledStopShift();

            if (scheduledStopShift.id === undefined) {
                objScheduledStopShift.scheduled_stop = selectedScheduledStop.url ?? "";
                objScheduledStopShift.shift = shift.url;

                scheduledStopShiftService.save(objScheduledStopShift).then(() => {
                    toast.success(translate.t("successfully_registered"));
                    searchScheduledStopShift();

                    if (value === "save_form") {
                        setOpenDialog(false);
                    } else {
                        setOpenDialog(true);
                    }
                }).catch((error: any) => {
                    ErrorComponent(error);
                });
            } else {
                Object.assign(objScheduledStopShift, scheduledStopShift);
                objScheduledStopShift.scheduled_stop = selectedScheduledStop.url ?? "";

                scheduledStopShiftService.patch(objScheduledStopShift, objScheduledStopShift.id ?? 0).then(() => {
                    toast.success(translate.t("successfully_updated"));
                    searchScheduledStopShift();

                    if (value === "save_form") {
                        setOpenDialog(false);
                    } else {
                        setOpenDialog(true);
                    }
                }).catch((error: any) => {
                    ErrorComponent(error);
                });
            }
        }
    };

    useEffect(() => {
        searchScheduledStopShift();
    }, [shift]);

    const searchScheduledStopShift = () => {
        if (shift.id) {
            scheduledStopShiftService.clearParameters();
            scheduledStopShiftService.addParameter("limit", rowsPerPage);
            scheduledStopShiftService.addParameter("offset", (page * rowsPerPage));
            scheduledStopShiftService.addParameter("expand", "scheduled_stop");
            scheduledStopShiftService.addParameter("shift", shift.id);
            if (searchName) scheduledStopShiftService.addParameter("name", searchName);

            scheduledStopShiftService.getAllPaginate().then(response => {
                setPagination(response.data);
            }).catch((error: any) => {
                ErrorComponent(error);
            });
        }
    };

    useEffect(() => {
        setDataSource(pagination?.results ?? []);
    }, [pagination]);

    const listScheduledStop = () => {
        scheduledStopService.clearParameters();
        scheduledStopService.addParameter("ordering", "name");
        scheduledStopService.addParameter("active", true);

        scheduledStopService.getAll().then(response => {
            setScheduledStops(response.data ?? []);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    useEffect(() => {
        if (openDialog) listScheduledStop();
    }, [openDialog]);

    const handleDelete = (row: Shift) => {
        scheduledStopShiftService.delete(Number(row.id)).then(() => {
            toast.success(translate.t("successfully_deleted"));
            scheduledStopShiftService.getAll().then(() => {
                searchScheduledStopShift();
            });
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSwitch = (row: ScheduledStopShift, column: keyof ScheduledStopShift) => {
        (row[column] as boolean) = !row[column];
        const payload = {"active": row.active};
        scheduledStopShiftService.patch(payload, Number(row.id)).then(() => {
            toast.success(translate.t("successfully_updated"));
            scheduledStopService.clearParameters();
            searchScheduledStopShift();
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const editWithDialog = (row: ScheduledStopShift) => {
        setTypeOperation("edit");
        const objScheduledStop:any = row.scheduled_stop;
        setSelectedScheduledStop(objScheduledStop);
        setShceduledStopShift(row);
        setOpenDialog(true);
    };

    const handleOpenDialogHistory = (id: number) => {
        setIdHistory(id);
        setOpenHistory(true);
    };

    const handleCloseDialogHistory = () => {
        setOpenHistory(false);
    };

    const defaultPropsProduct = {
        options: scheduledStops,
        getOptionLabel: (option: ScheduledStop) => option.name,
    };

    return (
        <Box sx={{width: "100%", marginTop: 2}}>
            <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab
                        sx={{backgroundColor: "white", borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
                        label={translate.t("programmed_shutdown")}
                        {...a11yProps(0)}
                    />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <Grid container>
                    <Box paddingTop={1}>
                        <Formik
                            enableReinitialize={true}
                            validateOnMount={true}
                            initialValues={{name: ""}}
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    searchScheduledStopShift();
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {
                                () => (
                                    <Form autoComplete="off">
                                        <Grid alignItems="center" container spacing={2} direction={"row"}>
                                            <Grid item xs>
                                                <Field
                                                    as={TextField}
                                                    name="name"
                                                    label={translate.t("name")}
                                                    fullWidth
                                                    variant={"outlined"}
                                                    autoFocus={true}
                                                    value={searchName}
                                                    onChange={(event: React.ChangeEvent<{
                                                        value: unknown
                                                    }>) => setSearchName(event.target.value as string)}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Fab
                                                    size="small"
                                                    type="submit"
                                                    color="secondary">
                                                    <Search/>
                                                </Fab>
                                            </Grid>
                                            <Grid item>
                                                <Fab
                                                    size="small"
                                                    color="secondary"
                                                    value="add_schedule_stop"
                                                    onClick={() => {
                                                        addScheduledStop();
                                                    }}
                                                >
                                                    <AddIcon/>
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                        </Formik>

                        <Paper variant="outlined" sx={{marginTop: "16px"}}>
                            <TablePaginationComponent
                                state={dataSource}
                                displayedColumnsShow={["programmed_shutdown", "duration", "Status", "action"]}
                                displayedColumns={["scheduled_stop.name", "scheduled_stop.duration", "active", "action"]}
                                handleDelete={handleDelete}
                                handleSwitch={handleSwitch}
                                url={url}
                                handleDoubleClick={editWithDialog}
                                editWithDialog={editWithDialog}
                                count={pagination?.count}
                                next={pagination?.next}
                                previous={pagination?.previous}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                actionsComponent={TableActionComponent}
                                handleOpenDialogHistory={handleOpenDialogHistory}
                                columnStyles={{1: "20%", 2: "20%", 3: "20%"}}
                            />
                        </Paper>

                    </Box>
                </Grid>
            </CustomTabPanel>

            <HistoryComponent
                openHistory={openHistory}
                handleCloseDialogHistory={handleCloseDialogHistory}
                idHistory={idHistory}
                columns_history={["shift", "scheduled_stop"]}
                service={scheduledStopShiftService}
            />

            <div>
                <Dialog
                    open={openDialog}
                    onClose={() => {
                        setOpenDialog(false);
                    }}
                >
                    <Grid
                        padding={2}
                        container
                        justifyContent="space-between"
                        sx={{backgroundColor: "primary.main", minWidth: 480}}
                    >
                        <Grid item >
                            <Typography sx={{color: "#ffffff", fontWeight: "bold", fontSize: "16px"}}>
                                {translate.t(typeOperation)} - {translate.t("programmed_shutdown")}
                            </Typography>
                        </Grid>
                        <Grid item sx={{cursor: "pointer", color: "#ffffff"}}>
                            <Close onClick={() => {
                                setOpenDialog(false);
                            }}/>
                        </Grid>
                    </Grid>
                    <DialogContent>
                        <Formik
                            initialValues={{scheduled_stop: ""}}
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    setSubmitting(false);
                                }, 400);
                            }}
                        >
                            <Form autoComplete="off">
                                <Autocomplete
                                    id="cb-scheduled-stop"
                                    {...defaultPropsProduct}
                                    fullWidth
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label={translate.t("scheduled_stop")}
                                        />
                                    }
                                    value={selectedScheduledStop}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={(event: any, newValue: ScheduledStop | null) => {
                                        setSelectedScheduledStop(newValue);
                                    }}
                                />
                            </Form>
                        </Formik>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            startIcon={<Check/>}
                            color="success"
                            variant="contained"
                            value="save_form"
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                setButtonAdd(event.currentTarget.value);
                            }}
                            name="save_form"
                        >
                            {translate.t("save_form")}
                        </Button>
                        <Button
                            startIcon={<Add/>}
                            color="secondary"
                            variant="contained"
                            value="save"
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                setButtonAdd(event.currentTarget.value);
                            }}
                            name="save"
                        >
                            {translate.t("save")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Box>
    );
};

export default TabsScheduledStop;
