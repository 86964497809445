import React, {useEffect, useState} from "react";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Box, Button, FormControl, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, Paper, TextField, Typography} from "@mui/material";
import {translate} from "../../../../translate/translate";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {toast} from "react-toastify";
import {ErrorComponent} from "../../../../components/error/error.component";
import {Setting} from "../../../../models/settings/setting";
import Select from "@mui/material/Select";
import {Choice} from "../../../../dto/choice";

const PreferencesItemComponent = () => {
    const [object, setObject] = React.useState(new Setting());
    const service = new BaseService<Setting>(URLS.SETTING);
    const params = useParams();
    const url = "/settings/preferences/";
    const [buttonValue, setButtonValue] = useState("");
    const navigate = useNavigate();
    const [choices, setChoices] = React.useState<Choice[]>([]);

    const schema = Yup.object().shape({
        name: Yup.string().required(),
        value: Yup.string().required()
    });

    const getChoices = () => {
        service.getChoices("name").then((response: any) => {
            setChoices(response?.map((item: any) => item));
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    useEffect(() => {
        getChoices();
    }, []);

    useEffect(() => {
        if (params["action"] != "create") {
            service.getById(parseInt(params["action"] as string)).then((response) => {
                setObject(response.data);
            });
        }
    }, []);

    return (
        <section>
            <div className="floating-title floating-title-item" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <IconButton
                    component={NavLink}
                    to={url}>
                    <ArrowBackIcon color="secondary" fontSize="large" fontWeight="fontWeightBold"/>
                </IconButton>
                <Typography variant={"h5"}>
                    {translate.t("preferences")}
                </Typography>
            </div>
            <div>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize
                            initialValues={object}
                            onSubmit={(values: Setting, {resetForm}: FormikHelpers<Setting>) => {
                                setTimeout(() => {
                                    const segment = new Setting();
                                    Object.assign(segment, values);
                                    if (segment.id) {
                                        service.patch(segment, segment.id).then(() => {
                                            toast.success(translate.t("successfully_updated"));
                                            if (buttonValue === "save") {
                                                setObject(new Setting());
                                                resetForm();
                                            } else {
                                                navigate(url);
                                            }
                                        }).catch((error: any) => {
                                            ErrorComponent(error);
                                        });
                                    } else {
                                        service.save(segment)
                                            .then(() => {
                                                toast.success(translate.t("successfully_registered"));
                                                if (buttonValue === "save") {
                                                    setObject(new Setting());
                                                    resetForm();
                                                } else {
                                                    navigate(url);
                                                }
                                            })
                                            .catch((error: any) => {
                                                ErrorComponent(error);
                                            });
                                    }
                                }, 500);
                            }}
                            validationSchema={schema}
                            validateOnChange={true}>
                            {({isValid, values, isValidating, errors, touched}) => (
                                <Form autoComplete="off">
                                    <Grid container spacing={2} direction={"column"}>
                                        <Grid item xs>
                                            <FormControl fullWidth>
                                                <InputLabel
                                                    required={true}
                                                    className={touched.name && Boolean(errors.name) ? "error-required" : ""}
                                                >
                                                    {translate.t("name")}
                                                </InputLabel>
                                                <Field
                                                    as={Select}
                                                    name="name"
                                                    variant={"outlined"}
                                                    fullWidth
                                                    label={translate.t("name")}
                                                    error={touched.name && Boolean(errors.name)}
                                                    required={true}
                                                    values={values}>
                                                    {choices.map((choice: any) => (
                                                        <MenuItem key={choice.value} value={choice.value}>
                                                            <ListItemText primary={translate.t(choice.display_name)}/>
                                                        </MenuItem>
                                                    ))}
                                                </Field>
                                                {(touched.name && Boolean(errors.name)) && (
                                                    <div className={"required-field"}>
                                                        {translate.t("required_field")}
                                                    </div>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="value"
                                                label={translate.t("value")}
                                                variant={"outlined"}
                                                fullWidth
                                                required={true}
                                                values={values}
                                                error={touched.value && Boolean(errors.value)}
                                                InputProps={{
                                                    maxLength: 54,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {touched.value && Boolean(errors.value) && (
                                                                <div className={"required-field"}>
                                                                    {translate.t("required_field")}
                                                                </div>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}/>
                                        </Grid>
                                        <Grid item container direction={"row"}>
                                            <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="secondary"
                                                        component={NavLink}
                                                        to={url}
                                                        variant="outlined">
                                                        {translate.t("cancel")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="success"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save_form"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save_form"
                                                        disabled={!isValid || isValidating || !values.name || !values.value}>
                                                        {translate.t("save_form")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="secondary"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save"
                                                        disabled={!isValid || isValidating || !values.name}>
                                                        {translate.t("save_plus")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
            </div>
        </section>
    );
};

export default PreferencesItemComponent;
