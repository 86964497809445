import BaseModel from "../default/base-model";

export class Setting extends BaseModel {
    name: string;
    value: string;

    constructor() {
        super();
        this.name = "";
        this.value = "";
    }
}
