import BaseModel from "../default/base-model";


export class ProductionLine extends BaseModel {
    name: string;
    type: string;
    active: boolean;
    force_update?: boolean;

    constructor() {
        super();
        this.name = "";
        this.type = "";
        this.active = true;
    }
}
