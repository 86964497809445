import BaseModel from "../default/base-model";

export class Segment extends BaseModel {
    id: number;
    name: string;
    active: boolean;
    url: string;

    constructor() {
        super();
        this.id = 0;
        this.name = "";
        this.active = true;
        this.url = "";
    }
}
