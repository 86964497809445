import React, {useEffect, useState} from "react";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {BaseService} from "../../../../services/base-service";
import {SmtTime, SmtTimeUpdateCreate} from "../../../../models/basic/smt-time";
import {URLS} from "../../../../services/app-urls";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Typography
} from "@mui/material";
import {translate} from "../../../../translate/translate";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {Product} from "../../../../models/basic/product";
import {ProductionLine} from "../../../../models/basic/production-line";
import {toast} from "react-toastify";
import SmtDefaultTimeItemTableComponent from "./smt-default-time-item-table.component";
import {ErrorComponent} from "../../../../components/error/error.component";
import AutocompletePaginated from "../../../../components/autocomplete/autocompletePaginated";


const SmtDefaultTimeItemComponent = () => {
    const params = useParams();
    const url = "/production_capacity/smt_default_time/";
    const navigate = useNavigate();
    const service = new BaseService<SmtTime>(URLS.SMT_TIME);
    const serviceUpdateCreate = new BaseService<SmtTimeUpdateCreate>(URLS.SMT_TIME);
    const serviceValidate = new BaseService<boolean>(URLS.SMT_TIME);
    const serviceProduct = new BaseService<Product>(URLS.PRODUCT);
    const serviceProductionLine = new BaseService<ProductionLine>(URLS.PRODUCTION_LINE);
    const [smtTime, setSmtTime] = React.useState(new SmtTime());
    const [listProduct, setListProduct] = React.useState<Product[]>([]);
    const [allProductionLine, setAllProductionLine] = React.useState<ProductionLine[]>([]);
    const [selectedProductionLine, setSelectedProductionLine] = React.useState<ProductionLine[]>([]);
    const [productValue, setProductValue] = React.useState<Product | null>(null);
    const [buttonValue, setButtonValue] = useState("");
    const [disableFields, setDisableFields] = useState<boolean>(true);
    const [productionLineFocused, setProductionLineFocused] = useState(false);
    const [clearAutocomplete, setClearAutocomplete] = useState<boolean>(false);

    useEffect(() => {
        if (!productValue || selectedProductionLine.length === 0)
            setDisableFields(true);
        else
            setDisableFields(false);
    }, [productValue, selectedProductionLine]);

    useEffect(() => {
        if (smtTime.id) {
            setProductValue(smtTime.product);
            setSelectedProductionLine(smtTime.lines);
        }
    }, [smtTime]);

    useEffect(() => {
        searchProduct();
        searchProductionLine();
        setProductionLineFocused(false);
    }, []);

    const searchProduct = () => {
        serviceProduct.clearParameters();
        serviceProduct.getAll().then(response => {
            setListProduct(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const searchProductionLine = () => {
        serviceProductionLine.clearParameters();
        serviceProductionLine.addParameter("type", "S");
        serviceProductionLine.getAll().then(response => {
            setAllProductionLine(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const getById = (id: number) => {
        service.clearParameters();
        service.addParameter("expand", "product.sides,lines");
        service.getById(id).then((response) => {
            setSmtTime(response.data);
        });
    };

    useEffect(() => {
        if (params["action"] != "create") {
            getById(parseInt(params["action"] as string));
            setProductionLineFocused(false);
        }
    }, [params["action"]]);

    const handleChangeProductionLine = (event: any, productionLine: ProductionLine) => {
        let newStateSide = [];
        const isChecked = event.target.checked;

        if (isChecked) {
            newStateSide.push(...selectedProductionLine);
            newStateSide.push(productionLine);
        } else {
            newStateSide = selectedProductionLine.filter(s => s.id !== productionLine.id);
        }
        setSelectedProductionLine(newStateSide);
    };

    const clearObjects = () => {
        setProductValue(new Product());
        setSelectedProductionLine([]);
        setSmtTime(new SmtTime());
    };

    const validateProductionLine = (smtTimeObj: SmtTimeUpdateCreate) => {
        return serviceValidate.getFromRoutePatch("validate_production_line", smtTimeObj)
            .then(response => response.data);
    };

    const handleProductionLineFocus = () => {
        setProductionLineFocused(true);
    };

    const handleChangeProduct = (event: any) => {
        setProductValue(event);
    };

    return (
        <section>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <IconButton
                    component={NavLink}
                    to={url}>
                    <ArrowBackIcon color="secondary" fontSize="large" fontWeight="fontWeightBold"/>
                </IconButton>
                <Typography variant={"h5"}>
                    {translate.t("smt_default_time")}
                </Typography>
            </div>
            <div>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            initialValues={smtTime}
                            onSubmit={
                                (values: SmtTime, {resetForm}: FormikHelpers<SmtTime>) => {
                                    setTimeout(async () => {
                                        if (productValue === null) {
                                            toast.error(translate.t("error_required_field"));
                                        } else {
                                            setClearAutocomplete(false);
                                            const smtTimeObj = new SmtTimeUpdateCreate();
                                            smtTimeObj.id = smtTime.id;
                                            smtTimeObj.product = listProduct
                                                .find((obj) => obj.id === productValue.id)?.url ?? "";
                                            selectedProductionLine.forEach((obj: ProductionLine) => {
                                                smtTimeObj.production_line_list.push(obj?.url ?? "");
                                            });
                                            const resultValidate = await validateProductionLine(smtTimeObj);
                                            if (resultValidate && smtTimeObj.id) {
                                                try {
                                                    service.patch(smtTimeObj, smtTimeObj.id)
                                                        .then(() => {
                                                            toast.success(translate.t("successfully_updated"));
                                                            if (buttonValue === "save") {
                                                                clearObjects();
                                                                setProductValue(null);
                                                                resetForm();
                                                                navigate(`${url}create`);
                                                                setProductionLineFocused(false);
                                                                setClearAutocomplete(true);
                                                            } else {
                                                                navigate(url);
                                                            }
                                                        })
                                                        .catch((error: any) => {
                                                            ErrorComponent(error);
                                                        });
                                                } catch (error) {
                                                    console.log(error);
                                                }
                                            } else if (resultValidate) {
                                                try {
                                                    serviceUpdateCreate.save(smtTimeObj)
                                                        .then((response) => {
                                                            toast.success(translate.t("successfully_registered"));
                                                            if (buttonValue === "save") {
                                                                clearObjects();
                                                                setProductValue(null);
                                                                resetForm();
                                                                navigate(`${url}create`);
                                                                setProductionLineFocused(false);
                                                                setClearAutocomplete(true);
                                                            } else {
                                                                navigate(`${url}${response.data.id}`);
                                                                getById(parseInt(String(response.data.id)));
                                                            }
                                                        })
                                                        .catch((error: any) => {
                                                            ErrorComponent(error);
                                                        });
                                                } catch (error) {
                                                    console.log(error);
                                                }
                                            } else if (!resultValidate) {
                                                toast.error(translate.t("validate_production_lines"));
                                            }
                                        }
                                    }, 500);
                                }}
                        >
                            {() => (
                                <Form autoComplete="off">
                                    <Grid container spacing={2} direction={"column"}>
                                        <Grid item container spacing={2} direction={"row"}>
                                            <Grid item xs>
                                                <FormControl fullWidth>
                                                    <AutocompletePaginated
                                                        display="name"
                                                        label="product"
                                                        service={serviceProduct}
                                                        onSelectElement={handleChangeProduct}
                                                        element={productValue}
                                                        required={true}
                                                        clear={clearAutocomplete}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs>
                                                <FormControl fullWidth>
                                                    <InputLabel required={true}
                                                        className={productionLineFocused && !selectedProductionLine.length ? "error-required" : ""}>
                                                        {translate.t("production_line")}
                                                    </InputLabel>
                                                    <Field
                                                        as={Select}
                                                        name="production_line"
                                                        variant={"outlined"}
                                                        fullWidth
                                                        multiple
                                                        onBlur={handleProductionLineFocus}
                                                        label={translate.t("production_line")}
                                                        error={productionLineFocused && !selectedProductionLine.length}
                                                        autoFocus={false}
                                                        required={true}
                                                        renderValue={(selected: any) => selected.map((obj: any) => obj.name).join(", ")}
                                                        value={selectedProductionLine}>
                                                        {allProductionLine.map((productionLine: ProductionLine) => (
                                                            <MenuItem key={productionLine.id}>
                                                                <Checkbox
                                                                    checked={selectedProductionLine.find(obj => (obj.id === productionLine.id)) !== undefined}
                                                                    onChange={(p: any) => handleChangeProductionLine(p, productionLine)}/>
                                                                <ListItemText primary={productionLine.name}/>
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                    {productionLineFocused && !selectedProductionLine.length && (
                                                        <div className={"required-field"}>
                                                            {translate.t("required_field")}
                                                        </div>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction={"row"}>
                                            <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                                                <Grid item>
                                                    <Button
                                                        color="secondary"
                                                        component={NavLink}
                                                        to={url}
                                                        variant="outlined">
                                                        {translate.t("cancel")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="success"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save_form"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save_form"
                                                        disabled={disableFields}>
                                                        {translate.t("save_form")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="secondary"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save"
                                                        disabled={disableFields}>
                                                        {translate.t("save_plus")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
                {
                    (params["action"] != "create") &&
                    <SmtDefaultTimeItemTableComponent
                        smtTime={smtTime}
                        getById={getById}
                    />
                }
            </div>
        </section>
    );
};

export default SmtDefaultTimeItemComponent;
