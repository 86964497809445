/*
    Returns the value of the row in the indicated column, whether it is a relational table or not
*/
export const Value = (row: any, column: string) => {
    const columns = column.split(".");
    let value = row;

    for (const col of columns) {
        if (value[col] !== null) {
            value = value[col];
        } else {
            value = "";
            break;
        }
    }
    return value;
};
