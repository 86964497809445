import BaseModel from "../default/base-model";

export class Marker extends BaseModel {
    id: number;
    description: string;
    color: string;
    active: boolean;
    url: string;

    constructor() {
        super();
        this.id = 0;
        this.description = "";
        this.color = "";
        this.active = true;
        this.url = "";
    }
}
