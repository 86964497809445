import * as React from "react";
import * as echarts from "echarts/core";
import {useState} from "react";
import {translate} from "../../../translate/translate";
import {Box, Fab, FormControl, Grid, InputLabel, MenuItem, Paper, TextField, Typography} from "@mui/material";
import {BaseService} from "../../../services/base-service";
import {Search} from "@mui/icons-material";
import {URLS} from "../../../services/app-urls";
import {Field, Form, Formik} from "formik";
import {Demand} from "../../../models/basic/demand";
import {ErrorComponent} from "../../../components/error/error.component";
import {Product} from "../../../models/basic/product";
import Select from "@mui/material/Select";
import AutocompletePaginated from "../../../components/autocomplete/autocompletePaginated";
import EChartsReact, {useChart} from "echarts-for-react-fc";
import {GridComponent, LegendComponent, TooltipComponent} from "echarts/components";
import {BarChart} from "echarts/charts";
import {CanvasRenderer} from "echarts/renderers";


echarts.use([GridComponent, BarChart, CanvasRenderer, TooltipComponent, LegendComponent]);

const RealizedAndPlannedComponent = () => {
    const service = new BaseService<Demand>(URLS.GROUP_PLANNING);
    const productService = new BaseService<Product>(URLS.PRODUCT);
    const labelOption = {
        show: true,
        distance: 15,
        rotate: 90,
        formatter: "{c}",
        fontSize: 16
    };
    const {chartRef, setChartOption, handleListenChartReady} = useChart();

    const [showChart, setShowChart] = useState(false);
    const [start_date, setStartDate] = useState("");
    const [end_date, setEndDate] = useState("");
    const [type, setType] = React.useState<string>("S");
    const [groupBy, setGroupBy] = React.useState<string>("consolidated");
    const [product, setProduct] = useState<Product>();

    const handleStartDateChange = (event: any) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event: any) => {
        setEndDate(event.target.value);
    };

    // Search
    const search = () => {
        setShowChart(false);
        service.clearParameters();
        service.addParameter("initial_date", start_date);
        service.addParameter("final_date", end_date);
        service.addParameter("production_line_type", type);
        service.addParameter("group_by", groupBy);
        if (product) {
            service.addParameter("product_id", product.id);
        }
        service.getFromListRoute("realized_versus_planned_report")
            .then((response: any) => {
                if (response.data.days.length > 0) {
                    setChartOption({
                        tooltip: {
                            trigger: "axis",
                            axisPointer: {
                                type: "shadow"
                            }
                        },
                        grid: {
                            left: "3%",
                            right: "4%",
                            bottom: "3%",
                            containLabel: true
                        },
                        legend: {
                            data: ["Planejado", "Realizado"],
                            type: "scroll",
                            top: "1.5%"
                        },
                        xAxis: [
                            {
                                type: "category",
                                data: response.data.days,
                                axisTick: {show: false}
                            }
                        ],
                        yAxis: [
                            {
                                type: "value",
                                name: "Quantidade"
                            }
                        ],
                        series: [
                            {
                                name: "Planejado",
                                type: "bar",
                                label: labelOption,
                                data: response.data.planned,
                            },
                            {
                                name: "Realizado",
                                type: "bar",
                                label: labelOption,
                                data: response.data.realized,
                            }
                        ]
                    });
                    setShowChart(true);
                }
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
    };

    // Render return
    return (
        <Box>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("realized_vs_planned")}
                </Typography>
            </div>
            <Grid>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            initialValues={{type: "S", groupBy: "consolidated"}}
                            enableReinitialize={true}
                            validateOnMount={true}
                            onSubmit={(values: any, {setSubmitting}) => {
                                setTimeout(() => {
                                    search();
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {
                                ({values, errors, touched}) => (
                                    <Form autoComplete="off">
                                        <Grid alignItems="center" container spacing={2} direction={"row"}>
                                            <Grid item xs lg={2}>
                                                <Field
                                                    as={TextField}
                                                    id="id_initial_date"
                                                    name="initial_date"
                                                    label={translate.t("initial_date")}
                                                    values={values}
                                                    value={start_date}
                                                    autoFocus={false}
                                                    type="date"
                                                    fullWidth
                                                    required={true}
                                                    onChange={handleStartDateChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={touched.initial_date && Boolean(errors.initial_date)}
                                                />
                                            </Grid>
                                            <Grid item xs lg={2}>
                                                <Field
                                                    as={TextField}
                                                    id="id_end_date"
                                                    name="end_date"
                                                    label={translate.t("final_date")}
                                                    values={values}
                                                    value={end_date}
                                                    autoFocus={false}
                                                    type="date"
                                                    fullWidth
                                                    required={true}
                                                    onChange={handleEndDateChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={touched.end_date && Boolean(errors.end_date)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                                <FormControl fullWidth>
                                                    <InputLabel className={"formControl"} htmlFor="type">
                                                        {translate.t("type")} *
                                                    </InputLabel>
                                                    <Field
                                                        variant={"outlined"}
                                                        defaultValue="S"
                                                        label={translate.t("type")}
                                                        name="type"
                                                        component={Select}
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setType(event.target.value as string)}
                                                    >
                                                        <MenuItem value="S">{translate.t("SMT")}</MenuItem>
                                                        <MenuItem value="M">{translate.t("Manual")}</MenuItem>
                                                    </Field>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                                <FormControl fullWidth>
                                                    <InputLabel className={"formControl"} htmlFor="groupBy">
                                                        {translate.t("group_by")} *
                                                    </InputLabel>
                                                    <Field
                                                        variant={"outlined"}
                                                        defaultValue="consolidated"
                                                        label={translate.t("type")}
                                                        name="groupBy"
                                                        component={Select}
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setGroupBy(event.target.value as string)}
                                                    >
                                                        <MenuItem value="consolidated">{translate.t("consolidated")}</MenuItem>
                                                        <MenuItem value="detailed">{translate.t("detailed")}</MenuItem>
                                                    </Field>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                                <AutocompletePaginated
                                                    display="name"
                                                    label="product"
                                                    service={productService}
                                                    onSelectElement={(product: Product) => {
                                                        setProduct(product);
                                                    }}
                                                    autoFocus={true}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={0.6}>
                                                <Fab
                                                    size="small"
                                                    type="submit"
                                                    disabled={!start_date || !end_date || !type || !groupBy}
                                                    color="secondary">
                                                    <Search/>
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>
            {showChart && (<Grid marginTop={2}>
                <Paper variant="outlined">
                    <EChartsReact
                        style={{
                            height: 300,
                        }}
                        ref={chartRef}
                        echarts={echarts}
                        onChartReady={handleListenChartReady}
                    />
                </Paper>
            </Grid>)}
        </Box>
    );
};

export default RealizedAndPlannedComponent;
