export const CheckFieldsComponent = (row: any, columns_history: string[]) => {
    if (row.history_type === "+") {
        return true;
    } else {
        let show = false;
        row.changes.map((change: any) => {
            if (columns_history.find((column: any) => change.field === column) !== undefined) {
                show = true;
            }
        });
        return show;
    }
};
