import {toast} from "react-toastify";
import {translate} from "../../translate/translate";
import {AxiosError} from "axios";

export const ErrorComponent = (axiosError: AxiosError) => {
    const response = axiosError.response;
    if (response == undefined) {
        toast.error(translate.t(axiosError.message));
        return;
    } else if (response.status === 0) {
        toast.error(translate.t("unknown_error"));
        return;
    }

    const isJson = (item: any) => {
        item = typeof item !== "string" ? JSON.stringify(item) : item;
        try {
            item = JSON.parse(item);
        } catch (e) {
            return false;
        }
        return typeof item === "object" && item !== null;
    };

    const captureError = () => {
        if (response.data instanceof Array) {
            return response.data;
        } else if (isJson(response.data)) {
            return [response.data];
        }
        return [{detail: response.data}];
    };

    const errors = captureError();

    const showErrors = (value: any) => {
        Object.keys(value).forEach((key: any) => {
            toast.error(translate.t(value[key].toString()));
        });
    };

    errors.forEach(t => {
        if (t instanceof Blob) {
            const reader = new FileReader();
            reader.addEventListener("loadend", () => {
                showErrors(JSON.parse(reader.result ? reader.result.toString() : ""));
            });
            reader.readAsText(t);
        } else {
            showErrors(t);
        }
    });
};
