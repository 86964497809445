import * as React from "react";
import {useTheme} from "@mui/material/styles";
import {Box} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

function TableActionComponent(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const {count, page, rowsPerPage, onPageChange} = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{width: "400px", color: "rgba(0, 0, 0, 0.26)"}}>
            <IconButton
                sx={{color: "rgba(0, 0, 0, 0.26) :disabled"}}
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? <LastPageIcon sx={{width: "28px", height: "28px"}}/> : <FirstPageIcon sx={{width: "28px", height: "28px"}}/>}
            </IconButton>
            <IconButton
                sx={{color: "rgba(0, 0, 0, 0.26) :disabled"}}
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? <KeyboardArrowRight sx={{width: "28px", height: "28px"}}/> : <KeyboardArrowLeft sx={{width: "28px", height: "28px"}}/>}
            </IconButton>
            <IconButton
                sx={{color: "rgba(0, 0, 0, 0.26) :disabled"}}
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? <KeyboardArrowLeft sx={{width: "28px", height: "28px"}}/> : <KeyboardArrowRight sx={{width: "28px", height: "28px"}}/>}
            </IconButton>
            <IconButton
                sx={{color: "rgba(0, 0, 0, 0.26) :disabled"}}
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? <FirstPageIcon sx={{width: "28px", height: "28px"}}/> : <LastPageIcon sx={{width: "28px", height: "28px"}}/>}
            </IconButton>
        </Box>
    );
}

export default TableActionComponent;
