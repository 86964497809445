
const preventTypeNumber = (event:any) => {
    return (
        event.key === "+" ||
        event.key === "-" ||
        event.key === "." ||
        event.key === "," ||
        event.key === "E" ||
        event.key === "e"
    ) && event.preventDefault();
};

export default preventTypeNumber;