import BaseModel from "../default/base-model";

export class ExpressionBuilder extends BaseModel {
    id: number;
    name: string;
    active: boolean;
    url: string;
    return_value: string;
    expression: string;
    default: boolean;

    constructor() {
        super();
        this.id = 0;
        this.name = "";
        this.url = "";
        this.return_value = "";
        this.expression = "";
        this.active = true;
        this.default = false;
    }
}
