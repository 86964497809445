import BaseModel from "./base-model";

export class LoginModel extends BaseModel {
    username: string;
    password: string;

    constructor() {
        super();
        this.username = "";
        this.password = "";
    }
}
