import {Autocomplete, Box, Fab, Grid, TextField} from "@mui/material";
import * as React from "react";
import {useEffect} from "react";
import {Form, Formik} from "formik";
import {translate} from "../../../../translate/translate";
import {Product} from "../../../../models/basic/product";
import {Search} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import {Demand} from "../../../../models/basic/demand";
import AvailableMenuComponent from "./available-menu/available-menu.component";
import PlannedMenuComponent from "./planned-menu/planned-menu.component";
import FinalizedMenuComponent from "./finalized-menu/finalized-menu.component";
import ProductionMenuComponent from "./production-menu/production-menu.component";
import "./demand-menu.component.css";
import {ErrorComponent} from "../../../../components/error/error.component";


const DemandMenuComponent = (props: any) => {
    const {
        onPlanning,
        version,
        searchDemand
    } = props;

    const [productValue, setProductValue] = React.useState<Product | null>(null);
    const [listProduct, setListProduct] = React.useState<Product[]>([]);
    const serviceProduct = new BaseService<Product>(URLS.PRODUCT);
    const serviceDemand = new BaseService<Demand>(URLS.DEMAND);
    const [demand, setDemand] = React.useState<Demand[]>([]);

    useEffect(() => {
        getProduct();
        search();
    }, []);

    useEffect(() => {
        search();
    }, [version, searchDemand]);

    const defaultPropsProduct = {
        options: listProduct,
        getOptionLabel: (option: Product) => option.name,
    };

    const getProduct = () => {
        serviceProduct.clearParameters();
        serviceProduct.getAll().then(response => {
            setListProduct(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const search = () => {
        serviceDemand.clearParameters();
        if (productValue) {
            serviceDemand.addParameter("product", productValue.id);
        }
        serviceDemand.addParameter("version", version);
        serviceDemand.addParameter("active", true);
        serviceDemand.addParameter("ordering", "reference_date");
        serviceDemand.addParameter("expand", "product,version");
        serviceDemand.getAllFromListRoute("board").then(response => {
            setDemand(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const handlePlanning = () => {
        search();
        onPlanning();
    };

    return (
        <Box>
            <Box sx={{padding: "10px"}}>
                <Formik
                    enableReinitialize={true}
                    validateOnMount={true}
                    initialValues={{product: ""}}
                    onSubmit={(values, {setSubmitting}) => {
                        setTimeout(() => {
                            search();
                            setSubmitting(false);
                        }, 500);
                    }}
                >
                    {() => (
                        <Form autoComplete="off">
                            <Grid alignItems="center" container spacing={1} direction={"row"}>
                                <Grid item xs>
                                    <Autocomplete
                                        disablePortal
                                        id="cb-product"
                                        {...defaultPropsProduct}
                                        fullWidth
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label={translate.t("product")}
                                            />
                                        }
                                        onChange={(event: any, newValue: Product | null) => {
                                            setProductValue(newValue);
                                        }}
                                        noOptionsText={translate.t("empty_listing")}
                                    />
                                </Grid>
                                <Grid item>
                                    <Fab
                                        size="small"
                                        type="submit"
                                        color="secondary">
                                        <Search/>
                                    </Fab>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
            <Divider/>
            <AvailableMenuComponent available={demand} handleSearch={handlePlanning} version={version}/>
            <Divider/>
            <PlannedMenuComponent planned={demand} handleSearch={handlePlanning} version={version}/>
            <Divider/>
            <ProductionMenuComponent production={demand} handleSearch={handlePlanning} version={version}/>
            <Divider/>
            <FinalizedMenuComponent finalized={demand} handleSearch={handlePlanning} version={version}/>
        </Box>
    );
};

export default DemandMenuComponent;
