import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import {DialogActions, Grid, Typography} from "@mui/material";
import {translate} from "../../translate/translate";
import {Close} from "@mui/icons-material";


const DialogVersion = (props: any) => {
    const {
        open,
        onClose,
        title,
        message,
        description,
        labelButton,
        colorButton
    } = props;

    const handleCloseDialog = (result: boolean) => {
        onClose(result);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <Grid padding={2} container justifyContent="space-between" sx={{backgroundColor: "primary.main"}}>
                <Grid item>
                    <Typography sx={{color: "#ffffff", fontWeight: "bold", fontSize: "16px"}}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item sx={{cursor: "pointer", color: "#ffffff"}}>
                    <Close onClick={() => {
                        handleCloseDialog(false);
                    }}/>
                </Grid>
            </Grid>
            <DialogContent>
                <Grid container flexDirection="column" gap={1}>
                    <Grid item>
                        <Typography>
                            {message}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography sx={{fontWeight: "bold"}}>
                            {description}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="flex-end" gap={1} padding={2}>
                    <Grid item>
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={() => {
                                handleCloseDialog(false);
                            }}>{translate.t("cancel")}</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color={colorButton ? colorButton : "error"}
                            onClick={() => handleCloseDialog(true)}>
                            {labelButton}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default DialogVersion;
