import * as Yup from "yup";
import React, {useEffect} from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import {translate} from "../../../../translate/translate";
import {
    Box,
    Checkbox,
    DialogActions,
    DialogTitle,
    Fab,
    Grid,
    MenuItem,
    Paper,
    Step,
    StepButton,
    Stepper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {Field, Form, Formik} from "formik";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import {Close, Search} from "@mui/icons-material";
import {Shift} from "../../../../models/basic/shift";
import {ProductionRamp} from "../../../../models/basic/production-ramp";
import {Demand} from "../../../../models/basic/demand";
import {PaginatedResult} from "../../../../models/default/paginated-result";
import {ErrorComponent} from "../../../../components/error/error.component";
import TableActionComponent from "../../../../components/table/table-action.component";
import TablePaginationComponent from "../../../../components/table/table-pagination.component";
import TableHead from "@mui/material/TableHead";
import {SimulationFieldComponent} from "./simulation-field.component";
import {Simulation} from "../../../../models/planning/Simulation";
import {ManualDefaultTime} from "../../../../models/basic/manual-default-time";
import Loading from "../../../../components/loading/loading";

const LineTypeList = [
    {key: "S", name: "SMT"},
    {key: "M", name: "manual"},
    {key: "A", name: "aligned"}
];


const DialogSimulationComponent = (props: any) => {
    const {open, onClose, version} = props;

    const [selectedShifts, setSelectedShifts] = React.useState<any[]>([]);
    const [shifts, setShifts] = React.useState<any[]>([]);
    const [allShifts, setAllShifts] = React.useState<Shift[]>([]);
    const serviceDemand = new BaseService<Demand>(URLS.DEMAND);
    const productionRampService = new BaseService<ProductionRamp>(URLS.PRODUCTION_RAMP);
    const manualDefaultTimeService = new BaseService<ManualDefaultTime>(URLS.MANUAL_DEFAULT_TIME);
    const shiftService = new BaseService<Shift>(URLS.SHIFT);
    const simulationService = new BaseService<Simulation>(URLS.SIMULATION);
    const [productionRamps, setProductionRamps] = React.useState<ProductionRamp[]>([]);
    const [manualTimes, setManualTimes] = React.useState<ManualDefaultTime[]>([]);
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = ["select_demand", "adjust_variables", "define_period_and_shifts"];
    const [productName, setProductName] = React.useState<string>("");
    const [dataSource, setDataSource] = React.useState<Demand[]>([]);
    const [pagination, setPagination] = React.useState<PaginatedResult<Demand>>();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [showShiftAndPeriod, setShowShiftAndPeriod] = React.useState(false);
    const [startDate, setStartDate] = React.useState<any>();
    const [endDate, setEndDate] = React.useState<any>();
    const demandUrl = "/planning/demand/";
    const lineTypes = LineTypeList;
    const [loading, setLoading] = React.useState(false);
    const [simulationResults, setSimulationResults] = React.useState<any[]>([]);
    const [finalized, setFinalized] = React.useState(false);

    useEffect(() => {
        setDataSource(pagination?.results ?? []);
    }, [pagination]);

    useEffect(() => {
        search();
        getManualTime();
        getProductionRamp();
        getShifts();
    }, [version, page, rowsPerPage]);

    const validationSchema = Yup.object().shape({
        selectedRows: Yup.array().of(
            Yup.object().shape({
                show_planned_qty: Yup.boolean(),
                line_setting: Yup.string(),
                planned_qty: Yup.number().when("show_planned_qty", {
                    is: (show_planned_qty: any) => !!show_planned_qty,
                    then: Yup.number().required(translate.t("required_field")).positive().min(0, translate.t("greater_than_equal_0")),
                    otherwise: Yup.number(),
                }),
                show_standard_time_data: Yup.boolean(),
                standard_time_data: Yup.number().when("show_standard_time_data", {
                    is: (show_standard_time_data: any) => !!show_standard_time_data,
                    then: Yup.number().required(translate.t("required_field")).positive().min(0, translate.t("greater_than_equal_0")),
                    otherwise: Yup.number(),
                }),
                show_production_ramp: Yup.boolean(),
                production_ramp: Yup.number().when("show_production_ramp", {
                    is: (show_production_ramp: any) => !!show_production_ramp,
                    then: Yup.number().required(translate.t("required_field")),
                    otherwise: Yup.number(),
                })
            })
        )
    });

    const schemaPeriodShifts = Yup.object().shape({
        initial_date: Yup.string().required(),
        final_date: Yup.string().required(),
    });

    const handleClose = () => {
        onClose(finalized);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getShifts = () => {
        shiftService.clearParameters();
        shiftService.addParameter("ordering", "id");
        shiftService.getAll()
            .then((response: any) => {
                setAllShifts(response.data);
            });
    };

    const search = () => {
        serviceDemand.clearParameters();
        if (productName) {
            serviceDemand.addParameter("product__name", productName);
        }
        serviceDemand.addParameter("version", version.id);
        serviceDemand.addParameter("status", "TP");
        serviceDemand.addParameter("active", true);
        serviceDemand.addParameter("ordering", "reference_date");
        serviceDemand.addParameter("expand", "product,version");
        serviceDemand.addParameter("limit", rowsPerPage);
        serviceDemand.addParameter("offset", (page * rowsPerPage));
        serviceDemand.getAllPaginate().then(response => {
            setPagination(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleRowSelect = (row: Demand) => {
        const isSelected = selectedRows.some((selectedRow) => selectedRow.id === row.id);
        let newSelectedRows;
        if (!isSelected) {
            newSelectedRows = [...selectedRows, row];
        } else {
            newSelectedRows = selectedRows.filter((selectedRow) => selectedRow.id !== row.id);
        }
        const productManualTimes = manualTimes.filter(m => m.product.id === row.product.id);
        const manualTime = productManualTimes.length > 0 ? productManualTimes[0].id : "";
        const ramp = productionRamps.length > 0 ? productionRamps[0].id : "";
        setSelectedRows(newSelectedRows.map((item: Demand) => ({
            ...item,
            top_bot_different_lines: item.top_bot_different_lines ? item.top_bot_different_lines : false,
            line_setting: item.line_setting ? item.line_setting : "S",
            planned_qty: item.planned_qty ? item.planned_qty : item.quantity,
            manual_default_time: item.manual_default_time ? item.manual_default_time : manualTime,
            production_ramp: item.production_ramp ? item.production_ramp : ramp
        })));
    };

    const handleStep = (step: number) => {
        if (step == 1) {
            search();
            setActiveStep(step);
        } else if (step == 2) {
            if (selectedRows.length > 0) {
                setActiveStep(step);
            }
        } else {
            setActiveStep(step);
        }
    };

    const goToShift = () => {
        setShowShiftAndPeriod(true);
        handleNext();
    };

    const startSimulation = () => {
        setLoading(true);
        const demandData: any[] = [];
        const shiftData: any[] = [];
        selectedRows.map((item: Demand) => {
            demandData.push({
                "demand": item.url,
                "quantity": item.quantity,
                "manual_default_time": item.manual_default_time,
                "planned_qty": item.planned_qty,
                "line_setting": item.line_setting,
                "top_bot_different_lines": item.top_bot_different_lines,
                "product": item.product.url,
                "production_ramp": item.production_ramp
            });
        });
        selectedShifts.map((item: Shift) => {
            shiftData.push(item.url);
        });
        const payload = {
            "shifts": shiftData,
            "initial_date": startDate,
            "final_date": endDate,
            "version": version.url,
            "shift_to_start": selectedShifts[0].shift,
            "demand_data": demandData
        };
        simulationService.clearParameters();
        simulationService.postFromListRoute(payload, "start_simulation")
            .then((response: any) => {
                setSimulationResults(response.data.results);
                setFinalized(true);
            }).catch((error: any) => {
                ErrorComponent(error);
            }).finally(() => setLoading(false));
    };

    const getManualTime = () => {
        manualDefaultTimeService.clearParameters();
        manualDefaultTimeService.addParameter("active", true);
        manualDefaultTimeService.addParameter("expand", "product");
        manualDefaultTimeService.getAll()
            .then((response: any) => {
                setManualTimes(response.data);
            });
    };

    const getProductionRamp = () => {
        productionRampService.clearParameters();
        productionRampService.addParameter("active", true);
        productionRampService.getAll()
            .then((response: any) => {
                setProductionRamps(response.data);
            });
    };

    const handleChangeShifts = (event: any, shift: any) => {
        let newStateShifts = [];
        const isChecked = event.target.checked;
        if (isChecked) {
            newStateShifts.push(...selectedShifts);
            newStateShifts.push(shift);
        } else {
            newStateShifts = selectedShifts.filter(s => s.id !== shift.id);
            setShifts(shifts.filter(s => s.shift !== shift.url));
        }
        setSelectedShifts(newStateShifts);
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"xl"}>
                <Loading open={loading}/>
                <DialogTitle sx={{backgroundColor: "primary.main"}}>
                    <Grid container direction="row">
                        <Grid item xs>
                            <Typography color="#FFFFFF" fontWeight="bold" fontSize="16px">
                                {translate.t("new_simulation")}
                            </Typography>
                        </Grid>
                        <Grid sx={{cursor: "pointer", color: "#ffffff"}}>
                            <Close onClick={handleClose}/>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogTitle sx={{backgroundColor: "#F5F5F5"}}>
                    <Grid container direction="row">
                        <Stepper activeStep={activeStep} nonLinear style={{width: "100%"}}>
                            {!finalized && steps.map((label, index) => (
                                <Step key={label}>
                                    <StepButton color="inherit" onClick={() => handleStep(index)}>
                                        {translate.t(label)}
                                    </StepButton>
                                </Step>
                            ))}
                            {finalized &&
                                <Step key={"simulation_result"}>
                                    <StepButton color="inherit">
                                        {translate.t("simulation_result")}
                                    </StepButton>
                                </Step>
                            }
                        </Stepper>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    {activeStep === 0 && !finalized && (
                        <Box>
                            <Formik
                                enableReinitialize={true}
                                validateOnMount={true}
                                initialValues={{product: ""}}
                                onSubmit={(values: any, {setSubmitting}) => {
                                    setTimeout(() => {
                                        search();
                                        setSubmitting(false);
                                    }, 500);
                                }}
                            >
                                {
                                    () => (
                                        <Form autoComplete="off">
                                            <Grid container direction="column" alignItems="center">
                                                <Grid container item direction="row" spacing={2} alignItems="center">
                                                    <Grid item xs>
                                                        <Field
                                                            as={TextField}
                                                            name="product"
                                                            label={translate.t("product")}
                                                            fullWidth
                                                            variant={"outlined"}
                                                            autoFocus={true}
                                                            value={productName}
                                                            onChange={(event: React.ChangeEvent<{
                                                                value: unknown
                                                            }>) => setProductName(event.target.value as string)}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Fab
                                                            size="small"
                                                            type="submit"
                                                            onClick={search}
                                                            color="secondary">
                                                            <Search/>
                                                        </Fab>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item direction="row" alignItems="center">
                                                    <Grid item xs={12}>
                                                        <Paper variant="outlined" sx={{marginTop: "16px"}}>
                                                            <TablePaginationComponent
                                                                state={dataSource}
                                                                displayedColumnsShow={["", "#", "product", "version", "quantity", "production_order", "reference_date", "Status"]}
                                                                displayedColumns={[
                                                                    "isRowSelectable", "id", "product.name", "version.name", "quantity", "production_order", "reference_date", "status_name"
                                                                ]}
                                                                dateColumns={["reference_date"]}
                                                                url={demandUrl}
                                                                count={pagination?.count}
                                                                next={pagination?.next}
                                                                previous={pagination?.previous}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                rowsPerPage={rowsPerPage}
                                                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                                                actionsComponent={TableActionComponent}
                                                                selectedRows={selectedRows}
                                                                handleRowSelect={handleRowSelect}
                                                                columnStyles={{
                                                                    1: "5%",
                                                                    2: "5%",
                                                                    3: "25%",
                                                                    4: "20%",
                                                                    5: "5%",
                                                                    6: "15%",
                                                                    7: "15%",
                                                                    8: "10%"
                                                                }}
                                                            />
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container justifyContent="flex-end" gap={2} paddingTop={2}>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="success"
                                                        onClick={() => handleStep(1)}
                                                        disabled={selectedRows.length === 0}
                                                        variant="contained">
                                                        {translate.t("next")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                            </Formik>
                        </Box>
                    )}
                    {activeStep === 1 && !finalized && (
                        <Box>
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    selectedRows: selectedRows
                                }}
                                validationSchema={validationSchema}
                                onSubmit={(values: any) => {
                                    setTimeout(async () => {
                                        console.log(values);
                                    }, 500);
                                }}
                                validateOnMount={true}
                                validateOnChange={true}>
                                {({isValid, values, setFieldValue, isValidating, errors, touched, resetForm}) => (
                                    <Form autoComplete="off">
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>{translate.t("product")}</TableCell>
                                                        <TableCell>{translate.t("OP")}</TableCell>
                                                        <TableCell>{translate.t("quantity")}</TableCell>
                                                        <TableCell>{translate.t("line_setting")}</TableCell>
                                                        <TableCell>{translate.t("top_bottom_different_line")}</TableCell>
                                                        <TableCell>{translate.t("planned_qty")}</TableCell>
                                                        <TableCell>{translate.t("capacity")}</TableCell>
                                                        <TableCell>{translate.t("ramp")}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {values.selectedRows.map((row: any, index: any) => (
                                                        <TableRow key={row.id} sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                                            <TableCell component="th" scope="row">{row.product.name}</TableCell>
                                                            <TableCell>{row.production_order}</TableCell>
                                                            <TableCell>{row.quantity}</TableCell>
                                                            <TableCell>
                                                                <SimulationFieldComponent
                                                                    index={index}
                                                                    values={values}
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    setFieldValue={setFieldValue}
                                                                    field={"line_setting"}
                                                                    resetForm={resetForm}
                                                                    isSelect={true}
                                                                    isType={true}
                                                                    selectList={lineTypes}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Checkbox
                                                                    name={`selectedRows[${index}].top_bot_different_lines`}
                                                                    inputProps={{"aria-label": "controlled"}}
                                                                    checked={row.top_bot_different_lines}
                                                                    onChange={(e: any) => {
                                                                        const updatedSelectedRows = [...values.selectedRows];
                                                                        updatedSelectedRows[index].top_bot_different_lines = e.target.checked;
                                                                        setFieldValue("selectedRows", updatedSelectedRows);
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <SimulationFieldComponent
                                                                    index={index}
                                                                    values={values}
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    setFieldValue={setFieldValue}
                                                                    field={"planned_qty"}
                                                                    resetForm={resetForm}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <SimulationFieldComponent
                                                                    index={index}
                                                                    values={values}
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    setFieldValue={setFieldValue}
                                                                    field={"manual_default_time"}
                                                                    resetForm={resetForm}
                                                                    isSelect={true}
                                                                    isManual={true}
                                                                    selectList={manualTimes.filter(m => m.product.id === row.product.id)}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <SimulationFieldComponent
                                                                    index={index}
                                                                    values={values}
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    setFieldValue={setFieldValue}
                                                                    field={"production_ramp"}
                                                                    resetForm={resetForm}
                                                                    isSelect={true}
                                                                    isRamp={true}
                                                                    selectList={productionRamps}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Grid container justifyContent="flex-end" gap={2} paddingTop={2}>
                                            <Grid item>
                                                <Button
                                                    className="button-options"
                                                    color="secondary"
                                                    onClick={handleBack}
                                                    variant="outlined">
                                                    {translate.t("previous")}
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    className="button-options"
                                                    color="success"
                                                    type="submit"
                                                    variant="contained"
                                                    onClick={goToShift}
                                                    disabled={!isValid || isValidating}>
                                                    {translate.t("next")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    )}
                    {activeStep === 2 && showShiftAndPeriod && !finalized && (
                        <Box>
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    initial_date: undefined,
                                    final_date: undefined
                                }}
                                onSubmit={() => {}}
                                validationSchema={schemaPeriodShifts}
                                validateOnMount={true}
                                validateOnChange={true}>
                                {({values}) => (
                                    <Form autoComplete="off">
                                        <Grid container spacing={2} direction={"row"}>
                                            <Grid container item xs={12} spacing={2} justifyContent={"flex-end"}>
                                                <Grid item xs>
                                                    {allShifts.length > 0 ? (
                                                        allShifts.map((shift: Shift) => (
                                                            <Grid key={shift.id}>
                                                                <Checkbox
                                                                    checked={selectedShifts.find(obj => (obj.id === shift.id)) !== undefined}
                                                                    onChange={(p: any) => handleChangeShifts(p, shift)}
                                                                />
                                                                <label>
                                                                    <strong>{translate.t("shift") + ": "}</strong>
                                                                    {shift.name}
                                                                </label>
                                                            </Grid>
                                                        ))
                                                    ) : (
                                                        <MenuItem disabled>{translate.t("no_shift")}</MenuItem>
                                                    )}
                                                </Grid>
                                                <Grid container item direction="row" spacing={2}>
                                                    <Grid item xs lg={2}>
                                                        <Field
                                                            as={TextField}
                                                            id="initial_date"
                                                            name="initial_date"
                                                            label={translate.t("initial_date")}
                                                            values={values}
                                                            autoFocus={false}
                                                            type="date"
                                                            fullWidth
                                                            onChange={(event: any) => {
                                                                setStartDate(event.target.value);
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs lg={2}>
                                                        <Field
                                                            as={TextField}
                                                            id="final_date"
                                                            name="final_date"
                                                            label={translate.t("final_date")}
                                                            values={values}
                                                            autoFocus={false}
                                                            type="date"
                                                            fullWidth
                                                            onChange={(event: any) => {
                                                                setEndDate(event.target.value);
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container justifyContent="flex-end" gap={2} paddingTop={2}>
                                            <Grid item>
                                                <Button
                                                    className="button-options"
                                                    color="secondary"
                                                    onClick={handleBack}
                                                    variant="outlined">
                                                    {translate.t("previous")}
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    className="button-options"
                                                    color="success"
                                                    onClick={startSimulation}
                                                    disabled={selectedRows.length == 0 || !showShiftAndPeriod || selectedShifts.length == 0 || !startDate || !endDate}
                                                    variant="contained">
                                                    {translate.t("finalize")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    )}
                    {finalized && (
                        <Box>
                            {simulationResults.length > 0 &&
                                <Grid container gap={2} paddingTop={2}>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>{translate.t("demand")}</TableCell>
                                                    <TableCell>{translate.t("product")}</TableCell>
                                                    <TableCell>{translate.t("production_order")}</TableCell>
                                                    <TableCell>{translate.t("quantity")}</TableCell>
                                                    <TableCell>{translate.t("status")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {simulationResults.map((row: any, index: any) => (
                                                    <TableRow key={index} sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                                        <TableCell>{row.demand}</TableCell>
                                                        <TableCell>{row.product}</TableCell>
                                                        <TableCell>{row.production_order}</TableCell>
                                                        <TableCell>{row.quantity}</TableCell>
                                                        <TableCell>
                                                            {row.success ?
                                                                <Typography color="#3f9a01">{translate.t("planned")}</Typography>
                                                                :
                                                                <Typography color="#fa0202">{translate.t("no_planned")}</Typography>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            }
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="flex-end" gap={2} paddingTop={2}>
                        <Grid item>
                            <Button
                                className="button-options"
                                color="secondary"
                                onClick={handleClose}
                                variant="contained">
                                {finalized ? translate.t("close") : translate.t("cancel")}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DialogSimulationComponent;
